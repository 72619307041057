<template>
  <div :class="sidebarClasses" :style="sidebarStatus.open ? 'min-width: 30rem' : null">
    <div :class="openCloseButtonClasses">
      <b-button :icon-right="openCloseButtonIcon" @click="toggleSideBarOpen" />
    </div>

    <div v-show="sidebarStatus.open" class="sidebar-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["position", "open"],
  data() {
    return {
      sidebarStatus: {
        open: false,
      },
    };
  },
  provide() {
    return {
      sidebarStatus: this.sidebarStatus,
    };
  },
  computed: {
    sidebarClasses: (vm) => {
      return "sidebar" + " " + (vm.position === "right" ? "sidebar-right" : "sidebar-left");
    },
    openCloseButtonClasses: (vm) => {
      return vm.position === "right"
        ? "open-close-button-right is-small is-rounded"
        : "open-close-button-left is-small is-rounded";
    },
    openCloseButtonIcon: (vm) => {
      if (vm.sidebarStatus.open) {
        return vm.position === "right" ? "chevron-right" : "chevron-left";
      } else {
        return vm.position === "right" ? "chevron-left" : "chevron-right";
      }
    },
  },
  mounted() {
    this.sidebarStatus.open = this.open;
  },
  methods: {
    toggleSideBarOpen() {
      this.sidebarStatus.open = !this.sidebarStatus.open;
    },
  },
};
</script>

<style lang="css" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1.5em;
}

.sidebar-left {
  border-right: 5px solid var(--primary);
}

.sidebar-right {
  border-left: 5px solid var(--primary);
  max-height: 100%;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
}

.open-close-button-left {
  display: inline;
  position: absolute;
  right: -1.75em;
  top: calc(50vh - 1.5em);
  z-index: 38;
}

.open-close-button-right {
  display: inline;
  position: absolute;
  height: 100%;
  left: -2em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 38;
}
</style>
