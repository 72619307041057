import tinycolor from 'tinycolor2';
import * as turf from '@turf/turf';

class LatLng {
  constructor(latOrParams, lng) {
    if (latOrParams instanceof Object) {
      this.lat = latOrParams.lat;
      this.lng = latOrParams.lng;
    } else {
      this.lat = latOrParams;
      this.lng = lng;
    }
  }
}

const LatLngs = {
  toPointGeoJson(latLng) {
    return turf.point([latLng.lng, latLng.lat]);
  },

  toLineStringGeoJson(latLngs) {
    return turf.lineString(
      latLngs.map((ll) => {
        return [ll.lng, ll.lat];
      }),
    );
  },

  toPolygonGeoJson(latLngs) {
    return turf.lineToPolygon(LatLngs.toLineStringGeoJson(latLngs));
  },
};

const GeoJson = {
  toLatLngs(geoJson) {
    if (geoJson.geometry.type == 'LineString') {
      return geoJson.geometry.coordinates.map((c) => {
        return new LatLng(c[1], c[0]);
      });
    } else if (geoJson.geometry.type == 'Point') {
      let coords = geoJson.geometry.coordinates;
      return new LatLng(coords[1], coords[0]);
    }

    return turf.coordAll(geoJson).map((c) => {
      return new LatLng(c[1], c[0]);
    });
  },

  isPoint(geo) {
    if (geo == null || geo.type !== 'Feature' || geo.geometry == null || geo.geometry.type !== 'Point') {
      return false;
    }

    let coords = geo.geometry.coordinates;
    if (coords == null || coords.length !== 2) {
      return false;
    }

    return true;
  },

  bbox(geo) {
    return turf.bbox(geo);
  },
  
  isLineString(geo) {
    if (geo == null || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'LineString') {
      return false;
    }

    let coords = geo.geometry.coordinates;
    if (coords == null || coords.length < 2) {
      return false;
    }

    return true;
  },

  isPolygon(geo) {
    if (!geo || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'Polygon') {
      return false;
    }

    let coords = geo.geometry.coordinates;
    if (coords == null || coords.length < 1) {
      return false;
    }

    return true;
  },

  isMultiPolygon(geo) {
    if (!geo || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'MultiPolygon') {
      return false;
    }

    if (!geo.geometry.coordinates.every(GeoUtil.Coords.isPolygon)) {
      return false;
    }

    return true;
  },

  booleanContains(f0, f1) {
    return turf.booleanContains(f0, f1);
  },

  bboxPolygon(bbox, options) {
    return turf.bboxPolygon(bbox, options);
  },

  envelope(fc) {
    return turf.envelope(fc);
  },

  circle(center, radius, options) {
    return turf.circle(center, radius, options);
  },

  center(geo, options) {
    return turf.center(geo, options);
  },

  feature(geo, properties, options) {
    return turf.feature(geo, properties, options);
  },

  featureCollection(featureList, opts) {
    return turf.featureCollection(featureList, opts);
  },

  lineOffset(geo, distance, opts) {
    return turf.lineOffset(geo, distance, opts);
  },

  simplify(geo, opts) {
    return turf.simplify(geo, opts);
  },

  distance(from, to, opts) {
    return turf.distance(from, to, opts);
  },

  area(geo) {
    return turf.area(geo);
  },

  clustersDbscan(points, maxDistance, opts) {
    return turf.clustersDbscan(points, maxDistance, opts);
  },
};

const Bbox = {
  toLatLngPath(bbox) {
    return [
      new LatLng(bbox[1], bbox[0]),
      new LatLng(bbox[1], bbox[2]),
      new LatLng(bbox[3], bbox[2]),
      new LatLng(bbox[3], bbox[0]),
      new LatLng(bbox[1], bbox[0]),
    ];
  },
};

function mulberry32(str, s) {
  const cyrb53 = function (str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  let h = cyrb53(str, s);

  return function () {
    var t = (h += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

function randomColorsFromSeed(count, seed, opts) {
  const r = mulberry32("foo", seed);

  const ret = [];
  for (let i = 0; i < count; i++) {
    ret.push(randomColor(r, opts));
  }

  return ret;
}

function hexToComplimentary(hex) {
  // Convert hex to rgb
  // Credit to Denis http://stackoverflow.com/a/36253499/4939630
  var rgb = 'rgb(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16); }).join(',') + ')';

  // Get array of RGB values
  rgb = rgb.replace(/[^\d,]/g, '').split(',');

  var r = rgb[0], g = rgb[1], b = rgb[2];

  // Convert RGB to HSL
  // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
  r /= 255.0;
  g /= 255.0;
  b /= 255.0;
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2.0;

  if(max == min) {
      h = s = 0;  //achromatic
  } else {
      var d = max - min;
      s = (l > 0.5 ? d / (2.0 - max - min) : d / (max + min));

      if(max == r && g >= b) {
          h = 1.0472 * (g - b) / d ;
      } else if(max == r && g < b) {
          h = 1.0472 * (g - b) / d + 6.2832;
      } else if(max == g) {
          h = 1.0472 * (b - r) / d + 2.0944;
      } else if(max == b) {
          h = 1.0472 * (r - g) / d + 4.1888;
      }
  }

  h = h / 6.2832 * 360.0 + 0;

  // Shift hue to opposite side of wheel and convert to [0-1] value
  h+= 180;
  if (h > 360) { h -= 360; }
  h /= 360;

  // Convert h s and l values into r g and b values
  // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
  if(s === 0){
      r = g = b = l; // achromatic
  } else {
      var hue2rgb = function hue2rgb(p, q, t){
          if(t < 0) t += 1;
          if(t > 1) t -= 1;
          if(t < 1/6) return p + (q - p) * 6 * t;
          if(t < 1/2) return q;
          if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
  }

  r = Math.round(r * 255);
  g = Math.round(g * 255); 
  b = Math.round(b * 255);

  // Convert r b and g values to hex
  rgb = b | (g << 8) | (r << 16); 
  return "#" + (0x1000000 | rgb).toString(16).substring(1);
} 

function randomColorFromSeed(seed) {
  return randomColor(mulberry32("foo", seed));
}

function randomColor(randomFunc = Math.random, { r, g, b } = { r: true, g: true, b: true }) {
  const letters = '0123456789ABCDEF';

  let ret = '#';

  for (let i = 0; i < 6; i++) {
    if (!r && i <= 1) {
      ret += '0';
      continue;
    } else if (!g && i <= 3) {
      ret += '0';
      continue;
    } else if (!b) {
      ret += '0';
      continue;
    }

    ret += letters[Math.floor(randomFunc() * 16)];
  }

  return ret;
}

function randomSchemeFromSeed(seed) {
  const bg = new tinycolor(randomColorFromSeed(seed));
  const bgOther = bg.tetrad();
  const fg = tinycolor.mostReadable(bg, bgOther, {includeFallbackColors: true});
  const border = bgOther[0];

  return {
    bg, fg, border
  }
}

export { GeoJson, LatLngs, Bbox, randomColor, randomColorFromSeed, randomColorsFromSeed, hexToComplimentary, randomSchemeFromSeed };
