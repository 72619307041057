<template>
  <map-controls position="TOP_CENTER">
    <div class="search-bar">
      <label for="map-searchbar" class="search-bar-label">Search: </label>
      <input
        type="text"
        id="map-searchbar"
        placeholder="..."
        ref="search"
        v-show="autoComplete != null"
        @keyup.enter="search"
        v-model="searchInput"
      />
    </div>
  </map-controls>
</template>

<script>
import MapControls from '@/components/maps/MapControls';
import Pp4MapObject from '@/components/maps/Pp4MapObject';

const latLngMatchString = /^(-?\d+(?:\.\d+)*),? *(-?\d+(?:\.\d+)*)$/;

export default {
  extends: Pp4MapObject,
  components: {
    MapControls,
  },
  data: function () {
    return {
      autoComplete: null,
      searchInput: '',
    };
  },
  computed: {
    hasTop: (vm) => vm.$slots.top != null,
  },
  methods: {
    mapReady() {
      this.autoComplete = new this.api.places.Autocomplete(this.$refs.search, { fields: ['geometry'] });
      this.autoComplete.addListener('place_changed', () => this.search());

      this.placesService = new this.api.places.PlacesService(this.map);
    },
    async search() {
      this.searchInput = this.searchInput.trim();

      if (!this.searchInput || this.searchInput === '') return;

      const latLngRegexMatchResults = this.searchInput.match(latLngMatchString);

      if (latLngRegexMatchResults) {
        this.focusOnPlace(parseFloat(latLngRegexMatchResults[1]), parseFloat(latLngRegexMatchResults[2]));
        return;
      }

      await this.placesService.findPlaceFromQuery(
        {
          fields: ['geometry'],
          query: this.searchInput,
        },
        (places, status) => {
          const place = (places ? places[0] : null) || null;

          if (!place) {
            return;
          }

          const geometry = place.geometry;
          if (!geometry) {
            return;
          }

          const location = geometry.location;
          if (!location) {
            return;
          }

          this.focusOnPlace(location.lat(), location.lng());
        },
      );
    },
    focusOnPlace(lat, lng) {
      this.panToLatLngs({ lat, lng });
      this.setZoomLevel(14);
    },
  },
};
</script>

<style lang="css" scoped>
.search-bar-label {
  color: white;
}

.search-bar {
  padding: 10px;
  font-size: medium;
  position: relative;
  margin-top: 12px;
  background: #77838f;
}
</style>
