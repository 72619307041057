class LatLng {
  constructor(latOrParams, lng) {
    if (latOrParams instanceof Object) {
      this.lat = latOrParams.lat;
      this.lng = latOrParams.lng;

      if ('connectorType' in latOrParams) {
        this.connectorType = latOrParams.connectorType;
      }
    } else {
      this.lat = latOrParams;
      this.lng = lng;
    }
  }
}

const Direction = {
  Left: 1,
  Right: 2,
};
Object.freeze(Direction);

const GeometryEditMode = {
  None: 1,
  EditPoints: 2,
  RemovePoints: 3,
};
Object.freeze(GeometryEditMode);

export { Direction, LatLng, GeometryEditMode };
