<template>
  <span @mouseenter="mouseenter">
    <template v-if="active">
      <b-tooltip class="is-large" :animated="false" :active="true" :append-to-body="true" :delay="150"
        :position="tooltipPosition" multilined>
        <icon-button icon="image" @click="showFullscreenImage(image)" />
        <template #content>
          <authorized-image :image="image" />
          {{ new Date(image.createdOn).toLocaleDateString() }}
        </template>
      </b-tooltip>
    </template>
    <template v-else>
      <icon-button icon="image" @click="showFullscreenImage(image)" />
    </template>
  </span>
</template>

<script>
import IconButton from './IconButton.vue';
import AuthorizedImage from './AuthorizedImage.vue';

export default {
  components: { IconButton, AuthorizedImage },
  props: {
    image: {
      type: Object,
      required: true,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: 'is-left',
    },
  },
  data: function () {
    return { active: false }
  },
  computed: {
    finalTooltipPosition: (vm) => vm.tooltipPosition,
  },
  methods: {
    mouseenter() {
      this.active = true
    },
    showFullscreenImage(image) {
      this.$emit('showFullscreenImage', image);
    },
  },
};
</script>

<style scoped></style>
