<template>
   <Table :data="rowsWithMetadata" :pagination="pagination" @pageChange="pageChange"
      :columns="data.columns" :multipleDetails="false"
      detailKey="id" :loading="usersSwr.isValidating.value" :snapTo="data.selectedRowId" :controls="true">
      <template #controls>
         <filters :showTooltip="false">
            <div class="filters_columns">
               <div class="filters_rows">
                  <span>
                     <label class="mr-1 ml-1" for="user-text-search">Name:
                     </label>
                     <input id="user-text-search" type="text" @input="searchTextChanged($event.target.value)" />
                  </span>
                  <span>
                     <label class="mr-1 ml-1" for="address-text-search">Address:
                     </label>
                     <input id="address-text-search" type="text" @input="addressChanged($event.target.value)" />
                  </span>
                  <span>
                     <label class="mr-1 ml-1" for="dealer-text-search">Dealer:
                     </label>
                     <input id="dealer-text-search" type="text" @input="dealerChanged($event.target.value)" />
                  </span>
               </div>
            </div>
         </filters>

         <b-button @click="refresh" size="is-small" icon-right="refresh" title="Refresh Table" class="mb-1" />
      </template>

      <template #detail="props">
         <user-edit :value="props.value" />
      </template>
   </Table>
</template>

<script setup>
import { reactive, watch, inject, computed } from 'vue';
import useSwr from 'swrv'
import { debounce } from "lodash";

import Table from '@/components/common/Table';
import UserEdit from '@/views/RouteWizard/components/UserEdit';
import Filters from "@/components/common/Filters.vue"

const data = reactive({
   showAssociationWorkflow: false,
   showVroom: false,
   selectedRowId: null,
   queryParams: {
      q: '', address: '', dealer: '', page: 0, perPage: 100
   },
   columns: [
      { field: 'firstName', label: 'First', visible: true, sortable: false },
      { field: 'lastName', label: 'Last', visible: true, sortable: false },
      { field: 'userEmail', label: 'Email', visible: true, sortable: false }
   ],
})

const pagination = reactive({
   total: 0,
   page: data.queryParams.page,
   perPage: data.queryParams.perPage,
})

function pageChange(newPage) {
   data.value = {...data.value, queryParams: {
      ...data.queryParams, page: newPage }
   }
}

const $api = inject('$api')

const swrUrl = computed(() => {
   const params = new URLSearchParams(data.queryParams)
   const queryString = params.toString()

   if (queryString.length) {
      return `/dispatcher/users?${queryString}`
   }

   return `/dispatcher/users`
})

const usersSwr = useSwr(swrUrl, $api.fetch, {
   revalidateOnFocus: false
})

watch(usersSwr.data, () => {
   pagination.total = usersSwr.data.value?.count || 0
})

const rowsWithMetadata = computed(() => {
   const rows = usersSwr.data?.value?.results || []

   return rows.map(row => {
      let searchText = `${row.dealer} ${row.userEmail} ${row.userId}`.toLowerCase();
      if (row.myAccountInfo) {
         const m = row.myAccountInfo
         searchText += `${m.address1} ${m.city} ${m.dealerName} ${m.organizationName} ${m.firstName} ${m.lastName} ${m.phone}`.toLowerCase();
      }

      return {
         ...row,
         firstName: row.myAccountInfo?.firstName || '',
         lastName: row.myAccountInfo?.lastName || '',
         searchText
      }
   });
})

function refresh() {
   usersSwr.mutate()
}

const searchTextChanged = debounce(function (q) {
   data.queryParams = { ...data.queryParams, q } // need to trigger update so modify full object
}, 400)
const addressChanged = debounce(function (address) {
   data.queryParams = { ...data.queryParams, address } // need to trigger update so modify full object
}, 400)
const dealerChanged = debounce(function (dealer) {
   data.queryParams = { ...data.queryParams, dealer } // need to trigger update so modify full object
}, 400)

// filteredRowsWithMetata: vm => {
//    const lower = vm.queryParams.searchText.toLowerCase();

//    return vm.rowsWithMetadata.filter(row => {
//       return row.searchText.indexOf(lower) >= 0;
//    });
// }

// methods: {
//    refresh() {
//       this.$store.dispatch('getUsers');
//    },
//    searchTextChanged(val) {
//       this.queryParams.searchText = val;
//    },
//    rowOpenedHandler(id) {
//       console.log(id)
//       return
//       if (this.selectedRowId === id) {
//          return;
//       }

//       this.selectedRowId = id;
//       // could do something here like panto
//    },
// },
// };
</script>

<style lang="css" scoped>
.table-wrapper {
   position: relative;
   justify-content: stretch;
   justify-items: stretch;
   align-items: stretch;
   align-content: stretch;
   min-height: 100%;
   display: flex;
   flex-direction: column;
   padding: 2px;
   min-width: 550px;
   max-width: 550px;
}

.filters_rows {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   row-gap: 0.5rem;
   column-gap: 0.5rem;
   max-width: 550px;
}
</style>
