<template>
  <div>
    <context-menu>
      <template v-slot:top>
        <b-checkbox-button
          type="is-danger"
          title="Toggle remove points mode"
          @input="removeModeToggled"
        >
          <b-icon icon="minus-circle" />
        </b-checkbox-button>

        <b-button
          icon-left="vector-square"
          title="Simplify to bounding box"
          @click="toggleSimplify"
        />
      </template>

      <b-button
        icon-left="check"
        class="is-success"
        :disabled="saveDisabled"
        @click="save"
      />
      <b-button
        icon-left="cancel"
        class="has-text-white is-danger"
        type="is-danger"
        title="Cancel changes"
        @click="cancel"
      />
    </context-menu>

    <pp4-poly
      v-if="startOrSimplifiedPath"
      :path="startOrSimplifiedPath"
      :editMode="editMode"
      strokeColor="rgba(255, 255, 0, 0.75)"
      fillColor="rgba(255, 255, 0, 0.75)"
      @change="pathChanged"
    />

    <CompletedDriverStop
      v-for="stop in completedDriverStops"
      :key="`EditPickupLocationBounds:${pickupLocation.rowPointer}:${stop.rowPointer}`"
      :stop="stop"
    />

    <SpottedPoint v-for="point in pickupRequests"
      :key="`PickupLocationMapObject:${pickupLocation.rowPointer}:${point.rowPointer}`" :spottedPoint="point" />
  </div>
</template>

<script>
import MapActivity from "@/components/maps/MapActivity";
import Pp4Poly from "@/components/maps/Poly";
import ContextMenu from "@/components/maps/ContextMenu";
import CompletedDriverStop from "@/components/maps/CompletedDriverStop";
import SpottedPoint from "@/components/maps/SpottedPoint";

import { GeoJson, LatLngs } from "@/services/GeoUtil";
import { GeometryEditMode } from "@/utils/Dto";

export default {
  extends: MapActivity,
  components: {
    ContextMenu,
    Pp4Poly,
    CompletedDriverStop, SpottedPoint,
  },
  props: {
    pickupLocation: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    return {
      editMode: GeometryEditMode.EditPoints,
      startPath: null,
      modifiedPath: null,
      removePointsEnabled: false,
      simplify: false
    };
  },
  computed: {
    startOrSimplifiedPath: vm => {
      if(vm.simplify) {
        const geo = LatLngs.toPolygonGeoJson(vm.startPath);
        const bbox = GeoJson.bbox(geo)
        const bboxGeo = GeoJson.bboxPolygon(bbox);
        const simplifiedLatLngs = GeoJson.toLatLngs(bboxGeo);
        return simplifiedLatLngs;
      }

      return vm.startPath;
    },
    completedDriverStops: (vm) => {
      const stops = vm.pickupLocation?.driverStops || [];
      const stopsWithInfo = stops.filter((s) => s.info != null);

      // normally the above is sufficient, but we have some stops without pickupPoint right now..
      return stopsWithInfo.filter((s) => s.info.pickupPoint != null);
    },
    pickupRequests: vm => vm.pickupLocation?.pickupRequests || [],
    saveDisabled: (vm) => vm.modifiedPath == null,
  },
  mounted() {
    const geo = this.pickupLocation.geoJson;
    if (GeoJson.isPoint(geo)) {
      const circleGeo = GeoJson.circle(geo, 100, {
        units: "meters",
        steps: 5,
      });

      this.startPath = GeoJson.toLatLngs(circleGeo);
    } else if (GeoJson.isPolygon(geo)) {
      this.startPath = GeoJson.toLatLngs(geo);
    } else {
      alert("Unknown Geometry -- Consider Re-Adding This Pickup Location");
      return;
    }

    // Remove last point so it doesn't double up.
    this.startPath.splice(this.startPath.length - 1, 1);

    this.fitBoundsForLatLngs(this.startPath, 55);
  },
  methods: {
    toggleSimplify() {
      this.simplify = ! this.simplify;
    },
    pathChanged(value) {
      this.modifiedPath = value;
    },
    removeModeToggled(remove) {
      this.editMode = remove
        ? GeometryEditMode.RemovePoints
        : GeometryEditMode.EditPoints;
    },
    async save() {
      const updatedPickupLocation = Object.assign({}, this.pickupLocation);
      updatedPickupLocation.geoJson = LatLngs.toPolygonGeoJson(
        this.modifiedPath
      );

      await this.$store.dispatch("putPickupLocation", updatedPickupLocation);

      this.$emit("done");
    },
    cancel() {
      this.$emit("done");
    },
  },
};
</script>
