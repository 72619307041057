<template>
  <b-button rounded size="is-small" style="margin-bottom: 4px" @click="$emit('click')">
    <b-icon custom-size="mdi-18px" icon="account-circle-outline"></b-icon>
  </b-button>
</template>

<script>
export default {};
</script>

<style scoped>
.button .icon {
  margin-top: 0.05em;
}
</style>
