<template>
  <div>
    <div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <span class="is-size-6"> {{ title }} </span>
        <icon-button
          v-if="!editing"
          icon="note"
          @click="toggleEditing"
          title="Route Notes"
        />
      </div>

      <div v-if="!editing">
        <span class="is-italic has-text-weight-light">
          {{ data }}
        </span>
      </div>
    </div>

    <div v-if="editing">
      <b-input type="textarea" v-model="notes" class="mb-1 mt-1"></b-input>

      <b-button :disabled="saveDisabled" size="is-small" is-warning @click="save"
        >Save Note</b-button
      >
      <b-button class="ml-2 is-warning" size="is-small" @click="cancel">Cancel Edit</b-button>
    </div>
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";
export default {
  components: {
    IconButton,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: String,
      required: false,
    },
    clearNotes: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      notes: "",
      editing: false,
    };
  },
  computed: {
    saveDisabled: (vm) => vm.notes === vm.data,
  },
  watch: {
    data: "updateNotesFromData",
  },
  mounted() {
    this.updateNotesFromData();
  },
  methods: {
    updateNotesFromData() {
      if (this.clearNotes) {
        this.notes = "";
      } else {
        this.notes = this.data;
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    save() {
      this.$emit("saveEvent", this.notes);
      this.editing = false;
    },
    cancel() {
      if (this.clearNotes) {
        this.notes = "";
      } else {
        this.notes = this.data;
      }
      this.editing = false;
    },
  },
};
</script>

<style lang="css" scoped>
.editable a {
  text-decoration: none;
  font-style: italic;
  color: black;
}

.editable a:visited {
  color: black;
}

.editable a:hover {
  text-decoration: underline;
}

.uneditable {
  font-style: italic;
  color: grey;
}
</style>
