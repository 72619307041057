<template>
  <google-map mapDivId="vroomMapDiv" :empty="true">
    <div class="vroom-wizard-wrapper">
      <sensible-sidebar position="left">
        <select-jobs :jobs="reconciledJobs" @change="selectedJobsChange($event)" />
      </sensible-sidebar>
      <div id="vroomMapDiv" />
      <sensible-sidebar position="right">
        <Tabs>
          <Tab title="Drivers" class="full-height-tab">
            <button @click="submitRequest">submit</button>
            <select-drivers :drivers="reconciledDrivers" @change="selectedDriversChange($event)" />
          </Tab>
          <Tab title="Route" class="full-height-tab">
            <adjust-routes :routes="currentRoutes" />
          </Tab>
        </Tabs>
      </sensible-sidebar>
    </div>
  </google-map>
</template>

<script>
import PageLoader from "@/components/common/PageLoader";
import GoogleMap from "@/components/maps/GoogleMap";
import SensibleSidebar from "@/components/common/SensibleSidebar";
import Tabs from "@/components/common/Tabs";
import Tab from "@/components/common/Tab";

import SelectDrivers from "./components/SelectDrivers.vue";
import SelectJobs from "./components/SelectJobs.vue";
import AdjustRoutes from "./components/AdjustRoutes.vue";

import { GeoJson } from "@/services/GeoUtil";

import customers from "./customers.json";

export default {
  components: {
    PageLoader,
    GoogleMap,
    SensibleSidebar,
    Tabs,
    Tab,
    SelectDrivers,
    SelectJobs,
    AdjustRoutes,
  },
  data: function () {
    return {
      request: {
        jobs: [],
        vehicles: [],
      },
    };
  },
  mounted() {
    this.refreshDrives();
  },
  methods: {
    submitRequest() {
      this.$store.dispatch("vroom/getRoutes", this.request);
    },
    refreshDrives() {
      this.$store.dispatch("vroom/getDrivers");
      this.$store.dispatch("vroom/getGeoTabDrivers");
    },
    selectedDriversChange(drivers) {
      this.request.vehicles = drivers.map((driver) => {
        const { id, warehouse } = driver;
        const { lat, lng } = warehouse.location;

        return {
          id,
          start: [lng, lat],
          end: [lng, lat],
          capacity: [],
          skills: [],
        };
      });
    },
    selectedJobsChange(jobs) {
      this.request.jobs = jobs.map((job) => {
        const { cut_num, lat, lng } = job;
        return {
          id: parseInt(cut_num),
          service: 300,
          delivery: [],
          skills: [],
          location: [parseFloat(lng), parseFloat(lat)],
        };
      });
    },
  },
  computed: {
    reconciledJobs() {
      return customers;
    },
    reconciledDrivers() {
      const drivers = this.$store.getters["vroom/drivers"];
      const geoTabDrivers = this.$store.getters["vroom/geoTabDrivers"];

      if (!drivers || !geoTabDrivers) return [];
      const ret = drivers
        .map((driver) => {
          const geoTabDriver = geoTabDrivers.find((v) => v.id === driver.geotabId);
          const location = GeoJson.toLatLngs(driver.warehouse.pointGeo);
          return {
            ...driver,
            geotabDriver: geoTabDriver,
            warehouse: {
              id: driver.warehouse.id,
              name: driver.warehouse.name,
              location,
            },
          };
        })
        .filter((d) => d.geotabDriver);
      return ret;
    },
    currentRoutes() {
      return this.$store.getters["vroom/routes"];
    },
  },
};
</script>

<style lang="css" scoped>
@font-face {
  font-family: "RedHat";
  src: local("RedHat"), url(../../assets/fonts/redhat/RedHatDisplay-Bold.ttf) format("truetype");
}
.vroom-wizard-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

:deep(h1) {
  font-weight: bold;
  font-size: large;
  text-align: center;
}

#vroomMapDiv {
  width: 100%;
  height: 100%;
}

.full-height-tab {
  margin: 2px;
  overflow: scroll;
}
</style>
