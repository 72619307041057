<template>
   <div>
     Vroom go here {{ warehouseId }}
   </div>
 </template>
 
 <script>
 export default {
   components: {},
   props: {
      warehouseId: {
         type: Number,
         required: true
      }
   },
   watch: {
   },
   data() {
     return {
     };
   },
   computed: {
   },
   mounted() {
   },
   methods: {
      
   },
 };
 </script>
 
 <style>
 </style>
 