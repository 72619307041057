<template>
   <div class="transparent-bg">
      <div class="content">
         <form action="#" @submit="submit">
            <div style="display: flex; flex-direction: row;">
               <b-select v-model="plasticTypeGroupId" 
                  size="is-medium">
                  <option v-for="o in plasticTypeGroups" :key="o.id" :value="o.id">
                     {{ o.name }}
                  </option>
               </b-select>
               <b-select v-model="plasticTypeId" class="ml-2"
                  size="is-medium">
                  <option :value="null">---</option>
                  <option v-for="o in availablePlasticTypes" :key="o.id" :value="o.id">
                     {{ o.name }}
                  </option>
               </b-select>
               <b-select v-model="unitId" class="ml-2"
                  size="is-medium">
                  <option v-for="o in units" :key="o.id" :value="o.id">
                     {{ o.plural }}
                  </option>
               </b-select>
            </div>

            <div class="mt-4" style="display: flex; justify-content: center;">
               <b-numberinput controls-position="compact" ref="unitValue"
                  controls-rounded size="is-medium" v-model="unitValue" :min="0" :max="10000"></b-numberinput>
            </div>

            <div class="" style="display: flex; flex-direction: row; justify-content: flex-end;">
               <b-button @click="submit" :disabled="disabled" size="is-medium" class="is-primary">Add</b-button>
            </div>
         </form>
      </div>
   </div>
</template>
 
<script>

export default {
   props: {
      pickupLocation: {
         type: Object,
         required: true,
      },
      defaultUnit: {
         type: Number,
         required: false,
         default: 1,
      },
      defaultPlasticTypeGroupId: {
         type: Number,
         required: false,
         default: 2
      },
      defaultPlasticTypeId: {
         type: Number,
         required: false,
         default: null
      }
   },
   data: function () {
      return {
         unitId: 1,
         unitValue: 0,
         plasticTypeGroupId: null,
         plasticTypeId: null
      }
   },
   watch: {
      defaultPlasticTypeGroupId: 'mounted',
      defaultPlasticTypeId: 'mounted',
      plasticTypeGroupId: 'plasticTypeGroupIdChanged'
   },
   computed: {
      plasticTypeGroups: vm => vm.$store.state.plasticTypeGroups,
      plasticTypes: vm => vm.$store.state.plasticTypes,
      availablePlasticTypes: vm => {
         return vm.plasticTypes.filter(t => t.plasticTypeGroupId === vm.plasticTypeGroupId)
      },
      units: vm => vm.$store.state.units,
      disabled: vm => 
         vm.plasticTypeGroupId == null
   },
   methods: {
      plasticTypeGroupIdChanged() {
       if(! this.plasticTypeId) {
         return;
       }

       const plasticType = this.plasticTypes.find(pt => pt.id === this.plasticTypeId);

       if(plasticType.plasticTypeGroupId !== this.plasticTypeGroupId) {
         this.plasticTypeId = null;
       }
      },
      async submit() {
         const pickupRequest = {
            location: this.pickupLocation.pointLatLng,
            unitId: this.unitId,
            unitValue: this.unitValue,
            plasticTypeGroupId: this.plasticTypeGroupId,
            plasticTypeId: this.plasticTypeId
         };

         try {
            const result = await this.$store.dispatch('postPickupRequest', {
               pickupLocationRowPointer: this.pickupLocation.rowPointer,
               pickupRequest
            })
            
            this.$toast.success('Request Added');
         }
         catch(e) {
            this.$telemetry.error(e);
         }
         
         this.$emit('close', {success: true});
      },
      mounted() {
         this.plasticTypeGroupId = this.defaultPlasticTypeGroupId;
         this.plasticTypeId = this.defaultPlasticTypeId

         this.$refs.unitValue.focus();
      }
   },
   mounted() {
      this.mounted();
   }
};
</script>
 
<style lang="css" scoped>
.transparent-bg {
   display: flex;
   align-items: center;
   justify-content: center;
}

.content {
   padding: 1em;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: #405465;
   border-radius: 1em;
   color: white;
   padding-bottom: 0.5em;
}
</style>
 