import * as VueRouter from 'vue-router'
// import { authGuard } from '../auth/authGuard';
// import { roleGuard } from '../role/roleGuard';
import routes from './routes';

const history = VueRouter.createWebHistory(import.meta.env.BASE_URL)

const router = VueRouter.createRouter({
  history,
  routes,
});

router.beforeEach((to, _from, next, huh) => {
  // authGuard(to, _from, next);
  // roleGuard(to, _from, next);
  next()
});

export default router;
