import Swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const noticeToast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
});

const error = (message) => {
  noticeToast.fire({
    icon: 'error',
    title: message || 'Whoops, something went wrong!',
  });
};

const success = (message) => {
  noticeToast.fire({
    icon: 'success',
    title: message || 'alright, something went right!',
  });
};

const info = (message) => {
  noticeToast.fire({
    icon: 'info',
    title: message || 'There should something useful here',
  });
};

export default {
  error,
  success,
  info,
};

export const ToastPlugin = {
  install(app, _options) {
    const instance = {
      error, success, info
    }

    app.config.globalProperties.$toast = instance
    app.provide("$toast", instance)
  },
};
