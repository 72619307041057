<template>
  <div v-if="drivers.length" class="main-wrapper">
    <div style="display: flex; flex-direction: column; flex: 1; overflow-y: none">
      <ul v-for="d in drivers" :key="`selectDriver:${d.id}`">
        <li
          @click="addToSelectedDrivers(d)"
          :style="`${selectedDrivers.includes(d) ? 'background-color: lightgreen;' : ''}`"
          class="list-item"
        >
          <label :for="d.checkboxId" class="name_label" :title="d.title">
            {{ getFullNameAny(d.user) }}
          </label>
        </li>
      </ul>
    </div>

    <warehouse
      v-for="warehouse in driverAtWarehouse"
      :warehouse="warehouse"
      :key="`warehouse:${warehouse.id}`"
    />
  </div>
</template>

<script>
import Warehouse from '@/components/maps/Warehouse';
import { getFullNameAny } from "@/services/UserUtil";

export default {
  components: { Warehouse },
  props: ["drivers"],
  data() {
    return {
      getFullNameAny,
      selectedDrivers: [],
    };
  },
  methods: {
    addToSelectedDrivers(driver) {
      const selectedDriverIndex = this.selectedDrivers.indexOf(driver);
      if (selectedDriverIndex < 0) {
        this.selectedDrivers.push(driver);
      } else {
        this.selectedDrivers.splice(selectedDriverIndex, 1);
      }
      this.$emit("change", this.selectedDrivers);
    },
  },
  computed: {
    driverAtWarehouse() {
      const warehouses = [];
      this.drivers.forEach((driver) => {
        if (!warehouses.some((w) => w.id == driver.warehouse.id)) {
          warehouses.push({ ...driver.warehouse, drivers: 1 });
        } else {
          const warehouseIndex = warehouses.findIndex((w) => w.id === driver.warehouse.id);
          warehouses[warehouseIndex].drivers += 1;
        }
      });
      return warehouses;
    },
  },
};
</script>

<style lang="css" scoped>
.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.selected {
  background-color: lightgreen;
}

.list-item {
  padding: 5px;
  border-top: solid 1px;
  border-right: solid 1px;
  border-left: solid 1px;
}
</style>
