<template>
  <div v-show="jobs.length" class="main-wrapper">
    <div style="display: flex; flex-direction: column; flex: 1; overflow-y: none">
      <ul v-for="job in jobs" :key="`selectDriver:${job.address}`">
        <li
          @click="addToSelectedJobs(job)"
          :style="`${selectedJobs.includes(job) ? 'background-color: lightblue;' : ''}`"
          class="list-item"
        >
          <label :for="job.checkboxId" :title="job.address">
            {{ job.address }}
          </label>
        </li>
      </ul>
      <button>Refresh</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["jobs"],
  data() {
    return {
      selectedJobs: [],
    };
  },
  methods: {
    addToSelectedJobs(job) {
      const selectedJobIndex = this.selectedJobs.indexOf(job);
      if (selectedJobIndex < 0) {
        this.selectedJobs.push(job);
      } else {
        this.selectedJobs.splice(selectedJobIndex, 1);
      }
      this.$emit("change", this.selectedJobs);
    },
  },
};
</script>

<style lang="css" scoped>
.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.list-item {
  padding: 5px;
  border-top: solid 1px;
  border-right: solid 1px;
  border-left: solid 1px;
}
</style>
