import { createStore } from "vuex";
import { createVroomWizardStore } from "@/views/VroomWizard/store";
import { createRouteWizardStore } from "@/views/RouteWizard/store";

export function createPcStore($api) {
  return createStore({
    modules: {
      vroom: createVroomWizardStore($api),
    },
    ...createRouteWizardStore($api),
  });
}
