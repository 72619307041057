<template>
    <individual-field v-slot="inputProps" :enabled="enabled" :label="label" @onSave="save" @onReset="reset">
        <slot name="left" />
        <input v-if="inputProps.disabled" :disabled="inputProps.disabled" :value="computedOwnerName" />
        <div v-if="!inputProps.disabled">
            <!-- See UserSelectDatalist instance.. -->
             
            <input class="users-list" list="global-users-datalist" name="owner" v-model="model" @keyup.enter="save" required />

        </div>
    </individual-field>
</template>

<script setup>
import { computed, inject } from 'vue';
import useSwr from 'swrv'
import LocalStorageCache from 'swrv/dist/cache/adapters/localStorage'

import { getFullNameAny } from "@/services/UserUtil";

const model = defineModel()

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: "User"
    },
    enabled: {
        type: Boolean,
        required: false,
        default: true,
    }
})

const $api = inject('$api')

const swrUrl = computed(() => {
    return `/dispatcher/users?all=true`
})

const usersSwr = useSwr(swrUrl, $api.fetch, {
    cache: new LocalStorageCache('swrv'),
    revalidateOnFocus: false
})

const users = computed(() => {
    if(usersSwr.isValidating.value) {
        return []
    }

    return usersSwr.data?.value?.results || []
})

const computedOwnerName = computed(() => {
    const user = users.value.find(u => u.id === model.value)
    if(!user) {
        return ""
    }
    
    return getFullNameAny(user)
})

const $emit = defineEmits(['save', 'reset'])

function save() {
    $emit("save", model.value)
}

function reset() {
    $emit("reset")
}
</script>

<style scoped lang="css">
.users-list {
    width: 35em;
    text-overflow: ellipsis;
}
</style>