/**
 * @returns Returns a string representation.
 * @param {unitValues} unitValues - Array of {value, unit}, where unit is a full Unit object.
 */
export function formatUnitValues(unitValues) {
   const byUnit = {};

   unitValues.forEach(r => {
      if(r.unit.plural in byUnit) {
         byUnit[r.unit.plural] += r.value;
      }
      else {
         byUnit[r.unit.plural] = r.value;
      }
   });

   const units = Object.keys(byUnit);
   if(units.length === 0) {
      return 'No Material'
   }

   const ret = [];
   units.forEach((unit) => {
      ret.push(byUnit[unit] + ' ' + unit);
   })

   return ret.join(', ');
}

export function formatMaterialUnitValues(materialUnitValues) { // [{value, unit, plasticTypeGroup, plasticType}, ...]
   const valueAndUnitByAgg = {}
   
   // group by plasticTypeGroup/plasticType/unit, sum value
   materialUnitValues.forEach(v => {
      if(! (v?.plasticTypeGroup || v?.plasticType || v?.unit)) {
         console.warn("Invalid materialUnitValue: ", v);
         return
      }

      const agg = (v?.plasticTypeGroup?.id ?? 'N/A') + ' ' + (v?.plasticType?.id ?? 'N/A') + ' ' + v.unit.id;
 
      if(agg in valueAndUnitByAgg) {
         valueAndUnitByAgg[agg].value += v.value
      }
      else {
         valueAndUnitByAgg[agg] = { 
            value: v.value, 
            plasticTypeGroup: v.plasticTypeGroup, 
            plasticType: v.plasticType, 
            unit: v.unit 
         };
      }
   });

   const aggs = Object.keys(valueAndUnitByAgg);
   if(aggs.length === 0) {
      return 'No Material'
   }

   const ret = [];
   aggs.forEach((agg) => {
      const data = valueAndUnitByAgg[agg];
      ret.push(data.value + ' ' + (data?.plasticTypeGroup?.name ?? 'N/A') 
         + ' ' + (data?.plasticType?.name ?? 'N/A') + ' ' +
         (data?.value > 1 ? data?.unit?.plural : data?.unit?.singular));
   })

   return ret.join(', ');
}


export function formatAge(date) {
   if(! date) {
      return "";
   }

   const diff = new Date().getTime() - date.getTime();
   if(diff <= 0) {
      return date.toDateString();
   }

   const OneHourInMs = 1000 * 60 * 60;
   if(diff < OneHourInMs) {
      return `${Math.round(diff / 60 / 1000) + ' min'}`;
   }

   const OneDayInMs = 24 * 60 * 60 * 1000;
   if(diff < OneDayInMs) {
      return `${Math.round(diff / 60 / 60 / 1000) + ' hrs'}`;
   }

   return `${Math.round(diff / 24 / 60 / 60 / 1000) + ' days'}`;
}

export function formatMaterial({plasticTypeGroup, plasticType}) {
   if(! (plasticTypeGroup || plasticType)) {
      return 'N/A'
   }

   let ret = '';
   if(plasticTypeGroup) {
      ret = `${plasticTypeGroup.name}`;
   }

   if(plasticType) {
      if(ret.length) {

         if(plasticType.id === 8) {
            return ret; // special case to prevent 'Silage - Silage'
         }

         ret += ", ";
      }

      ret += `${plasticType.name}`;
   }

   return ret;
}
