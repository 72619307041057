<template>

</template>

<script setup>
import { ref, watch, onUnmounted, toRaw } from 'vue'

import { useMaps, useGmpEvents, useAdvancedMarkerElementEvents } from '@/composables/useMaps'

const props = defineProps({
    latLng: {
        type: Object,
        required: true,
        validator: function (value) {
            return !(value == null || Number.isNaN(value.lat) || Number.isNaN(value.lng))
        }
    },
    labelText: {
        type: String,
        required: false // it or labelHtml must be provided
    },
    labelHtml: {
        type: String,
        required: false
    },
    elementClass: {
        type: String,
        required: true
    },
    targetPane: {
        type: String,
        required: false,
        default: 'overlayMouseTarget'
    },
    clickable: {
        type: Boolean,
        required: false,
        default: false
    },
    fg: {
        type: String,
        required: false,
    },
    bg: {
        type: String,
        required: false,
    },

    // //events
    onClick: {
        required: false
    },
    onMouseenter: {
        required: false
    },
    onMouseleave: {
        required: false
    }
})

const maps = useMaps()

const labelMapObjectRef = ref(null)

onUnmounted(() => {
    const val = labelMapObjectRef.value
    if (val) {
        val.setMap(null)
        toRaw(val).setMap(null)
        labelMapObjectRef.value = null
    }
})

function mapReady() {
    const val = labelMapObjectRef.value

    const label = document.createElement('div')
    label.style.userSelect = 'none'
    props.elementClass.split(' ').forEach(c => label.classList.add(c))
    label.innerHTML = props.labelHtml || props.labelText

    if (props.fg) {
        label.style.color = props.fg
    }
    if (props.bg) {
        label.style.backgroundColor = props.bg
    }

    if (props.onMouseenter) {
        label.addEventListener('mouseenter', () => {
            props.onMouseenter()
        })
    }
    if (props.onMouseleave) {
        label.addEventListener('mouseleave', () => {
            props.onMouseleave()
        })
    }

    if (val) {
        val.content = label
        val.position = props.latLng

        return
    }

    const marker = new maps.api.AdvancedMarkerElement({
        position: props.latLng,
        content: label, map: toRaw(maps.map)
    })


    labelMapObjectRef.value = marker

    useAdvancedMarkerElementEvents(maps.api, labelMapObjectRef.value, props, ['onClick'])
}

watch(props, mapReady, { immediate: true })
</script>
