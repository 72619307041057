<template>
   <div class="content">
      <div v-for="(a, i) in associations" class="association"
         :key="`associatedriver:${a.driver.user.userId}`">
         <span class="half driver pr-2">
            {{ UserUtil.getFullNameAny(a.driver.user) }}
         </span>

         <span class="half">
            <b-select v-model="associationIds[i]" placeholder="Select vehicle..." size="is-small" @input="associationChanged(a.driver, $event)">
               <option value="">--None--</option>
               <option v-for="gtd in geotabDrivers" 
                     :key="`associatedrivers:${a.driver.user.userId}:${gtd.id}`"
                     :value="gtd.id">
                  <template v-if="gtd">
                     {{ gtd.name  }}
                     ({{ gtd.firstName }} {{ gtd.lastName }})
                     -- {{ gtd.licenseProvince }} ({{ gtd.id }})
                  </template>
                  <template v-else>
                     {{ gtd.device.id }}
                  </template>
               </option>
            </b-select>
         </span>
      </div>
   </div>
</template>
 
<script>
import * as UserUtil from '@/services/UserUtil';

export default {
   props: {
      geotabDrivers: {
         type: Array,
         required: true,
      },
      drivers: {
         type: Array,
         required: true,
      },
   },
   data: function () {
      return {
         UserUtil,
         associations: [],
         associationIds: []
      }
   },
   watch: {
      drivers: 'updateAssociations',
      geotabDrivers: 'updateAssociations'
   },
   computed: {
   },
   methods: {
      associationChanged(driver, event) {
         const newGeotabId = event.target.value

         const updatePayload = {
            id: driver.id, geotabId: newGeotabId
         };

         this.$store.dispatch('updateDriver', updatePayload);
      },
      updateAssociations() {
         this.associations = this.drivers.map(d => {
            return {
               driver: d,
               geotabDriver: d.geotabDriver || null
            }
         });

         this.associationIds = this.associations.map(a => 
            a.driver.geotabId || null);
      }
   },
   mounted() {
      this.updateAssociations();
   }
};
</script>
 
<style lang="css" scoped>
.content {
   display: flex;
   flex-direction: column;
   align-items: center;
   background-color: #405465;
   border-radius: 1em;
   color: white;
   padding-bottom: 0.5em;
}

.driver {
   text-align: right;
}

.association {
   white-space: nowrap;
   margin-top: 0.5em;
   padding: 0.25em;
   font-size: smaller;
   border-radius: 0.25em;
   min-width: 60vw;
   max-width: 50vw;
   justify-self: center;
   justify-content: center;
   align-items: center;
   background-color: var(--primary);
   display: flex;
   flex-direction: row;
}

.half {
   width: 50%;
}
</style>
 