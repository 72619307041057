<template>
  <template v-if="pickupRequestsVisible">
    <PickupRequest v-for="(item, index) in pickupRequests" :key="index"
      @click="pickupRequestClickHandler" :pickupRequest="item" />
  </template>

  <!-- <pp4-label v-for="(item, index) in currentCounties" :visible="countiesVisible" :key="index + 'label'"
      :latLng="item.point" :labelText="`${labelCount(item.count)}`"
      elementClass="county-pickup-request-cluster-label" /> -->
</template>

<script>
import Pp4Label from "@/components/maps/Label";
import PickupLocation from "@/components/maps/PickupLocation";
import PickupRequest from "@/components/maps/PickupRequest";
import Pp4MapObject from "@/components/maps/Pp4MapObject";

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Label,
    PickupLocation,
    PickupRequest,
  },
  props: {
    pickupRequests: {
      required: false,
    }
  },
  mounted() { },
  data() {
    return {
      zoomThreshold: 10,
    };
  },
  render() {
    return null;
  },
  computed: {
    currentCounties: vm => {
      let ret = [];

      for (const { pickupRequest } of vm.pickupRequests) {
        let county = {};
        const include = !ret.some(
          (c) => c.name == pickupRequest.pickupLocation.addCounty
        );
        if (include) {
          const { unit, unitValue } = pickupRequest;
          county.name = pickupRequest.pickupLocation.addCounty;
          county.point = pickupRequest.pickupLocation.pointLatLng;
          county.count = {};
          county.count[unit.plural.toLowerCase()] = unitValue;
          ret.push(county);
        } else {
          const { unit, unitValue, pickupLocation } = pickupRequest;
          const { addCounty } = pickupLocation;
          const index = ret.findIndex(
            (cc) => cc.name == addCounty
          );
          ret[index].count[unit.plural.toLowerCase()] +=
            unitValue;
        }
      }

      return ret;
    },
    zoomLevel: vm => vm.provider.zoomLevel,
    filtered: (vm) => {
      return !vm.$store.state.layers.pickupRequests;
    },
    pickupRequestsVisible: vm => {
      if (vm.filtered) {
        return false;
      }

      return true;
      //const ret = vm.zoomLevel > vm.zoomThreshold;
      //console.log("! WTF? " + ret)
      //return ret;
    },
    countiesVisible: vm => {
      if (vm.filtered) {
        return false;
      }

      return vm.zoomLevel <= vm.zoomThreshold;
    },
  },
  watch: {
    pickupRequests: 'mapReady',
  },
  emits: ['pickupRequestClicked'],
  methods: {
    pickupRequestClickHandler(e) {
      this.$emit("pickupRequestClicked", e);
    },
    labelCount(count) {
      let ret = "";
      for (const key in count) {
        ret += `${key}: ${count[key]} `;
      }
      return ret;
    },
  },
};
</script>

<style lang="css">
.county-pickup-request-cluster-label {
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  color: white;
  position: absolute;
  background: rgba(0, 157, 224, 0.7);
  border: solid white 1px;
  padding: 0.5em;
  text-align: center;
}
</style>
