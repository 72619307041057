<template>
  <div class="card">
    <div class="card-content">
      <b-message v-if="stopInvolvedInRebate" size="is-small" has-icon icon="cash-multiple">
        <span>This review item is involved in a rebate and cannot be changed.</span>
      </b-message>
      <div style="display: flex; flex-direction: row; justify-content: space-evenly">
        <!-- Driver Items -->
        <div class="review-item-rows">
          <div class="is-size-6 has-text-centered has-text-weight-bold">Driver Count</div>
          <div v-if="driverItems.length == 0" class="driver-review-item-row">
            <div class="review-item-column">
              <label class="roll-count" style="color: #777777">None</label>
            </div>
          </div>
          <div v-else v-for="item in driverItems" :key="`driverItem:${item.plasticTypeId}`"
            class="driver-review-item-row">
            <div class="review-item-column">
              <plastic-type-selection :value="item.plasticTypeId" :enabled="false" />
            </div>
            <div class="review-item-column ml-2">
              <label class="roll-count" style="color: #777777">{{ item.rollCount }}</label>
            </div>
          </div>
        </div>

        <!-- Reviewer Items -->
        <div class="review-item-rows">
          <div class="is-size-6 has-text-centered has-text-weight-bold">Reviewer Count</div>
          <div v-for="(item, index) in cachedItems" :key="`cachedItem:${item.plasticTypeId}`"
            class="reviewer-review-item-row">
            <div class="review-item-column">
              <plastic-type-selection :value="item.plasticTypeId" :enabled="!stopInvolvedInRebate"
                @change="reviewerPlasticTypeChanged(item, index, $event)" :usedIds="reviewerPlasticTypeIdsInUse"
                :suppliedPlasticTypeIds="driverItems.map(di => di.plasticTypeId)" />
            </div>
            <div class="review-item-column">
              <b-numberinput size="is-small" :disabled="stopInvolvedInRebate" controls-alignment="right"
                controls-position="compact" v-model="item.rollCount" :placeholder="item.rollCount" :min="0" :max="100"
                class="ml-1" />
            </div>
            <div class="review-item-column">
              <b-tooltip :active="index == 0 && !stopInvolvedInRebate" multilined
                label="You must have at least one plastic type to submit for rebate.">
                <b-button size="is-small" :disabled="index == 0 || stopInvolvedInRebate" type="is-danger"
                  icon-right="close-circle" @click="removeReviewerItem(item)" />
              </b-tooltip>
            </div>
          </div>
          <div class="reviewer-buttons-box">
            <b-button label="Add" class="is-small" :disabled="stopInvolvedInRebate || !canAdd"
              @click="addReviewerItem" icon-left="plus-circle" />
            <b-tooltip :active="selectedHasItemWithZeroRolls" position="is-left" multilined
              label="You are saving a review item as zero rolls.">
              <b-button label="Save" :loading="loadingSubmit"
                :class="`${selectedHasItemWithZeroRolls ? 'is-warning' : ''} is-small`" :disabled="!canSave"
                @click="save" icon-left="content-save" />
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlasticTypeSelection from '../PlasticTypeSelection.vue';
import { PickupItem } from '../DriverStopReviewForm.vue';

import toastService from '../../../../services/toastService';

export default {
  components: { PlasticTypeSelection },
  props: {
    driverItems: {
      type: Array,
      required: true,
    },
    cachedItems: {
      type: Array,
      required: true,
    },
    reviewItems: {
      type: Array,
      required: true,
    },
    driverStopRowPointer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingSubmit: false,
    };
  },
  computed: {
    stopInvolvedInRebate: (vm) => {
      return vm.reviewItems.some((i) => i.rebateLineItem != null);
    },
    canSave: (vm) => !vm.stopInvolvedInRebate && vm.cachedItems.length > 0,
    reviewerPlasticTypeIdsInUse: (vm) => {
      const ret = new Set();
      vm.cachedItems.forEach((i) => ret.add(Number.parseInt(i.plasticTypeId)));
      return ret;
    },
    canAdd: (vm) => vm.reviewerPlasticTypeIdsInUse.size < 7,
    selectedHasItemWithZeroRolls: (vm) => {
      let hasZeroRollsFlag = false;

      vm.cachedItems.forEach((element) => {
        hasZeroRollsFlag |= element.rollCount == 0;
      });

      return Boolean(hasZeroRollsFlag);
    },
  },
  methods: {
    reviewerPlasticTypeChanged(item, index, newPlasticTypeId) {
      item.plasticTypeId = newPlasticTypeId;
      this.cachedItems.splice(index, 1, item);
    },
    addReviewerItem() {
      let newId = null;

      // search for a new type id until we find one
      for (let i = 1; i < 1000; i++) {
        if (!this.reviewerPlasticTypeIdsInUse.has(i)) {
          newId = i;
          break;
        }
      }

      if (!newId) {
        console.error('Failed to Add Because Could Not Find Valid Plastic Type Id');
        return;
      }

      this.cachedItems.push(new PickupItem(newId, 0));
    },
    removeReviewerItem(item) {
      this.cachedItems.splice(this.cachedItems.indexOf(item), 1);
    },
    async save() {
      this.loadingSubmit = true;

      try {
        const rowPointer = this.driverStopRowPointer;
        const reviewItems = [];

        // get review items
        this.cachedItems.forEach((element) => {
          reviewItems.push({
            driverStopRowPointer: rowPointer,
            plasticTypeId: element.plasticTypeId,
            rollCount: element.rollCount,
          });
        });

        // generate response data
        const responseData = {
          driverStopRowPointer: rowPointer,
          items: reviewItems,
        };

        await this.$store.dispatch('putDriverStopReview', responseData);
        this.$emit('onPut');

        toastService.success('Review Item saved');
      } catch (error) {
        toastService.error("Review Item could not be saved. Check your browser's log for details.");
      }

      this.loadingSubmit = false;
    },
  },
};
</script>

<style lang="css" scoped>
.reviewer-buttons-box {
  display: flex;
  flex-direction: row;
  column-gap: 1em;
  justify-content: space-between;
}

.review-item-rows {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 228.5px;
  gap: 0.5em;
  padding: 0.25em;
}

.driver-review-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25em;
  padding: 0.5em;
}

.reviewer-review-item-row {
  display: flex;
  flex-direction: row;
  border-radius: 0.25em;
  padding: 0.5em;
}

.review-item-column {
  text-align: center;
  padding: 0.25em;
}

.review-item-column:first-child {
  flex-grow: 1;
}

.roll-count {
  font-size: large;
  padding: 1.5px;
  min-width: 5.25em;
  border-radius: 0.25em;
  vertical-align: middle;
  display: inline-block;
}
</style>
