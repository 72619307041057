<template>
  <map-controls position="RIGHT_TOP" v-if="provider.mapLoaded">
    <div class="layer-filters-div">
      <section>
        <b-field>
          <b-switch v-model="displayPickupLocations">Locations</b-switch>
        </b-field>
        <b-field class="mt-1">
          <b-switch v-model="displayPickupRequests">Requests</b-switch>
        </b-field>
        <b-field class="mt-1">
          <b-switch v-model="displayDriverStops">Stops</b-switch>
        </b-field>
        <b-field class="mt-1">
          <b-switch v-model="displayDrivers">Drivers</b-switch>
        </b-field>
        <b-field class="mt-3">
          <b-switch v-model="displayDriverRegions">Driver Regions</b-switch>
        </b-field>
        <b-field class="mt-1">
          <b-switch v-model="displayPFPURegions">PFPU Regions</b-switch>
        </b-field>
      </section>
    </div>
  </map-controls>
</template>

<script setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import MapControls from "@/components/maps/MapControls";

const store = useStore();
const provider = inject("provider");

const createToggleLayer = (layerName) => {
  return computed({
    get() {
      return store.state.layers[layerName];
    },
    set() {
      store.dispatch("toggleLayer", layerName);
    },
  });
};

const displayPickupLocations = createToggleLayer("pickupLocations");
const displayPickupRequests = createToggleLayer("pickupRequests");
const displayDriverStops = createToggleLayer("driverStops");
const displayDrivers = createToggleLayer("drivers");
const displayDriverRegions = createToggleLayer("regionDriverConfigs");
const displayPFPURegions = createToggleLayer("regionPfpuConfigs");
</script>

<style lang="css">
.layer-filters-div {
  display: inline-flex;
  flex-direction: column;
  background: white;
  margin-right: 12px;
  margin-top: 12px;
  padding: 1em;
}
</style>
