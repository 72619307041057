<template>
  <div class="tab-wrapper" v-show="tabData.isActive">
    <slot v-if="tabData.wasEverActive"></slot>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: "Tab",
    },
  },
  inject: ["tabsData"],
  computed: {
    tabData(){
      return this.tabsData.find((tab) => tab.title === this.title);
    },
  },
};
</script>

<style>
.tab-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}
</style>
