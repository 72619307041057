<template>
  <div class="top">
    <div v-if="requests.length">
      <spotted-point-card
        v-for="r in requests"
        :key="`pickuprequestslist:${myKey}:${r.rowPointer}`"
        :value="r"
      />
    </div>

    <div v-else>No Pickup Requests</div>
  </div>
</template>

<script>
import Pp4Label from "@/components/maps/Label";
import PickupRequest from "@/components/maps/PickupRequest";
import SpottedPointCard from "./SpottedPointCard.vue";

export default {
  props: {
    activeFrame: {
      type: Object,
      required: false,
    },
    requests: {
      type: Array,
      required: true,
    },
    
  },
  components: {
    Pp4Label, PickupRequest,
    SpottedPointCard,
  },
  data() {
    return {
      myKey: new Date().getTime().toString(),
    };
  },
  computed: {
    requestsWithMetadata: (vm) => {
      const ret = vm.requests.map((p) => Object.assign({}, p));

      // sort pickup location spotted points for latest first
      ret.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

      if (vm.activeFrame) {
        // if the spotted point was within the active timeframe
        ret.forEach((r) => {
          if (
            r.createdOn >= vm.activeFrame.previousDsInfoCreatedOn &&
            r.createdOn <= vm.activeFrame.currentDsInfoCreatedOn
          )
            r.inActiveFrame = true;
        });
      }

      let meta;
      if (ret.length) {
        meta = {
          requests: ret.length,
          point: {
            lat: ret[0].pointLatLng.lat - 0.00002,
            lng: ret[0].pointLatLng.lng,
          },
          total: ret.reduce((acc, i) => {
            acc += i.rollCount;
            return acc;
          }, 0),
        };
      }
      return meta ? meta : null;
    }
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.top {
  position: relative;
}

.loading {
  display: block;
  height: 2em;
  width: 100%;
}

.error {
  text-align: center;
  color: red;
  font-weight: bold;
}
</style>
