<template>
  <b-taginput
    :autocomplete="true"
    v-model="workingValue"
    :data="filteredDrivers"
    type="is-success"
    maxtags="1"
    :has-counter="false"
    field="userNameOrEmail"
    size="is-small"
    placeholder="Who's in charge?"
    @typing="updateFilter"
  >
  </b-taginput>
</template>

<script>
export default {
  name: 'DriverSelect',
  components: {},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: ['value'],
  data() {
    return {
      startingValue: null,
      workingValue: [],
      filter: '',
    };
  },
  computed: {
    driversSearchable: vm => {
      const fullDrivers = vm.$store.state.drivers.data;
      const drivers = fullDrivers.map(d => d.user);
      return drivers.map(d => ({...d, 
        userNameOrEmail: d?.pcUserName?.length ?
          d.pcUserName : (d.userEmail || "")
      }));
    },
    filteredDrivers: (vm) => {
      return vm.driversSearchable.filter((d) => {
        if(d?.userNameOrEmail?.includes(vm.filter)) {
          return true;
        }

        return false;
      });
    },
  },
  watch: {
    workingValue: 'emitIfNecessary',
    value: 'initFromValue',
  },
  mounted() {
    if(! this.driversSearchable.length) {
      this.$store.dispatch('getDrivers');
    }

    this.initFromValue();
  },
  methods: {
    initFromValue() {
      this.startingValue = this.value;

      if (this.value) {
        this.workingValue.splice(0, this.workingValue.length, this.value);
      } else {
        this.workingValue.splice(0, this.workingValue.length);
      }
    },
    emitIfNecessary() {
      if (this.startingValue && this.workingValue.length > 0 && this.startingValue.id === this.workingValue.id) {
        return;
      }

      if (this.workingValue.length === 0) {
        if (this.startingValue != null) {
          this.$emit('change', null);
        }
        return;
      }

      this.$emit('change', this.workingValue[0]);
    },
    updateFilter(text) {
      this.filter = text;
    },
  },
};
</script>

<style scoped></style>
