<template>
  <b-button
    style="border: 2px solid grey; border-radius: 5px"
    class="is-small"
    :icon-right="icon"
    :type="type"
    :title="title"
    @click="$emit('click')"
    @dblclick="$emit('dblclick')"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['click', 'dblclick']
};
</script>

<style lang="css" scoped>
:deep(.icon.is-small > i) {
  font-size: 18px;
}
</style>
