<template>
  <div>
    <slot />
  </div>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';

export default {
  extends: Pp4MapObject,
  mounted() {
    this.$store.dispatch('mapActivityActive');
  },
  beforeUnmount() {
    this.$store.dispatch('mapActivityDone');
  },
};
</script>
