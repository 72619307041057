<template>
  <div :class="classes">
    <!-- <div class="card-header" v-if="value.info && value.info.pickupPoint">
      <p class="card-header-title">
        <span>
          {{ new Date(value.info.createdOn).toLocaleString() }}
          by
          <User :value="value.route.assignedToUser" />
        </span>
      </p>
    </div> -->
    <div class="card-content">
      <div class="content">
        <div v-if="value.info">
          <span>
          {{ new Date(value.info.createdOn).toLocaleString() }}
          by
          <User :value="value.route.assignedToUser" />
          </span>
        </div>
        <div v-if="value.info && value.info.pickupPoint">
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
            <div style="display: flex; flex-direction: column; text-overflow: ellipsis; overflow: hidden">
              {{ materialSummary }}
            </div>
            <div style="display: flex; flex-direction: row; align-items: center">
              {{ value.info.beforeImage ? 'Before' : null }}
              <image-button-set class="ml-1 mr-1"  :image="value.info.beforeImage" />
              {{ value.info.afterImage ? 'After' : null }}
              <image-button-set class="ml-1 mr-4" :image="value.info.afterImage" />
              <PanToButton v-if="value.info.pickupPoint" :location="value.info.pickupPoint" />
            </div>
          </div>
          <div v-if="value.info.notes && value.info.notes.length > 0" class="subform">
            <b>Driver Notes</b>
            {{ value.info.notes }}
          </div>
        </div>
        <div v-else>
          <div style="display: flex; flex-direction: column; text-overflow: ellipsis; overflow: hidden">
            <span
              >Routed
              <span class="is-uppercase has-text-weight-bold">
                {{ new Date(value.createdOn).toLocaleString() }}
              </span>
              for
              <User :value="value.route.assignedToUser" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanToButton from '@/components/common/PanToButton.vue';
import User from '@/components/common/User';
import ImageButtonSet from '@/components/common/ImageButtonSet.vue';
import * as FormatUtil from '@/services/FormatUtil';

export default {
  props: {
    value: {
      type: Object,
    },
  },
  components: {
    PanToButton,
    User,
    ImageButtonSet,
  },
  data: function() {
    return {
      FormatUtil
    }
  },
  mounted() {
  },
  computed: {
    classes: (vm) => {
      const ret = ['card', 'mt-1'];

      if(vm.value?.info) {
        ret.push('completed');
      }
      else {
        ret.push('not-completed')
      }

      return ret;
    },
    materialSummary: (vm) => {
      const info = vm.value.info;

      let sum = 0;
      for (let index = 0; index < 8; index++) {
        sum += info[`weight${index}`];
      }

      let remSum = 0;
      for (let index = 0; index < 8; index++) {
        remSum += info[`remWeight${index}`];
      }

      if(sum === 0 && remSum === 0) {
        return 'Skipped';
      }

      let ret = '';

      if(sum > 0) {
        const collectedMaterial = FormatUtil.formatUnitValues([
          {unit: info.unit, value: sum}
        ]);

        ret += `${collectedMaterial} Collected`;
      }

      // TODO: reference 'remUnit' property instead of 'unit' when we get that in...
      if(remSum > 0) {
        const remainingMaterial = FormatUtil.formatUnitValues([
          {unit: info.unit, value: remSum}
        ]);

        if(sum > 0) {
          ret += ', ';
        }

        ret += `${remainingMaterial} Remaining`;
      }

      return ret;
    },
  },
};
</script>

<style lang="css" scoped>
.completed {
  background: #77838f;
  color: #cccccc;
}

.not-completed {
  background: #77838f;
  color: white;
  font-weight: bold;
}
</style>
