<template>
  <span v-if="enabled">
    <b-select :disabled="!enabled" size="is-small" v-model="plasticType">
      <option v-for="(plasticType, index) in availablePlasticTypesByName" :key="index" :value="`${plasticType.id}`"
        :disabled="isDisabled(plasticType.id)">{{ formatMaterial({ plasticType }) }}</option>
    </b-select>
  </span>
  <span v-else class="styled-plastic-type">
    {{ name }}
  </span>
</template>

<script>
// TODO: rewrite this to use plastic type ids from the database
import { formatMaterial } from './../../../services/FormatUtil'

export default {
  name: 'PlasticTypeSelection',
  components: {},
  props: {
    value: {
      type: Number,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    usedIds: {
      type: Set,
      required: false,
      default: () => new Set(),
    },
    suppliedPlasticTypeIds: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits:['change'],
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      formatMaterial,
      defaultPlasticTypes:
        [{ id: 1, name: 'N/A' },
        { id: 2, name: 'White' },
        { id: 3, name: 'Blue' },
        { id: 4, name: 'Pink' },
        { id: 5, name: 'Butt' },
        { id: 6, name: 'Diamond' },
        { id: 7, name: 'Grain Bags' }],
    };
  },
  computed: {
    name: (vm) => {
      const val = Number.parseInt(vm.value);
      return vm.plasticTypes.find(pt => pt.id === val)?.name ?? "Unknown";
    },
    plasticTypeGroups: vm => vm.$store.state.plasticTypeGroups,
    plasticTypes: vm => vm.$store.state.plasticTypes,
    availablePlasticTypesByName: vm =>
      // [[id, name], ..]  
      Object.entries((
        { // { [id]: pt } 
          // get the driver supplied plastic types
          ...vm.plasticTypes.filter(pt => vm.suppliedPlasticTypeIds.includes(pt.id))
            .map(pt => ({
              ...pt,
              // and qualify their names with their group name
              name: `${vm.plasticTypeGroups.find(ptg => ptg.id === pt.plasticTypeGroupId).name} ${formatMaterial({ plasticType: pt })}`
              // then key the name by the id and spread into the parent
            })).reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
          // get the default plastic types, key by id, spread (overwriting duplicates from supplied) 
          ...vm.defaultPlasticTypes.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {})
        }
        // map the [id, name] tuples back to PlasticType without group ID
      )).map(e => ({ id: Number.parseInt(e[0]), name: e[1].name })),
    plasticType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', Number.parseInt(value));
      },
    },
  },
  mounted() { },
  methods: {
    isDisabled(id) {
      return id !== this.value && this.usedIds.has(id);
    },
  },
};
</script>

<style lang="css">
.styled-plastic-type {
  padding: 0.25em;
  min-width: 8em;
  border-radius: 0.25em;
  text-align: center;
  display: inline-block;
  line-height: 2em;
  user-select: none;
  cursor: default;
}

option:disabled {
  background: #dddddd;
}
</style>
