<template>
  <div>
    <context-menu>
      <div class="container">
        <div style="
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            margin-bottom: 5px;
          ">
          <div v-if="target">
            <span class="has-text-weight-bold" style="color: black; font-size: 12px">TARGET
              <b-tooltip label="The location everything ends up at."
                type="is-info" position="is-right" multilined>
                <b-icon icon="information-outline" size="is-small" /> </b-tooltip></span>
            <p class="is-uppercase" style="color: black; font-size: 12px">
              name: {{ target.name }}
            </p>
          </div>

          <div v-if="source">
            <span class="has-text-weight-bold" style="color: black; font-size: 12px">MERGEE
              <b-tooltip label="This location will be merged into target." type="is-info"
                position="is-right" multilined>
                <b-icon icon="information-outline" size="is-small" /> </b-tooltip></span>
            <p class="is-uppercase" style="color: black; font-size: 12px">
              name: {{ source.name }}
            </p>
          </div>
        </div>

        <div v-show="warning === 'bad_source'" style="padding: 5px">
          <p style="color: red; font-size: 12px">
            Locations with a stop history can not be merged.
          </p>
          <p style="color: red; font-size: 12px">
            If you do not want to see this pickup location any longer, "Archive" it.
          </p>
        </div>
        <div v-show="warning === 'select_source'" style="padding: 5px">
          <p style="color: black; font-size: 12px">
            Click the location to be merged into the target.
          </p>
        </div>
        <div v-show="warning === 'checking_source'" style="padding: 5px">
          <p style="color: grey; font-size: 12px">
            Checking source data...
          </p>
        </div>

        <div class="button-container">
          <b-button :expanded="true" icon-left="cancel" size="is-small" type="is-danger" style="margin-right: 5px"
            @click="cancel" />

          <b-button v-show="source && (!sourceCheckState)" :expanded="true" size="is-small" type="is-primary" @click="merge">Merge</b-button>
        </div>
      </div>
    </context-menu>
  </div>
</template>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject";
import ContextMenu from "@/components/maps/ContextMenu";

export default {
  extends: Pp4MapObject,
  components: {
    ContextMenu,
  },
  data: function () {
    return {
      isLoading: false,
      target: null,
      sourceCheckState: null
    };
  },
  mounted() {
    this.target = this.selectedPickupLocation;
  },
  computed: {
    selectedPickupLocation: (vm) => vm.$store.state.selectedPickupLocation,
    source: vm => {
      if (!vm.target) {
        return null;
      }
      if (vm?.target?.id === vm?.selectedPickupLocation?.id) {
        return null;
      }

      return vm.selectedPickupLocation;
    },
    warning: vm => {
      if (!vm.target) {
        return null;
      }
      if (!vm.source) {
        return 'select_source';
      }

      if (vm.sourceCheckState === 'checking') {
        return 'checking_source';
      }

      if (vm.sourceCheckState === 'bad') {
        return 'bad_source';
      }

      return null;
    }
  },
  watch: {
    source: 'updateSourceValid'
  },
  methods: {
    async merge() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("mergePickupLocations", {
          target: this.target,
          source: this.source
        });
      }
      catch (e) {
        this.$store.dispatch("error", "Merge Failed!")
      }
      this.isLoading = false;
      this.$emit("done");
    },
    cancel() {
      this.$emit("done");
    },
    async updateSourceValid() {
      this.sourceValid = false;

      if (!this.source) {
        return;
      }

      this.sourceCheckState = "checking"

      try {
        const result = await this.$store.getters["pickupLocationStops"](
          this.source.rowPointer
        );
        const data = result?.data;
        if (data.count == 0) {
          this.sourceCheckState = null;
        } else {
          this.sourceCheckState = "bad";
        }
      } catch (e) {
        this.$store.dispatch("error", e);
      }
    }
  }
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.75rem;
  width: 350px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
