export function createVroomWizardStore($api) {
  return {
    namespaced: true,
    state: () => ({
      drivers: [],
      geoTabDrivers: [],
      routes: [],
    }),
    mutations: {
      setDrivers(state, payload) {
        state.drivers = payload;
      },
      setGeoTabDrivers(state, payload) {
        state.geoTabDrivers = payload;
      },
      setRoutes(state, payload) {
        state.routes = payload;
      },
    },
    getters: {
      drivers(state) {
        return state.drivers;
      },
      geoTabDrivers(state) {
        return state.geoTabDrivers;
      },
      routes(state) {
        return state.routes;
      },
    },
    actions: {
      async getRoutes({ commit }, request) {
        try {
          const response = await $api.postVroom(request);
          commit("setRoutes", response.data.routes);
        } catch (error) {
          console.log(error);
        }
      },
      async getDrivers({ commit }) {
        try {
          const response = await $api.getDrivers();
          commit("setDrivers", response.data.results);
        } catch (error) {
          console.log(error);
        }
      },
      async getGeoTabDrivers({ commit }) {
        try {
          const response = await $api.getGeotabDrivers();
          commit("setGeoTabDrivers", response.data);
        } catch (error) {
          console.log(error);
        }
      },
    },
  }
}
