<template>
  <div v-if="this.value" style="padding: 10px 5px; background-color: lightgray;">
    <div v-if="!loading">
      <div class="mr-2" style="display: flex; flex-direction: column;">

        <div style="display: flex; flex-direction: row; justify-content: space-between; width: 50%;">
          <div>
            <span style="font-weight: bold;">Shipped: </span>
            <span>
              {{ totalItemsShipped }}
            </span>
          </div>
          <div>
            <span style="font-weight: bold;">Ordered: </span>
            <span>
              {{ totalItemsOrdered }}
            </span>
          </div>
          <div>
            <span style="font-weight: bold;">LTS: </span>
            <span>
              {{ itemsLeftToShip }}
            </span>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
          <div style="display: flex; flex-direction: column; width: 100%;">
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
              <span class="mr-2"><span style="font-weight: bold;">Location: </span>{{ pickupLocationAddress }}</span>
              <PanToButton v-if="pickupLocation && pickupLocation.pointLatLng" :location="pickupLocation.pointLatLng" />
            </div>
            <div style="display: flex; flex-direction: row; padding-bottom: 10px;">
              <div v-if="pickupLocation !== null">
                <a @click="setPickupLocationHandlerType('update')">Update Location</a>
              </div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
              <span class="mr-2" style="opacity: 0.5;"><span style="font-weight: bold;">Original Syteline Address: </span>{{
    sytelineStreetAddress.complete }}</span>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div>
      <div v-if="loading">
        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
      </div>
      <div v-else>
        <div v-if="pickupLocation == null">
          <div>
            Found no pickup locations...
          </div>
          <a @click="setPickupLocationHandlerType('add')">Add Location</a>
        </div>

        <AddPickupLocation :create="false" @done="addPickupLocationHandler" v-if="pickupLocationHandlerType !== null" />
      </div>
    </div>
    <PickupLocation v-if="pickupLocation" :pickupLocation="pickupLocation" />
  </div>
</template>

<script>
import PanToButton from '@/components/common/PanToButton.vue';
import IconButton from '@/components/common/IconButton.vue';
import AddPickupLocation from "@/components/maps/AddPickupLocation";
import PickupLocation from '@/components/maps/PickupLocation'

export default {
  props: {
    value: {
      type: Object
    }
  },
  components: {
    PanToButton,
    IconButton,
    AddPickupLocation,
    PickupLocation
  },
  data() {
    return {
      loading: false,
      pickupLocation: null,
      updateLocation: false,
      pickupLocationHandlerType: null
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(val, oldVal) {
        await this.refreshPickupLocation();
      }
    }
  },
  computed: {
    customerNumber() {
      return this.value.customerNumber ? this.value.customerNumber : 'n/a';
    },
    itemsLeftToShip() {
      return this.value.orderStats?.itemsLeftToShip ? this.value.orderStats.itemsLeftToShip : '0'
    },
    totalItemsOrdered() {
      return this.value.orderStats?.totalItemsOrdered ? this.value.orderStats.totalItemsOrdered : '0'
    },
    totalItemsShipped() {
      return this.value.orderStats?.totalItemsShipped ? this.value.orderStats.totalItemsShipped : '0'
    },
    customerSequence() {
      return this.value.customerSequence ? this.value.customerSequence : 'n/a';
    },
    sytelineAddressWp() {
      if (!this.value.syteline) return null;

      const str = this.value.addr2;

      if (!str) return null;
      const lcStr = str.toLowerCase();

      if (lcStr.includes("wp")) {
        const splitLcStr = lcStr.split(" ");
        const wp = splitLcStr[1];
        if (!isNaN(wp)) {
          return parseInt(wp);
        }
      }

      return 'No Way Point';
    },
    sytelineStreetAddress() {
      // if (!this.value.syteline) return null;

      const { addr3, city, state } = this.value.shipTo;

      if (state && city && addr3) {
        return {
          complete: `${addr3}, ${city} ${state}`,
          parts: { street: addr3, city, state },
        };
      }

      if (addr3 && city) {
        return {
          complete: `${addr3}, ${city}`,
          parts: { street: addr3, city },
        };
      }

      if (addr3 && state) {
        return {
          complete: `${addr3}, ${state}`,
          parts: { street: addr3, state },
        };
      }

      return {
        complete: ''
      }
    },
    pickupLocationAddress() {
      if (!this.pickupLocation) return '';

      const { addStreet, addCity, addState, addPostalCode } = this.pickupLocation;
      const ret = `${addStreet.length > 0 ? `${addStreet},` : ''} ${addCity} ${addState} ${addPostalCode}`
      return this.pickupLocation ? ret : ''
    }

  },
  methods: {
    async refreshPickupLocation() {

      const { addr3, city, state } = this.value.shipTo;


      // if (city === null || state === null) return;

      let address = {
        complete: `${addr3 ? addr3 : ''}, ${city ? city : ''} ${state ? state : ''}`,
        parts: { street: addr3, city, state },
      };

      const { customerNumber, customerSequence, shipTo } = this.value;
      const { name } = shipTo;


      if (customerNumber && customerSequence) {
        this.loading = true
        const { data: pickupLocation } = await this.$api.getOrderPickupLocations({
          customerNumber,
          customerSequence,
          address: city == null || state == null ? null : address.complete,
          name
        })

        if (!pickupLocation.pointLatLng) {
          this.loading = false;
          return;
        };

        this.$emit('pickupLocationFound', pickupLocation);
        this.pickupLocation = pickupLocation;
        this.loading = false;
      }
    },
    async updatePickupLocation(point, rowPointer, customerNumber, customerSequence) {
      await this.$api.putOrderPickupLocations({ point, rowPointer, customerNumber, customerSequence })
    },
    async addPickupLocationHandler(event) {
      if (event === undefined) {
        this.pickupLocationHandlerType = null
      }

      if (this.pickupLocationHandlerType == 'update') {

        if (event.lat && event.lng) {
          await this.updatePickupLocation(event, this.pickupLocation.rowPointer);
          await this.refreshPickupLocation();
          this.pickupLocationHandlerType = null;
        }
      }

      if (this.pickupLocationHandlerType == 'add') {
        if (event.lat && event.lng) {
          await this.updatePickupLocation(event, null, this.customerNumber, this.customerSequence);
          await this.refreshPickupLocation();
          this.pickupLocationHandlerType = null;
        }
      }
    },
    setPickupLocationHandlerType(event) {
      this.pickupLocationHandlerType = event;
    }
  }
}
</script>

<style scoped></style>