<template>
  <div v-if="image">
    <slot name="button" :value="{ image, showFullscreenImage }">
      <image-button :image="image" 
        :tooltipPosition="tooltipPosition"
        @showFullscreenImage="showFullscreenImage" />
    </slot>
    <slot name="handler" :value="{ closeFullscreenImage }">
      <full-screen-image-modal
        v-if="fullscreenImage"
        :active="true"
        :image="fullscreenImage"
        @close="closeFullscreenImage"
      />
    </slot>
  </div>
</template>

<script>
import ImageButton from './ImageButton.vue';
import FullScreenImageModal from './FullScreenImageModal.vue';

export default {
  components: { ImageButton, FullScreenImageModal },
  props: {
    image: {
      type: Object,
      required: false,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: 'is-left',
    },
  },
  data() {
    return {
      fullscreenImage: null,
    };
  },
  methods: {
    showFullscreenImage(image) {
      this.fullscreenImage = image;
    },
    closeFullscreenImage() {
      this.fullscreenImage = null;
    },
  },
};
</script>

<style></style>
