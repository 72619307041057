// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import { createApp, reactive } from 'vue';

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
function useTelemetry({ router, env }) {
  if (instance) {
    return instance;
  }

  // if (!env.VUE_APP_SENTRY_DSN || env.VUE_APP_SENTRY_DSN.length === 0) {
  //   console.log('Telemetry Disabled');

  //   instance = new Vue({
  //     methods: {
  //       log(s) {
  //         console.log(s);
  //       },
  //       setUser(user) {},
  //     },
  //   });

  //   return instance;
  // }

  const tracesSampleRate = env.VUE_APP_SENTRY_TRACING_SAMPLE_RATE
    ? Number.parseFloat(env.VUE_APP_SENTRY_TRACING_SAMPLE_RATE)
    : 1.0;

  // const integrations = [];
  // if (tracesSampleRate > 0) {
  //   integrations.push(
  //     new BrowserTracing({
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //       tracingOrigins: [
  //         'localhost',
  //         'collections.revolutioncompany.com',
  //         'pc-webapp-dev.azurewebsites.net',
  //         'pc-webapp-staging.azurewebsites.net',
  //         'auth.deltaplastics.com',
  //         'deltaplastics.auth0.com',
  //         /^\//,
  //       ],
  //     }),
  //   );
  // }

  // const sentryOpts = {
  //   Vue,
  //   dsn: env.VUE_APP_SENTRY_DSN,
  //   env: env.VUE_APP_SENTRY_ENV,
  //   //release: "SPA",
  //   debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production,
  //   tracesSampleRate,
  //   integrations,
  // };

  // console.log('Sentry Init: ' + JSON.stringify(sentryOpts));
  // Sentry.init(sentryOpts);

  instance = {
    log(s) {
      // Sentry.breadCrumb({
      //    message: s
      // })
      console.log(s);
    },
    error(e) {
      console.error(e);

      if (this.$toast) {
        this.$toast.error(e);
      }
    },
    setUser(user) {
      // Sentry.setUser(user);
    }
  }

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export default {
  install(app, opts) {
    const instance = useTelemetry(opts)
    
    app.config.globalProperties.$telemetry = instance
    app.provide("$telemetry", instance)
  },
};
