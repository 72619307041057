<template>
   <pp4-label :fg="props.value.fg" :bg="props.value.bg" :labelText="labelText" :border="true" :latLng="value.location"
      :elementClass="elementClasses" @click="onClick" @mouseenter="onMouseover" @mouseleave="onMouseout" />
</template>

<script setup>
import { computed, reactive } from 'vue';

import Pp4Label from '@/components/maps/Label';
import { formatUnitValues } from '@/services/FormatUtil';
import { getFullNameAny } from '@/services/UserUtil';

const props = defineProps({
   value: {
      type: Object,
      required: true
   }
})

const data = reactive({
   mouseover: false
})

const $emit = defineEmits(["click"]);

const labelText = computed(() => {
   if (!data.mouseover) {
      return props.value.info ?
         "✓" : props.value.stopOrder?.toString();
   }

   let ret = "";

   if (props.value.info) {
      ret += "Completed " + new Date(props.value.info.createdOn).toLocaleString();
   }

   const pickupRequests = props.value?.pickupLocation?.pickupRequests
      || [];
   pickupRequests.forEach(o => {
      const material = formatUnitValues([
         { unit: o.unit, value: o.unitValue }
      ]);

      ret += material + " -- "
         + getFullNameAny(o.createdBy) + '<br>'
         + new Date(o.createdOn).toLocaleString()
   })

   if(! ret.length) {
      ret = "No Pickup Requests"
   }

   return ret;
})


const elementClasses = computed(() => {
   let ret = 'driver-stop-label';
   if (data.mouseover) {
      ret += ' driver-stop-mouseover';
   }

   return ret;
})

function onMouseover() {
   data.mouseover = true;
}
function onMouseout() {
   data.mouseover = false;
}

function onClick() {
   $emit("click", props.value);
}
</script>

<style>
.driver-stop-mouseover {
   font-style: italic;
   z-index: 1000 !important;
   opacity: 1.0 !important;
   width: 17rem;
}

.driver-stop-label {
   cursor: pointer;
   font-size: small;
   font-weight: bold;
   position: absolute;
   border: solid white 1px;
   position: absolute;
   transform: translateX(-50%) translateY(-50%) skewX(-10deg);
   ;
   padding: 0.35em;
   opacity: 0.8;
   text-align: center;
   z-index: 10;
}
</style>
