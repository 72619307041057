<template>
  <pp4-poly :path="pathLatLng" :clickable="false" strokeColor="hsla(230, 100%, 80%, 0.5)"
    fillColor="hsla(230, 100%, 80%, 0.3)" />
</template>

<script>
import Pp4Poly from '@/components/maps/Poly';
import { Bbox } from '../../services/GeoUtil';

export default {
  components: {
    Pp4Poly,
  },
  props: {
    bboxPath: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    pathLatLng: (vm) => Bbox.toLatLngPath(vm.bboxPath),
  },
  beforeUnmount() {
  },
  unmounted() {
  },
  emits: [],
  mounted() { },
  methods: {},
};
</script>

<style lang="css"></style>
