<template>
  <Pp4Marker :location="stop.info.pickupPoint" v-bind="markerOptions" />
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import Pp4Marker from '@/components/maps/Marker';

import TruckBoomMapMarker from '@/assets/svg/truck_boom_map_marker.svg';

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Marker,
  },
  props: {
    stop: {
      type: Object,
      required: true,
    },
  },
  computed: {
    markerOptions: (vm) => {
      const completed = vm.stop.info != null;

      const completedOn = completed ? new Date(vm.stop.info.modifiedOn).toLocaleString() : null;

      return {
        title: completed ? `Completed ${completedOn}` : 'Pending',
        icon: {
          url: TruckBoomMapMarker,
          scaledSize: new vm.api.Size(64, 128),
          //origin: {x: 16, y: 64},
          anchor: { x: 32, y: 96 },
          labelOrigin: { x: 32, y: -16 },
          size: new vm.api.Size(64, 128),
        },
        // label: {
        //    text: "01/01/1933",
        //    fontSize: "36px",
        //    //color: '#405465',
        //    color: 'white',
        //    className: 'completed-driver-stop-marker'
        // }
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="css">
.completed-driver-stop-marker {
  padding: 0.25em;
  position: relative;
  border-radius: 10px;
  max-width: 5em;
  background: rgba(0, 0, 0, 0.5);
  white-space: pre-wrap;
  cursor: pointer;
}
</style>
