<template>
  <div class="card" style="display: flex; flex-direction: column; margin: 10px;">
    <div style="display: flex; flex-direction: column;">
      <div class="card-header">
        <div class="content"
          style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 5px;">
          <div>
            <span class="labelStyle">Item: </span>{{ item }}
          </div>
          <div>
            <span class="labelStyle">Shipped: </span>{{ qtyShipped }}
          </div>
          <div>
            <span class="labelStyle">Ordered: </span>{{ qtyOrdered }}
          </div>
          <div>
            <span class="labelStyle">LTS: </span>{{ lts }}
          </div>
        </div>
      </div>
    </div>


    <div v-if="dropTasksSwr.isValidating">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
    </div>
    <div v-if="dropTasks.length > 0 && !dropTasks.isValidating" class="line-item-assignments">
      <div v-for="(item, index) in dropTasks" :key="item.rowPointer" class="line-item-assignment"
        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <div class="mr-5">
            <span class="labelStyle mr-1">Qty: </span> {{ item.amount ? item.amount : 'N/A' }}
          </div>
          <div style="" class="mr-5">
            <span class="labelStyle mr-1">Assigned to: </span>
            <User :value="item.driverStop?.route?.assignedToUser" />
          </div>
          <div v-if="item.info">
            <span class="labelStyle mr-1">Completed: </span> {{ new
              Date(item.info.createdOn).toLocaleString() }}
          </div>
        </div>

        <div v-if="!item.info">
          <icon-button type="is-warning" style="font-size: 8px;" icon="delete" @click="deleteDropTask(item)" />
        </div>

      </div>
      <div style="margin-top: 10px; display: flex; flex-direction: row;">
        <div class="mr-5">
          <span class="labelStyle">Qty. Routed: </span>{{ totalRouted }}
        </div>
        <div>
          <span class="labelStyle">Qty. Remaining: </span>{{ totalRemaing }}
        </div>
      </div>
    </div>
    <div v-if="selectedDriver"
      style="display: flex; flex-direction: row; justify-content: space-between; padding: 5px 5px 5px">
      <div style="display: flex; flex-direction: row;">
        <input :disabled="data.loading" type="number" min="0" style="width: 50px; margin-right: 10px;" placeholder="Qty"
          v-model="data.quantity">
        <b-button size="is-small" @click="addStopToDriverHandler">
          Some for {{ selectedDriver }}
        </b-button>
      </div>
      <div>
        <b-button size="is-small" @click="addAllStopToDriverHandler" :disabled="data.loading">
          All for {{ selectedDriver }}
        </b-button>
      </div>
    </div>
    <div v-else class="ml-1 mt-1" style="color:green; font-weight: 500; font-size: 11px;">
      Select location and driver to add as stop. {{ $store.state.selectedDriver }}
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, inject } from "vue";
import useSwr from 'swrv'

import IconButton from '@/components/common/IconButton.vue'
import User from '@/components/common/User.vue'
import { getFullNameAny } from '@/services/UserUtil'
import { useStore } from "vuex";


const props = defineProps({
  value: {
    type: Object,
    required: true
  },
  pickupLocationId: {
    type: Number,
    required: false
  }
})

const data = reactive({
  loading: false,
  quantity: 0
})

const $api = inject('$api')
const $store = useStore()

const dropTasksSwr = useSwr(`/dispatcher/ship/syteline-orders/${props.value.orderNumber}/drop-tasks`, $api.fetch, {
  revalidateOnFocus: false
})

const dropTasks = computed(() => {
  return dropTasksSwr.data?.value ?? []
})

const item = computed(() => {
  return props.value.item ?? 'N/A'
})

const qtyOrdered = computed(() => props.value.sytelineOrderItems.reduce(
  (accum, item) => accum + item.qtyOrdered, 0))

const qtyShipped = computed(() => props.value.sytelineOrderItems.reduce(
  (accum, item) => accum + item.qtyShipped, 0))

const lts = computed(() => {
  return qtyOrdered.value - qtyShipped.value
})

const selectedDriver = computed(() => {
  return $store.state.selectedDriver ? `${getFullNameAny($store.state.selectedDriver.user)}` : false
})

const totalRouted = computed(() => {
  return dropTasks.value.reduce((acc, curr) => {
    return acc + curr.amount;
  }, 0);
})

const totalRemaing = computed(() => {
  return props.value.qtyOrdered - totalRouted.value
})

async function addAllStopToDriverHandler() {
  const { orderNumber, lineNumber } = props.value
  const { item } = props.value

  try {
    data.loading = true

    const parsedRequestData = {
      orderNumber: orderNumber,
      lineNumber: parseInt(lineNumber),
      quantity: parseInt(props.value.qtyOrdered),
      pickupLocationId: props.pickupLocationId,
      item
    }


    await $store.dispatch('addDropRequestStopToDriver', parsedRequestData);
  }
  finally {
    data.loading = false
  }

  console.log("! TODO: REFRES?")
}

async function addStopToDriverHandler() {
  if (!props.value) {
    return;
  }
  if (data.quantity === 0) {
    return;
  }

  try {
    data.loading = true;

    const { orderNumber, lineNumber } = props.value
    const { item } = props.value

    const parsedRequestData = {
      orderNumber: orderNumber,
      lineNumber: parseInt(lineNumber),
      quantity: parseInt(data.quantity),
      pickupLocationId: props.pickupLocationId,
      item
    }

    await $store.dispatch('addDropRequestStopToDriver', parsedRequestData);
  }
  finally {
    data.loading = false;
  }

  console.log("! TODO: REFRES?2")
}

async function deleteDropTask(dropTask) {
  await $store.dispatch("deleteDropTask", {
    routeRowPointer: dropTask.driverStop.route.rowPointer,
    dropTaskRowPointer: dropTask.rowPointer,
  });
}
</script>

<style>
.labelStyle {
  font-weight: 600;
}

.line-item-assignments {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  padding: 0.5em;
}

.line-item-assignment:hover {
  background-color: aliceblue;
}
</style>