<template>
  <b-message type="is-warning" size="is-small" v-show="activeDriverStops.length">
    <div v-if="driversAndStopStatuses.length > 0" class="entries">
      On Active Route for
      <span v-for="o in driversAndStopStatuses" :key="o.key">
        <span>
          <user :value="o.user" />
          <span>
            {{ o.completed ? '(Complete)' : '' }}
          </span>
        </span>
      </span>
    </div>
  </b-message>
</template>

<script>
import User from '@/components/common/User.vue';

export default {
  props: {
    driverStops: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    activeDriverStops: (vm) => vm.driverStops.filter((s) => ! s.route.completedOn),
    driversAndStopStatuses: (vm) => {
      const driversById = new Set();
      const ret = [];

      vm.activeDriverStops.forEach((s) => {
        if (driversById.has(s?.route?.assignedToUser.userId)) {
          return;
        }

        const o = {
          completed: s.info ? true : false,
          user: s.route.assignedToUser,
        };

        ret.push(o);
      });

      return ret;
    },
  },
  components: { User },
  methods: {
    getDriverStopAndStatusKey(stop) {
      return `${stop.info != null}:${stop.route.assignedTo}`;
    },
  },
  mounted() {
  },
};
</script>

<style lang="css" scoped>
.entries {
  font-weight: bold;
  font-size: smaller;
  color: green;
}
</style>
