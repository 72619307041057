<template>
  <div :class="classes">
    <div class="card-content">
      <div class="content">
        <div style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          ">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            ">
            <span>Spotted
              <span class="is-uppercase has-text-weight-bold">
                {{ new Date(value.createdOn).toLocaleString() }}</span>
              by
              <User :value="value.createdBy" />
            </span>
            <span v-if="value.completedOn">
              Closed {{ new Date(value.completedOn).toLocaleString() }}
            </span>
            <span v-else>
              --Needs Pickup--
            </span>
            <Location :value="value"></Location>
            <span>Material: {{ formatUnitValues([{unit: value.unit, value: value.unitValue}]) }} ({{ formatMaterial(value)}})</span>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center">
            <images-carousel :images="value.images" tooltipPosition="is-right" />
            <PanToButton style="margin: 5px" v-if="value.pointLatLng" :location="value.pointLatLng" />
          </div>
        </div>
        <div style="display: flex; flex-direction: row">
          <div v-if="value.notes && value.notes.length > 0" class="subform" style="flex: 1; margin-right: 5px">
            <b>Notes</b>
            {{ value.notes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Location from "@/components/common/Location.vue";
import PanToButton from "@/components/common/PanToButton.vue";
import ImagesCarousel from "@/components/common/ImagesCarousel.vue";
import User from "@/components/common/User.vue";

import { formatUnitValues, formatMaterial} from '@/services/FormatUtil';

export default {
  props: {
    // a spotted point
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatUnitValues, formatMaterial
    };
  },
  computed: {
    classes: (vm) => {
      const ret = ['card', 'mt-1'];

      if(vm.value.inActiveFrame) {
        ret.push('highlighted');
      }
      else if(vm.value.completedOn) {
        ret.push('completed');
      }
      else {
        ret.push('not-completed')
      }

      return ret;
    }
  },
  components: { Location, PanToButton, ImagesCarousel, User },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.highlighted {
  background: $scheme-main-bis;
}

.completed {
  background: #77838f;
  color: #cccccc;
}

.not-completed {
  background: #77838f;
  color: white;
  font-weight: bold;
}
</style>
