<template>
  <div>
    <context-menu>
      <div class="container">
        <div style="padding: 5px">
          <div style="padding-bottom: 5px">
            <p class="is-uppercase" style="color: black; font-size: 12px">
              name: {{ selectedPickupLocation.name }}
            </p>
          </div>
          <p style="color: red; font-size: 12px">
            You're about to "archive" a pickup location! This will result in the
            pickup location and all it's associations (stops and points history)
            from no longer no longer being visible or accessible. All pending
            routes or rebates with this pickup location will NOT be affected by
            this action and will be allowed to be complete.
          </p>
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              padding-top: 20px;
            "
          >
            <b-button
              :expanded="true"
              icon-left="cancel"
              type="is-danger"
              style="margin-right: 5px"
              @click="cancel"
            />
            <b-button
              @click="archive"
              :expanded="true"
              class="is-warning"
              >Archive</b-button
            >
          </div>
        </div>
        <section>
          <b-loading
            :is-full-page="isFullPage"
            v-model="isLoading"
            :can-cancel="true"
          >
          </b-loading>
        </section>
      </div>
    </context-menu>
  </div>
</template>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject";
import ContextMenu from "@/components/maps/ContextMenu";

export default {
  extends: Pp4MapObject,
  components: {
    ContextMenu,
  },
  data: function () {
    return {
      isLoading: false,
      isFullPage: false,
    };
  },
  computed: {
    selectedPickupLocation: (vm) => vm.$store.state.selectedPickupLocation,
  },
  methods: {
    cancel() {
      this.$emit("done");
    },
    async archive() {
      this.isLoading = true;
      await this.$store.dispatch(
        "archivePickupLocation",
        this.selectedPickupLocation
      );
      this.isLoading = false;
      this.$emit("done");
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.75rem;
  width: 350px;
}
.selectedInfo {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  color: black;
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}
</style>
