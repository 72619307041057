export default {
  setLoading(state, {loading, loadingLabel}) {
    state.loading = loading;
    state.loadingLabel = loadingLabel
  },
  async points(state, payload) {
    state.points = payload;
  },
  async route(state, payload) {
    state.route = payload;
  },
  async paginatedRoutes(state, payload) {
    // knowing Vue, this is probably not sufficient...
    for (let key of Object.keys(payload)) {
      state.paginatedRoutes[key] = payload[key];
    }
  },
  async paginatedSpottedPoints(state, payload) {
    // knowing Vue, this is probably not sufficient...
    for (let key of Object.keys(payload)) {
      state.paginatedSpottedPoints[key] = payload[key];
    }
  },
  async paginatedPickupLocations(state, payload) {
    // knowing Vue, this is probably not sufficient...
    for (let key of Object.keys(payload)) {
      state.paginatedPickupLocations[key] = payload[key];
    }
  },
  async paginatedPickupRequests(state, payload) {
    // knowing Vue, this is probably not sufficient...
    for (let key of Object.keys(payload)) {
      state.paginatedPickupRequests[key] = payload[key];
    }
  },
  updatePaginatedDriverStopReviews(state, payload) {
    for (let key of Object.keys(payload)) {
      state.paginatedDriverStopReviews[key] = payload[key];
    }
  },
  updateDetailedDriverStopReview(state, { index, payload }) {
    // if there is a driver stop review matching that index
    if (index in state.paginatedDriverStopReviews.data) {
      // for each of the keys in the payload, set the values
      for (let key of Object.keys(payload)) {
        state.paginatedDriverStopReviews.data[index][key] = payload[key];
      }
    }
  },
  updatePaginatedSpottedPoint(state, spottedPoint) {
    const index = state.paginatedSpottedPoints.data.findIndex((p) => p.id === spottedPoint.id);

    state.paginatedSpottedPoints.data.splice(index, 1, spottedPoint);
  },
  updatePickupLocation(state, value) {
    const index = state.paginatedPickupLocations.data.findIndex((o) => o.id === value.id);
    if (index < 0) {
      state.paginatedPickupLocations.data.push(value);
    } else state.paginatedPickupLocations.data.splice(index, 1, value);
  },
  async pushWarehouseToWorkingRoute(state, warehouse) {
    const dummyStop = {
      warehouseId: warehouse.id, //this is what we care about when actually creating the stop
      warehouse,
    };

    state.workingRoute.stops.push(dummyStop);
  },
  async pushPickupLocationToWorkingRoute(state, pickupLocation) {
    const dummyStop = {
      pickupLocationId: pickupLocation.id,
      pickupLocation,
    };

    state.workingRoute.stops.push(dummyStop);
  },
  constants(state, payload) {
    state.plasticTypeGroups = payload.plasticTypeGroups;
    state.plasticTypes = payload.plasticTypes;
    state.units = payload.units
  },
  async warehouses(state, payload) {
    state.warehouses = payload;
  },
  async addToRoute(state, payload) {
    state.route.push(payload);
  },
  async selectedPoint(state, payload) {
    state.selectedPoint = payload;
  },
  async selectedPoints(state, payload) {
    state.selectedPoints = payload;
  },
  async query(state, payload) {
    state.query = payload;
  },
  setSelectedDriver(state, driver) {
    state.selectedDriver = driver;
  },
  selectedDriverInPlannedMode(state, val) {
    state.selectedDriverInPlannedMode = val
  },
  setSelectedPickupLocation(state, val) {
    state.selectedPickupLocation = val;
  },
  setDrivers(state, payload) {
    state.drivers = {...state.drivers, ...payload}
  },
  setUsers(state, payload) {
    state.users = {...state.users, ...payload};
  },
  async updateDriver(state, driver) {
    const drivers = state.drivers.data;
    const index = drivers.findIndex((o) => o.id === driver.id);
    if (index >= 0) {
      drivers.splice(index, 1, driver);
    }
  },
  async geotabDrivers(state, payload) {
    state.geotabDrivers = payload;
  },
  async loader(state, payload) {
    state.loader = payload;
  },
  async filter(state, payload) {
    state.filter = payload;
  },
  async routeMade(state) {
    state.routeMade = !state.routeMade;
  },
  async stopsChanged(state, payload) {
    const route = state.routes.find((route) => route.id === payload[0].routeId);
    route.stops = payload;
  },
  async updateRoute(state, payload) {
    // the route can be in paginated routes
    {
      const routeIndex = state.paginatedRoutes.data.findIndex((route) => route.id === payload.id);
      if (routeIndex >= 0) {
        state.paginatedRoutes.data.splice(routeIndex, 1, payload);
      }
    }

    // or in the drivers
    const drivers = state.drivers.data;
    const driverIndex = drivers.findIndex((o) => o.id === payload.assignedTo);
    if (driverIndex >= 0) {
      const driver = state.drivers.data[driverIndex];

      const activeRoute = driver.activeRoute;
      if (activeRoute.id === payload.id) {
        const newDriver = Object.assign({}, driver);
        newDriver.activeRoute = payload;

        drivers.splice(driverIndex, 1, newDriver);
      }

      if (state.selectedDriver === driver) {
        state.selectedDriver = newDriver;
      }
    }
  },
  async crosshairCursor(state, payload) {
    state.crosshairCursor = payload;
  },
  async curPos(state, payload) {
    state.curPos = payload;
  },
  toggleLayer(state, layerKey) {
    state.layers[layerKey] = !state.layers[layerKey];
  },
  mapActivityActive(state) {
    state.mapActivityActive = true;
  },
  mapActivityDone(state) {
    state.mapActivityActive = false;
  }
};
