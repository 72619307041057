<template>
  <pp4-label :fg="props.driver.colorScheme.fg.toHexString()" :bg="props.driver.colorScheme.bg.toHexString()"
    :labelHtml="labelHtml" :border="true" :latLng="location" elementClass="driver-map-object" />
</template>

<script setup>
import { computed } from "vue";

import Pp4Label from '@/components/maps/Label';
import { getFullNameAny } from "../../services/UserUtil";

const props = defineProps({
  geotabDriver: {
    type: Object,
    required: true,
  },
  driver: {
    type: Object,
    required: false,
  },
})

const device = computed(() => props.geotabDriver?.device || null)

const location = computed(() => {
  if (!device.value) {
    return null;
  }

  return {
    lat: device.value.latitude,
    lng: device.value.longitude,
  };
})

const compassDiv = computed(() => {
  const speed = Math.floor((device?.value?.speed || 0) * 0.6213712); // kph to mph
  const bearing = device?.value?.bearing || 0;

  const nw = "↖️";
  const ne = "↗️";
  const sw = "↙️";
  const n = "⬆️";
  const s = "⬇️";
  const e = "➡️";
  const w = "⬅️";
  const se = "↘️";
  const idle = "💤";

  // east side of compass
  let icon = n;
  if (speed > 0 && bearing >= 0) {
    if (bearing < 22.5) {
      icon = n;
    } else if (bearing < 67.5) {
      icon = ne;
    } else if (bearing < 112.5) {
      icon = e;
    } else if (bearing < 157.5) {
      icon = se;
    } else if (bearing < 202.5) {
      icon = s;
    } else if (bearing < 247.5) {
      icon = sw;
    } else if (bearing < 302.5) {
      icon = w;
    } else if (bearing < 347.5) {
      icon = nw;
    } else {
      icon = n;
    }
  } else {
    icon = idle;
  }

  const ret = document.createElement("div");
  ret.style = "display: block; text-align: center;";
  ret.innerHTML = `${icon} ${speed}mph`;
  return ret;
})

const labelHtml = computed(() => {
  let label = props.driver?.user ? getFullNameAny(props.driver.user) : "N/A";

  const labelDiv = document.createElement("div");
  labelDiv.style = "text-align: center;";
  labelDiv.innerText = label

  const ret = document.createElement("div");
  ret.style = "display: flex; flex-direction: column; align-items: center;"

  ret.append(labelDiv)
  ret.appendChild(compassDiv.value)

  if (props.driver.collected) {
    const collectedDiv = document.createElement("div");
    collectedDiv.innerText = `${props.driver.collected}`;
    ret.appendChild(collectedDiv)
  }

  return ret.outerHTML;
})
</script>

<style lang="css">
.driver-map-object {
  position: absolute;
  cursor: pointer;
  font-weight: bold;
  width: 6rem;
  font-size: larger;
  transform: translateX(-50%) translateY(-50%);
  padding: 0.5em;
  border-radius: 0.5em;
  opacity: 0.85;
}
</style>
