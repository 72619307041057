<template>
  <pp4-label v-if="visibleAndViewable" :latLng="latLng" :labelText="labelText" :elementClass="elementClasses"
    @click="emitClick" @dblclick="emitDblclick" />

  <pp4-poly v-if="visibleAndViewable" :path="polyPath"
    :strokeColor="polyColor" :fillColor="polyColor" @click="emitClick" @dblclick="emitDblclick" />

  <template v-if="selected">
    <CompletedDriverStop v-for="stop in completedDriverStops"
      :key="`PickupLocationMapObject:${pickupLocation.rowPointer}:${stop.rowPointer}`" :stop="stop" />

    <SpottedPoint v-for="point in pickupRequests"
      :key="`PickupLocationMapObject:${pickupLocation.rowPointer}:${point.rowPointer}`" :spottedPoint="point" />
  </template>
</template>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject";
import Pp4Poly from "@/components/maps/Poly";
import Pp4Label from "@/components/maps/Label";
import CompletedDriverStop from "@/components/maps/CompletedDriverStop";
import SpottedPoint from "@/components/maps/SpottedPoint";
import { GeoJson } from "@/services/GeoUtil";
import { getFullNameAny } from '@/services/UserUtil';

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Label,
    Pp4Poly,
    CompletedDriverStop,
    SpottedPoint,
  },
  props: {
    pickupLocation: {
      type: Object,
      required: true,
    },
    simple: {
      type: Boolean,
      required: false,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    alwaysVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    priority: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      
    };
  },
  watch: {
  },
  computed: {
    driverStops: vm => vm.pickupLocation?.driverStops || [],
    completedDriverStops: vm => vm.driverStops.filter(s => s.info),
    pickupRequests: vm => vm.pickupLocation?.pickupRequests || [],
    driverStopsVisible: vm => vm.selected || vm.$store.state.layers.driverStops,
    pickupRequestsVisible: vm => vm.selected || vm.$store.state.layers.pickupRequests,
    showRelatedObjects: (vm) => {
      return vm.selected;
    },
    modifiedOnDate: (vm) => new Date(vm.pickupLocation.modifiedOn),
    polyColor: (vm) => {
      return vm.simple ? "#62808E" : "#1d9ad6";
    },
    labelText: (vm) => {
      let ret = vm.pickupLocation.name;

      if (vm.pickupLocation.owner) {
        ret += '<br>' + getFullNameAny(vm.pickupLocation.owner);
      }
      else {
        ret += "<br><i>Unowned</i>"
      }

      return ret;
    },
    latLng: (vm) => {
      const geo = vm.pickupLocation.geoJson;

      if (GeoJson.isPoint(geo)) {
        return GeoJson.toLatLngs(geo);
      } else if (GeoJson.isPolygon(geo)) {
        const centerPoint = GeoJson.center(geo);
        return GeoJson.toLatLngs(centerPoint);
      }

      return null;
    },
    polyPath: (vm) => {
      const startingGeo = vm.pickupLocation.geoJson;

      if (GeoJson.isPoint(startingGeo)) {
        const geo = GeoJson.circle(startingGeo, 100, {
          units: "meters",
          steps: 16,
        });

        return GeoJson.toLatLngs(geo);
      } else if (GeoJson.isPolygon(startingGeo)) {
        return GeoJson.toLatLngs(startingGeo);
      } else if (GeoJson.to) return null;
    },
    visibleAndViewable: (vm) => {
      if (vm.alwaysVisible) {
        return true;
      }

      return vm.visible;
    },
    elementClasses: () => {
      return "pickup-location-label";
    },
  },
  mounted() {
  },
  emits: ['click', 'dblclick'],
  methods: {
    emitClick() {
      this.$emit("click", this.pickupLocation);
    },
    emitDblclick() {
      this.$emit("dblclick", this.pickupLocation);
    },
    mapReady() { },
  },
};
</script>

<style lang="css">
.pickup-location-label {
  font-size: medium;
  position: absolute;
  cursor: pointer;
  padding: 0.35rem;
  border-radius: 0.5rem;
  text-align: center;
  border: 5px solid transparent;
  width: 10em;
  background-color: rgba(98, 128, 142, 0.6);
  transform: translateX(-50%) translateY(-50%);
}
</style>
