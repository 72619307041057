import { mutate as lameMutate } from 'swrv'



export function createActions($api) {
  function mutate(key) {
    lameMutate(key, $api.fetch(key))
  }

  return {
    setPlannedRoute({ commit }, value) {
      commit('plannedRoute', value)
    },
    async updatePrimaryImage({ dispatch }, model) {
      try {
        await $api.updatePrimaryImage(model);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async queryPoints({ commit, dispatch }, model) {
      try {
        const { data } = await $api.points(model);
        const { points } = data;
        commit("points", points);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async getDrivers({ commit, dispatch }) {
      try {
        commit("setDrivers", { loading: true });

        const response = await $api.getDrivers();

        commit("setDrivers", { data: response.data.results, loading: false });
      } catch (error) {
        dispatch("error", error);
        commit("setDrivers", { data: [], loading: false });
      }
    },
    async postPickupRequest({ }, { pickupLocationRowPointer, pickupRequest }) {
      await $api.postPickupRequest(pickupLocationRowPointer, pickupRequest);
    },
    async postCredit({ }, credit) {
      await $api.postCredit(credit);
    },
    async deleteCredit({ }, id) {
      await $api.deleteCredit(id);
    },

    async postWarehouseStop({ dispatch }, {userId, warehouseId, routeRowPointer, plannedRowPointer}) {
      try {
        if (warehouseId) await $api.postWarehouseStop(userId, warehouseId);
        else await $api.postWarehouseStop(userId);
        if(routeRowPointer) mutate(`/dispatcher/routes/${routeRowPointer}`)
        if(plannedRowPointer) mutate(`/dispatcher/routes/${plannedRowPointer}`)
        
      } catch (error) {
        dispatch("error", error);
      }

      await dispatch("getDrivers");
    },
    async getUsers({ commit, dispatch }) {
      try {
        commit("setUsers", {
          loading: true,
        });

        const response = await $api.getUsers();

        commit("setUsers", {
          data: response.data,
          loading: false,
        });
      } catch (error) {
        dispatch("error", error);
      }
    },
    async getGeotabDrivers({ commit, dispatch }) {
      try {
        const { data } = await $api.getGeotabDrivers();
        commit("geotabDrivers", data);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async pushWarehouseToWorkingRoute({ commit }, warehouse) {
      commit("pushWarehouseToWorkingRoute", warehouse);
    },
    async addPickupLocationForSelectedDriver(
      { state, commit, dispatch },
      pickupLocationRowPointer
    ) {

      const driver = state.selectedDriver;
      if (!driver) {
        return;
      }

      const targetRoute = state.selectedDriverInPlannedMode ? 
        driver.plannedRoute : driver.activeRoute;
      if (!targetRoute) {
        return;
      }

      try {
        const { data } = await $api.postRouteStop(targetRoute.rowPointer, {
          pickupLocationId: pickupLocationRowPointer,
        });

        commit("updateRoute", data);

        mutate(`/dispatcher/routes/${targetRoute.rowPointer}`)
      } catch (error) {
        dispatch("error", error);
      }
    },
    async addDropRequestStopToDriver(
      { state, dispatch },
      { orderNumber, lineNumber, quantity, item, pickupLocationId }
    ) {
      const driver = state.selectedDriver;

      if (!driver) return;

      const targetRoute = state.selectedDriverInPlannedMode ? 
        driver.plannedRoute : driver.activeRoute;
      if (!targetRoute) {
        return;
      }

      try {
        await $api.postRouteStop(targetRoute.rowPointer, {
          orderNumber,
          lineNumber,
          quantity,
          item,
          pickupLocationId,
        });

        dispatch("getDrivers");

        mutate(`/dispatcher/routes/${targetRoute.rowPointer}`)
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updatePaginatedRoutesOptions({ commit }, opts) {
      delete opts.data; // just in case

      commit("paginatedRoutes", opts);
    },
    async updatePaginatedRoutes({ commit, state, dispatch }) {
      try {
        const params = {};
        params.page = state.paginatedRoutes.page;
        params.perPage = state.paginatedRoutes.perPage;
        params.assignedTo = state.paginatedRoutes.assignedTo;
        params.completed = true;
        params.scheduledOnBegin = state.paginatedRoutes.scheduledOnBegin
          ? state.paginatedRoutes.scheduledOnBegin.toString()
          : null;
        params.scheduledOnEnd = state.paginatedRoutes.scheduledOnBegin
          ? state.paginatedRoutes.scheduledOnEnd.toString()
          : null;

        commit("paginatedRoutes", { loading: true });

        const serviceResult = await $api.routes(params);

        const { routes, count } = serviceResult.data;
        if (routes) {
          commit("paginatedRoutes", {
            data: routes,
            total: count,
            loading: false,
          });
        } else {
          commit("paginatedRoutes", { data: [], total: 0, loading: false });
        }
      } catch (error) {
        dispatch("error", error);
      }
    },
    async deleteRoutes({ dispatch }, models) {
      try {
        for (let model of models) {
          await $api.deleteRoute(model);
        }

        dispatch("updatePaginatedRoutes");
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updatePaginatedSpottedPointsOptions({ commit }, opts) {
      delete opts.data; // just in case

      commit("paginatedSpottedPoints", opts);
    },
    async updatePaginatedSpottedPoints({ commit, state, dispatch }) {
      try {
        const params = {};
        const psp = state.paginatedSpottedPoints;
        params.page = psp.page;
        params.perPage = psp.perPage;
        if (psp.state) {
          params.state = psp.state;
        }
        if (psp.statuses.length > 0) {
          params.statuses = psp.statuses;
        }
        if (psp.createdOnBegin) {
          params.createdOnBegin = psp.createdOnBegin;
        }
        if (psp.createdOnEnd) {
          params.createdOnEnd = psp.createdOnEnd;
        }

        commit("paginatedSpottedPoints", { loading: true });

        const serviceResult = await $api.points(params);

        const { results, counties, count } = serviceResult.data;

        commit("paginatedSpottedPoints", {
          data: results,
          counties,
          total: count,
          loading: false,
        });
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updatePaginatedDriverStopReviewsOptions({ commit }, opts) {
      delete opts.data; // just in case

      commit("updatePaginatedDriverStopReviews", opts);
    },
    async updatePaginatedDriverStopReviews({ state, commit, dispatch }) {
      try {
        const params = {};
        const pdsr = state.paginatedDriverStopReviews;
        params.page = pdsr.page;
        params.perPage = pdsr.perPage;

        Object.assign(params, pdsr.filters);

        commit("updatePaginatedDriverStopReviews", { loading: true });

        const serviceResult = await $api.getDriverStopReviews(params);

        const { results, count } = serviceResult.data;

        commit("updatePaginatedDriverStopReviews", {
          data: results,
          total: count,
          loading: false,
        });
      } catch (error) {
        commit("updatePaginatedDriverStopReviews", { data: [], loading: false });
        dispatch("error", error);
      }
    },
    async putUserMaxRebateAmount({ }, { userId, maxAmount }) {
      await $api.putUserMaxRebateAmount(userId, { maxAmount });
    },
    async deleteUserMaxRebateAmount({ }, userId) {
      await $api.deleteUserMaxRebateAmount(userId);
    },
    async putDriverStopReview({ }, review) {
      await $api.putDriverStopReview(
        review.driverStopRowPointer,
        review.items
      );
    },
    async updatePaginatedPickupLocationsOptions({ commit }, opts) {
      delete opts.data; // just in case

      commit("paginatedPickupLocations", opts);
    },
    async updatePaginatedPickupLocations({ commit, state, dispatch }) {
      try {
        const params = {};
        const ppl = state.paginatedPickupLocations;

        params.page = ppl.page;
        params.perPage = ppl.perPage;

        Object.assign(params, ppl.filters);

        commit("paginatedPickupLocations", { loading: true });

        const serviceResult = await $api.getPickupLocations(params);

        const { results, count } = serviceResult.data;

        commit("paginatedPickupLocations", {
          data: results,
          total: count,
          loading: false,
        });
      } catch (error) {
        dispatch("error", error);

        commit("paginatedPickupLocations", {
          loading: false,
        });
      }
    },
    async getPickupLocation({ commit }, value) {
      const serviceResult = await $api.getPickupLocation(value);

      await commit("updatePickupLocation", serviceResult.data);
    },
    async updatePaginatedPickupRequestsOptions({ commit }, opts) {
      delete opts.data; // just in case

      commit("paginatedPickupRequests", opts);
    },
    async updatePaginatedPickupRequests({ commit, state, dispatch }) {
      try {
        const params = {};
        const ppr = state.paginatedPickupRequests;

        params.page = ppr.page;
        params.perPage = ppr.perPage;

        Object.assign(params, ppr.filters);

        commit("paginatedPickupRequests", { loading: true });

        const serviceResult = await $api.getPickupRequests(params);

        const { results, count, summary } = serviceResult.data;

        commit("paginatedPickupRequests", {
          data: results,
          total: count,
          loading: false,
          resourceSummary: summary,
        });
      } catch (error) {
        dispatch("error", error);

        commit("paginatedPickupRequests", {
          loading: false,
        });
      }
    },
    async closeOutPickupRequest({ dispatch }, value) {
      try {
        await $api.putPickupRequest(value.rowPointer, {
          completedOn: new Date(),
          completionCode: 'X'
        });
        dispatch("updatePaginatedPickupRequests");
      } catch (error) {
        dispatch("error", error);
      }
    },
    async getPickupLocation({ commit }, value) {
      const serviceResult = await $api.getPickupLocation(value);

      await commit("updatePickupLocation", serviceResult.data);
    },
    async postPickupLocation({ dispatch }, value) {
      await $api.postPickupLocation(value);

      await dispatch("updatePaginatedPickupLocations");
    },
    async mergePickupLocations({ dispatch }, value) {
      await $api.mergePickupLocations(value);
      await dispatch("updatePaginatedPickupLocations");
    },
    async archivePickupLocation({ dispatch }, value) {
      await $api.archivePickupLocation(value);
      await dispatch("updatePaginatedPickupLocations");
    },
    async putPickupLocation({ commit }, value) {
      const serviceResult = await $api.putPickupLocation(value);

      commit("updatePickupLocation", serviceResult.data);
    },
    async deletePickupLocation({ dispatch }, value) {
      try {
        await $api.deletePickupLocation(value);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async getWarehouses({ commit, dispatch }) {
      try {
        const serviceResult = await $api.getWarehouses();

        const warehouses = serviceResult.data;

        commit("warehouses", warehouses);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updatePoint({ commit, dispatch }, model) {
      try {
        const { data } = await $api.updatePoint(model);
        const { point } = data;

        commit("updatePaginatedSpottedPoint", point);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async deleteRouteStop(
      { state, commit, dispatch },
      { routeRowPointer, stopRowPointer }
    ) {
      try {
        commit("setDrivers", { ...state.drivers, loading: true });

        await $api.deleteRouteStop(
          routeRowPointer,
          stopRowPointer
        );

        mutate(`/dispatcher/routes/${routeRowPointer}`)
      } catch (error) {
        dispatch("error", error);
      } finally {
        commit("setDrivers", { ...state.drivers, loading: false });
      }
    },
    async deleteRouteStops({ state, commit, dispatch }, { rowPointer }) {
      try {
        commit("setDrivers", { ...state.drivers, loading: true });

        await $api.deleteRouteStops(rowPointer);

        mutate(`/dispatcher/routes/${rowPointer}`)
      } catch (error) {
        dispatch("error", error);
      } finally {
        commit("setDrivers", { ...state.drivers, loading: false });
      }
    },
    async deleteDropTask({ state, commit, dispatch }, {routeRowPointer, dropTaskRowPointer}) {
      try {
        commit("setDrivers", { ...state.drivers, loading: true });

        await $api.deleteDropTask(
          dropTaskRowPointer
        );

        mutate(`/dispatcher/routes/${routeRowPointer}`)
      } catch (error) {
        dispatch("error", error);
      } finally {
        commit("setDrivers", { ...state.drivers, loading: false });
      }
    },
    async updateRouteStopOrder(
      { state, commit, dispatch },
      { rowPointer, updatedStopsSimplified }
    ) {
      try {
        commit("setDrivers", { ...state.drivers, loading: true });

        const { data } = await $api.routeStopOrderChanged(
          rowPointer,
          updatedStopsSimplified
        );

        commit("updateRoute", data);

        mutate(`/dispatcher/routes/${rowPointer}`)
      } catch (error) {
        dispatch("error", error);
      } finally {
        commit("setDrivers", { ...state.drivers, loading: false });
      }
    },
    async updateRoute({ commit }, { id, model }) {
      try {
        const { data } = await $api.updateRoute(id, model);

        commit("updateRoute", data);

        mutate(`/dispatcher/routes/${id}`)
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updateRouteNotes({ commit }, { id, notes }) {
      try {
        const { data } = await $api.updateRouteNotes(id, { notes });

        commit("updateRoute", data);

        mutate(`/dispatcher/routes/${id}`)
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updateDriver({ commit, dispatch }, driver) {
      console.log("! update driver")
      try {
        const payload = {
          id: driver.id,
          geotabId: driver.geotabId,
          following: driver.following,
          role: driver.role
        };

        const serviceResult = await $api.putDriver(payload);
        commit("updateDriver", serviceResult.data);
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updateDriverStopInfoNotes(_, { id, notes }) {
      try {
        await $api.putDriverStopInfo(id, { notes });
      } catch (error) {
        dispatch("error", error);
      }
    },
    async updateRouteDirections({ state, commit }, { id }) {
      try {
        commit("setDrivers", { ...state.drivers, loading: true });
        const { data } = await $api.updateRouteDirections(id);

        commit("updateRoute", data);
      } catch (error) {
        dispatch("error", error);
      } finally {
        commit("setDrivers", { ...state.drivers, loading: false });
      }
    },
    async setSelectedDriver({ commit }, id) {
      try {
        const response = await $api.getDriver(id);
        //commit("updateDriver", response.data);
        commit("setSelectedDriver", response.data);
      } catch (error) {
        console.log(`Whoops, ${error}`);
      }
    },
    selectedDriverInPlannedMode({commit}, val) {
      commit("selectedDriverInPlannedMode", val);
    },
    async getConstants({ commit, dispatch }) {
      try {
        const newConstants = await $api.getConstants();

        const data = newConstants.data;
        commit("constants", data);
      } catch (e) {
        dispatch("error", e);
      }
    },
    async dumpAndClearDriverRoutes({ commit, dispatch }, drivers) {
      try {
        commit("setLoading", {
          loading: true,
          loadingLabel: "Clearing Driver Routes...",
        });

        let index = 1;
        for (let d of drivers) {
          commit("setLoading", {
            loading: true,
            loadingLabel: `Clearing Driver Route  ${index}/${drivers.length}`,
          });

          if (d.activeRoute.stops.some((s) => s.info)) {
            await $api.postWarehouseStop(d.activeRoute.assignedTo);
          }

          await $api.deleteRouteStops(d.activeRoute.rowPointer);

          index += 1;
        }
      } catch (e) {
        alert("Error Clearing Routes");
        telemetry.error(e); 
      } finally {
        commit("setLoading", { loading: false });

        dispatch("getDrivers");
      } 
    },
    async createPlannedRoute({state, commit, dispatch}, driverId) {
      try {
        commit("setLoading", {
          loading: true,
          loadingLabel: "Creating Planned Route...",
        });

        await $api.getPlannedRoute(driverId);

        if(driverId === state.selectedDriver?.id) {
          dispatch('setSelectedDriver', driverId);
        }
      } catch (e) {
        dispatch("error", e)
      } finally {
        commit("setLoading", { loading: false });

        dispatch("getDrivers");
      } 
    },
    async postNotification({ }, notification) {
      await $api.postNotification(notification);
    },
    async postVroomForWarehouse({ dispatch }, warehouseId) {
      try {
        await $api.postVroomForWarehouse(warehouseId);
      } catch (e) {
        dispatch("error", e);
      }
    },
    setSelectedPickupLocation({ commit }, val) {
      commit("setSelectedPickupLocation", val);
    },
    mapActivityActive({ commit }) {
      commit("mapActivityActive");
    },
    mapActivityDone({ commit }) {
      commit("mapActivityDone");
    },
    toggleLayer({ commit }, layerKey) {
      commit("toggleLayer", layerKey);
    },
    error({ }, e) {
      console.error(e);
    },
  }
}