<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <sidebar ref="profileSidebar" />
    <div style="width: 50%; margin-top: 3em">
      <b-message type="is-warning" has-icon>
        You do not have permission to access this resource. Please contact your admin for access.
      </b-message>
      <div style="display: flex; justify-content: center">
        <profile-button @click="$refs.profileSidebar.onOpen()" />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileButton from '../components/maps/ProfileButton.vue';
import Sidebar from '@/components/common/Sidebar.vue';

export default {
  components: { ProfileButton, Sidebar },
};
</script>

<style></style>
