<template>
  <div class="driver_stops_list">
    <active-route-info :driverStops="stops" />

    <div v-if="stops && stops.length > 0">
      <driver-stop-card
        v-for="stop in stops"
        :key="`${myKey}:stopcard:${stop.rowPointer}`"
        :value="stop"
      />
    </div>
    <div v-else>
      None
    </div>

    <div v-if="showOnMap">
      <CompletedDriverStop v-for="s in completedStops" :key="`${myKey}:${s.rowPointer}`" :stop="s" />
    </div>
  </div>
</template>

<script>
import DriverStopCard from "./cards/DriverStopCard.vue";
import ActiveRouteInfo from "./ActiveRouteInfo.vue";
import CompletedDriverStop from "@/components/maps/CompletedDriverStop";

export default {
  components: { ActiveRouteInfo, DriverStopCard, CompletedDriverStop },
  props: {
    showOnMap: {
      type: Boolean,
      required: false,
      default: false,
    },
    stops: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      myKey: new Date().getTime().toString(),
    };
  },
  computed: {
    completedStops: (vm) => vm.stops.filter((s) => (s.info ? true : false)),
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="css" scoped>
.driver_stops_list {
  position: relative;
}

.loading {
  display: block;
  height: 2em;
  width: 100%;
}

.delete-location {
  background-color: grey;
  border-radius: 5px;
  padding: 4px;
}

.error {
  text-align: center;
  color: red;
  font-weight: bold;
}
</style>
