<template>
  <b-modal v-model="isActive">
    <div style="display: flex; justify-content: center" @click="clicked">
      <!-- Height is always a bit too big. Don't need the whole view anyway -->
      <authorized-image
        :imgStyles="{ maxHeight: `${getWinHeight * 0.9}px`, maxWidth: `${getWinWidth}px` }"
        class="modal-image"
        v-if="image"
        :image="image"
      />
    </div>
    <b-button size="is-small" v-if="action" @click="buttonClicked">{{ actionTitle }}</b-button>
  </b-modal>
</template>

<script>
import AuthorizedImage from "./AuthorizedImage.vue";

export default {
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: Object,
      required: true,
    },
    action: {
      type: Boolean,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
      default: "Press",
    },
  },
  computed: {
    isActive: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("close", value);
      },
    },
    getWinHeight() {
      return window.innerHeight;
    },
    getWinWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    clicked() {
      this.$emit("close");
    },
    buttonClicked() {
      this.$emit("buttonClicked", this.image);
    },
  },
  components: { AuthorizedImage },
};
</script>

<style>
.modal-image {
  object-fit: contain;
}
</style>
