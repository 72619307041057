<template>
  <div :class="`card ${cardClasses}`" :style="{cursor: cursorStyle, marginBottom: marginStyle}">
    <div class="card-header" v-if="stopCompleted">
      <div class="pt-1 pb-1" style="display: flex; flex-direction: row; flex: 1; justify-content: flex-end;">
        <div>
          <span class="is-size-7"> Picked up {{ pickedUpMaterial }} on {{ completedOn }} </span>
          <i class="mdi mdi-check-circle mr-1" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; flex: 1; padding: 10px">
      <div class="mt-1"
        style="display: flex; flex-direction: row; flex: 1; justify-content: space-between; align-items: center;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div>
              <span class="is-size-6 has-text-weight-bold mr-2">{{ stop.stopOrder }}</span>
            </div>
            <div>
              <span class="is-size-7 has-text-weight-medium">{{ pickupLocationAddress }}</span>
            </div>
            <div>
              <span class="is-size-7 has-text-weight-bold"> ({{ pickupLocationMaterial }})</span>
            </div>
          </div>
        </div>

        <div style="display: flex; flex-direction: column;">
          <div style="display: flex; flex-direction: row;">
            <PanToButton class="mr-2" :location="stop.location" />
            <icon-button v-if="!stopCompleted && !readonly" type="is-warning" icon="delete" @click="emitRemove" />
          </div>

        </div>
      </div>

      <images-carousel class="mb-2" :pickupLocationId="stop.pickupLocation.id" tooltipPosition="is-left" />

      <ToggleNotes v-if="pickupLocation.notes" :data="pickupLocation.notes" title="Location Notes"
        @saveEvent="pickupLocationNotesSaveHandler" :editable="true" class="mb-2" />

      <ToggleNotes v-if="stop?.info?.notes" :data="stop.info.notes" title="Driver Notes" :editable="false" class="mb-1" />
    </div>
  </div>
</template>

<script>
import PanToButton from "@/components/common/PanToButton";
import ToggleNotes from "@/components/common/ToggleNotes";
import User from "@/components/common/User";
import ImagesCarousel from "@/components/common/ImagesCarousel";
import * as FormatUtil from "@/services/FormatUtil";
import IconButton from "./IconButton.vue";

export default {
  name: "RouteStop",
  components: {
    ImagesCarousel,
    PanToButton,
    IconButton,
    ToggleNotes,
    User,
  },
  props: {
    stop: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() { },
  computed: {
    pickupLocationAddress: (vm) => {
      const pl = vm.pickupLocation;
      return `${pl.addStreet} ${pl.addCity}, ${pl.addState} ${pl.addPostalCode}`;
    },
    pickupLocationMaterial: (vm) => {
      const pickupRequests = vm.stop.pickupLocation?.pickupRequests;
      const dropRequest = vm.stop.dropRequest
      if (dropRequest) {
        let ret = "Qty: "
        if (dropRequest.amount) {
          ret += dropRequest.amount
        }

        if (dropRequest?.item?.sytelineItem) {
          ret += ` | Item: ${dropRequest.item.sytelineItem}`
        }

        return ret
      }

      if (!pickupRequests) {
        return "No Material";
      }


      const unitValues = pickupRequests.map((r) => {
        return { unit: r.unit, value: r.unitValue };
      });

      return FormatUtil.formatUnitValues(unitValues);
    },
    stopCompleted: (vm) => vm.stop.info != null,
    completedOn: (vm) => {
      const dateToUse = vm.stop.info.modifiedOn || vm.stop.info.createdOn;
      if (!dateToUse) {
        return "";
      }

      return new Date(dateToUse).toLocaleString(undefined, {
        dateStyle: "short",
        timeStyle: "short",
      });
    },
    pickedUpMaterial: (vm) => {
      const i = vm.stop.info;
      const value =
        i.weight0 +
        i.weight1 +
        i.weight2 +
        i.weight3 +
        i.weight4 +
        i.weight5 +
        i.weight6 +
        i.weight7;
      const unit = i.unit;

      return FormatUtil.formatUnitValues([{ unit, value }]);
    },
    pickupLocation: (vm) => vm.stop.pickupLocation,
    warehouse: (vm) => vm.stop.warehouse,
    cardClasses: (vm) => (vm.stopCompleted ? "success" : ""),
    cursorStyle() {
      return this.readonly ? 'default' : 'grab';
    },
    marginStyle(){
      return this.readonly ? '.8em' : null;
    }
  },
  methods: {
    emitRemove() {
      this.$emit("remove");
    },
    pickupLocationNotesSaveHandler(event) {
      this.$store.dispatch("putPickupLocation", {
        rowPointer: this.stop.pickupLocation.rowPointer,
        notes: event,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.success {
  color: var(--successDark);
  background: var(--successLight);
}
</style>
