<template>
  <!-- <div>moo</div> -->
  <remove-points v-if="editMode == GeometryEditMode.RemovePoints" :points="path" @remove="pointRemoved" />
  <pp4-label v-if="labelProps" v-bind="labelProps" :clickable="clickable" />
  <slot />
</template>

<script>
import { toRaw } from 'vue'
import * as GeoUtil from '@/services/GeoUtil'
import { GeometryEditMode } from '@/utils/Dto'

import RemovePoints from '@/components/maps/RemovePoints'
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import Pp4Label from '@/components/maps/Label'

export default {
  extends: Pp4MapObject,
  components: { RemovePoints, Pp4Label },
  props: {
    path: {
      type: Array,
      required: true
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    strokeColor: {
      type: String,
      required: false,
      default: "white"
    },
    fillColor: {
      type: String,
      required: false,
      default: 'black'
    },
    fillOpacity: {
      required: false,
      default: 0.5
    },
    strokeWeight: {
      required: false,
      default: 3
    },
    strokeOpacity: {
      required: false,
      default: 1.0
    },
    zIndex: {
      type: Number,
      required: false,
      default: 0
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    labelClass: {
      type: String,
      required: false,
      default: null
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    infoWindowContent: {
      type: String,
      required: false,
      default: null
    },
    editMode: {
      type: Number,
      required: false,
      default: GeometryEditMode.None,
      validator: function (value) { return value == GeometryEditMode.None || value == GeometryEditMode.EditPoints || value == GeometryEditMode.RemovePoints }
    }
  },
  computed: {
    geo: (vm) => GeoUtil.LatLngs.toPolygon(vm.path),
    centerLatLng: (vm) => {
      const centerGeo = GeoUtil.GeoJson.centerOfMass(vm.geo)
      return GeoUtil.GeoJson.toLatLngs(centerGeo)
    },
    labelProps: vm => {
      if (!(vm.showLabel && vm.label?.length)) {
        return null
      }


      let ret = {
        zIndex: vm.zIndex + 1
      }

      if (vm.labelClass) {
        ret['elementClass'] = vm.labelClass
      }

      ret.labelText = vm.label
      ret.latLng = vm.centerLatLng
      ret.clickable = vm.clickable

      return ret
    }
  },
  watch: {
    path: 'updateOrCreatePoly',
    strokeColor: 'updateOrCreatePoly',
    strokeWeight: 'updateOrCreatePoly',
    strokeOpacity: 'updateOrCreatePoly',
    fillColor: 'updateOrCreatePoly',
    fillOpacity: 'updateOrCreatePoly',
    showLabel: 'updateOrCreatePoly',
    clickable: 'updateOrCreatePoly',
    infoWindowContent: 'updateOrCreatePoly',
    zIndex: 'updateOrCreatePoly',
    editMode: 'activateEditMode'
  },
  data: function () {
    return {
      GeometryEditMode,
      polyMapObject: null,
      infoWindowObject: null
    }
  },
  unmounted() {
    toRaw(this.polyMapObject)?.setMap(null)
    toRaw(this.infoWindowObject)?.setMap(null)
    this.polyMapObject = null
    this.infoWindowObject = null
  },
  methods: {
    buildInfoWindow(content) {
      let contentWrapper = document.createElement('div')
      contentWrapper.innerHTML = content
      contentWrapper.className += 'info_window_content'
      return new this.api.InfoWindow({
        content: contentWrapper
      })
    },
    buildPoly(latLngs, options) {
      if (!options) {
        options = {}
      }

      options.paths = latLngs

      let ret = new this.api.Polygon(options)

      toRaw(ret).setMap(toRaw(this.map))

      return ret
    },
    updateOrCreatePoly() {
      let options = {
        path: this.path,
        editable: false,
        clickable: this.clickable,
        strokeColor: this.strokeColor,
        strokeWeight: this.strokeWeight,
        strokeOpacity: this.strokeOpacity,
        fillColor: this.fillColor,
        fillOpacity: this.fillOpacity,
        zIndex: this.zIndex
      }

      if (!this.polyMapObject) {
        this.polyMapObject = this.buildPoly(this.path, options)
      }
      else {
        toRaw(this.polyMapObject).setOptions(options)
      }

      this.updateEventListeners()

      this.activateEditMode()
    },
    onClick(e) {
      this.$emit('click', e)
    },
    updateEventListeners() {
      const events = Object.keys(this.$attrs)
      events.forEach((attrEvent) => {
        const event = attrEvent.slice(2).toLowerCase()

        this.api.event.clearListeners(toRaw(this.polyMapObject), event)

        this.api.event.addListener(toRaw(this.polyMapObject), event, (e) => {
          this.$emit(event, e)
        })
      })
    },
    updateInfoWindow() {
      if (this.infoWindowContent == null || this.polyMapObject == null || this.infoWindowContent.length < 1) {

        if (this.infoWindowObject) {
          this.infoWindowObject.setMap(null)
          this.infoWindowObject = null
        }

        return
      }

      let bounds = this.objectBounds(this.polyMapObject)

      this.infoWindowObject = this.buildInfoWindow(this.infoWindowContent)
      this.infoWindowObject.setPosition(bounds.getCenter())

      this.api.event.addListener(this.polyMapObject, 'click', (e) => {
        this.infoWindowObject.open(this.map)
      })
    },
    activateEditMode() {
      if (!this.polyMapObject) {
        return
      }

      if (this.editMode == GeometryEditMode.EditPoints) {
        this.activateEditPointsMode()
      }
      else {
        toRaw(this.polyMapObject).setOptions({ path: this.path })
      }
    },
    activateEditPointsMode() {
      this.polyMapObject.setOptions({
        editable: true
      })

      let path = this.polyMapObject.getPath()
      this.api.event.addListener(path, 'set_at', () => this.emitPolyChanged())
      this.api.event.addListener(path, 'insert_at', () => this.emitPolyChanged())
      this.api.event.addListener(path, 'remove_at', () => this.emitPolyChanged()) //for the undo events
    },
    pointRemoved(newPath) {
      this.$emit("poly_changed", newPath)
    },
    emitPolyChanged() {
      let latLngs = this.buildPathFromGooglePoly(this.polyMapObject)
      this.$emit("poly_changed", latLngs)
    },
    mapReady() {
      this.updateOrCreatePoly()
      this.updateInfoWindow()
    }
  },
  emits: ['poly_changed', 'dblclick', 'click', 'mousemove', 'mouseover', 'mouseout'],
}
</script>
