<template>
  <div class="wrapper">
    <Table
      :search="true"
      @search="searchTextChanged($event)"
      :controls="true"
      :data="rows"
      detailKey="id"
      :pagination="rowPagination"
      @pageChange="pageChange"
      :columns="columns"
      :loading="rowPagination.loading"
      :snapTo="selectedPickupLocationRowId"
      @rowOpened="rowOpenedHandler"
    >
    <!-- :row-class="(row, index) => row.id === 1 && 'is-info'"> -->
      <template #controls>
        <filters>
          <template #filters>
            <div class="filters_columns">
              <div class="filters_rows">
                <!-- row one filters -->
                <inclusive-filter
                  :name="'Routed'"
                  :value="filters.routed"
                  @change="updateFilter('routed', $event)"
                  :loading="rowPagination.loading"
                />
                <inclusive-filter
                  :name="'Owned'"
                  :value="filters.owned"
                  @change="updateFilter('owned', $event)"
                  :loading="rowPagination.loading"
                />
                <inclusive-filter
                  :name="'Archived'"
                  :value="filters.archived"
                  @change="updateFilter('archived', $event)"
                  :loading="rowPagination.loading"
                />

                <span class="ml-3 filters_rows">
                  <b-checkbox size="is-small" v-model="noPickupFilter">No Pickup</b-checkbox>
                </span>
                <span class="ml-3 filters_rows">
                  <b-checkbox size="is-small" v-model="allWeatherOkFilter">All Weather</b-checkbox>
                </span>
              </div>

              <div class="filters_rows" style="justify-content: stretch;">
                <div>
                  <div class="filters_rows mt-1">
                    <label class="mr-1" for="pickup-location-user-search"
                      >Owner Search:
                    </label>
                    <input
                      id="pickup-location-user-search"
                      :disabled="rowPagination.loading"
                      type="text"
                      @change="updateFilter('ownerSearchText', $event.target.value)"
                    />
                  </div>

                  <div class="filters_rows mt-1">
                    <label class="mr-1" for="pickup-location-container-search">Container:</label>
                    <input
                      id="pickup-location-container-search"
                      :disabled="rowPagination.loading"
                      type="text"
                      @change="updateFilter('containerSearchText', $event.target.value)"
                    />
                  </div>
                </div>
                <bbox-filter
                  style="justify-content: center; flex: 1;"
                  :value="filters.bbox"
                  @change="updateFilter('bbox', $event)"
                />
              </div>
            </div>
          </template>
        </filters>
        <div style="display: flex; flex-direction: column; margin-bottom: 5px">
          <span class="is-uppercase has-text-weight-bold">Actions</span>
          <div style="display: flex; flex-direction: row">
            <b-button
              @click="beginAddPickupLocation"
              size="is-small"
              style="margin-right: 5px"
              >Add</b-button
            >
            <b-button
              @click="beginArchivePickupLocation"
              size="is-small"
              style="margin-right: 5px"
              >Archive</b-button
            >
            <b-button
              @click="beginMergePickupLocations"
              size="is-small"
              style="margin-right: 5px"
              >Merge</b-button
            >
            <b-button
              size="is-small"
              @click="refresh"
              icon-right="refresh"
              title="Refresh Drivers"
            />
          </div>
        </div>
      </template>

      <template #name="{ value }">
        <span style="white-space: nowrap">{{ value.name }}</span>
      </template>

      <template #ownerName="{ value }">
        <User v-if="value.owner" :value="value.owner" />
        <template v-else>N/A</template>
      </template>

      <template #rain="{ value }">
        <Weather :value="value.weather" />
      </template>

      <template #detail="props">
        <pickup-location-edit :startingValue="props.value" 
        ref="pickupLocationEdit"/>
      </template>
    </Table>

    <pickup-locations :pickupLocations="rows" 
      :selectedPickupLocation="selectedPickupLocation" />

    <div style="display: none">
      <filter-bounds v-if="filters.bbox" :bboxPath="filters.bbox" />

      <add-pickup-location
        v-if="addingPickupLocation"
        @done="addPickupLocationDone"
      />
      <merge-pickup-locations
        v-if="mergingPickupLocations"
        @done="mergePickupLocationsDone"
      />
      <archive-pickup-location
        v-if="
          !addingPickupLocation &&
          !mergingPickupLocations &&
          archivingPickupLocation
        "
        @done="archivePickupLocationDone"
      />
    </div>
  </div>
</template>

<script>
import Table from "@/components/common/Table";
import InclusiveFilter from "@/components/common/InclusiveFilter.vue";
import Filters from "@/components/common/Filters.vue";
import Weather from "@/components/common/Weather";
import User from "@/components/common/User.vue";

import Pp4MapObject from "@/components/maps/Pp4MapObject";
import PickupLocations from "@/components/maps/PickupLocations";
import AddPickupLocation from "@/components/maps/AddPickupLocation";
import MergePickupLocations from "@/components/maps/MergePickupLocations";
import ArchivePickupLocation from "@/components/maps/ArchivePickupLocation";
import FilterBounds from "@/components/maps/FilterBounds.vue";

import PickupLocationEdit from "@/views/RouteWizard/components/PickupLocationEdit";
import BboxFilter from "@/views/RouteWizard/components/BboxFilter.vue";
import * as UserUtil from '@/services/UserUtil';
import { debounce } from "lodash";

export default {
  extends: Pp4MapObject,
  props: [],
  inject: ["sidebarStatus"],
  components: {
    Table,
    Filters,
    InclusiveFilter,
    BboxFilter,
    AddPickupLocation,
    PickupLocations,
    PickupLocationEdit,
    MergePickupLocations,
    ArchivePickupLocation,
    FilterBounds,
    Weather,
    User,
  },
  data() {
    return {
      noPickupFilter: false,
      allWeatherOkFilter: false,
      columns: [
        { field: "name", label: "Name", visible: true, sortable: true },
        { field: "id", visible: false },
        {
          field: "rain",
          label: "Rain",
          visible: true,
          sortable: true,
          centered: true,
        },
        {
          field: "ownerName",
          label: "Owner",
          visible: true,
          sortable: true,
          centered: true,
        },
      ],
      addingPickupLocation: false,
      mergingPickupLocations: false,
      archivingPickupLocation: false,
    };
  },
  computed: {
    mapActivityActive() {
      return this.$store.state.mapActivityActive;
    },
    states: (vm) => vm.$store.state.states,
    rows: (vm) => {
      return vm.$store.state.paginatedPickupLocations?.data?.map((ppl) => {
        const ret = { ...ppl };

        ret.ownerName = ppl.owner ? 
          UserUtil.getFullNameAny(ppl.owner) : 'Unowned';
        ret.rain = vm.getTotalRain(ret.weather);

        return ret;
      }) || []
    },
    rowPagination: (vm) => vm.$store.state.paginatedPickupLocations,
    filters: (vm) => vm.$store.state.paginatedPickupLocations.filters,
    selectedPickupLocation: vm => vm.$store.state.selectedPickupLocation,
    selectedPickupLocationRowId: (vm) => {
      return vm.selectedPickupLocation?.id;
    },
  },
  watch: {
    noPickupFilter(newValue) { this.updateBinaryFilter("noPickupFilter", newValue) },
    allWeatherOkFilter(newValue) { this.updateBinaryFilter("allWeatherOkFilter", newValue) }
  },
  mounted() {
    this.refresh();
  },
  created() {
    this.debounceRefresh = debounce(function () {
      this.$store.dispatch('updatePaginatedPickupLocations');
    }, 1000);
  },
  methods: {
    // https://stackoverflow.com/questions/46925527/vue-js-bind-object-properties
    searchTextChanged(text) {
      this.updateFilter("searchText", text);
    },
    async updateFilter(property, value) {
      const newFilters = { ...this.filters };
      newFilters[property] = value;
      await this.$store.dispatch("updatePaginatedPickupLocationsOptions", {
        filters: newFilters,
      });

      this.debounceRefresh();
    },
    updateBinaryFilter(name, isOn) {
      this.updateFilter(name, isOn ? ['inc'] : []);
    },
    async refresh() {
      await this.$store.dispatch("updatePaginatedPickupLocations");
      const pickupLocationEdit = this.$refs.pickupLocationEdit;
      if(pickupLocationEdit) {
        pickupLocationEdit.refresh();
      }
    },
    beginAddPickupLocation() {
      if (this.mapActivityActive) {
        return;
      }

      this.addingPickupLocation = true;
      this.$store.dispatch("mapActivityActive");
    },
    addPickupLocationDone() {
      this.addingPickupLocation = false;
      this.$store.dispatch("mapActivityDone");
    },
    beginMergePickupLocations() {
      if (this.mapActivityActive) {
        return;
      }

      this.mergingPickupLocations = true;
      this.$store.dispatch("mapActivityActive");
    },
    beginArchivePickupLocation() {
      if (this.mapActivityActive) {
        return;
      }

      this.archivingPickupLocation = true;
      this.$store.dispatch("mapActivityActive");
    },
    mergePickupLocationsDone() {
      this.mergingPickupLocations = false;
      this.$store.dispatch("mapActivityDone");
    },
    archivePickupLocationDone() {
      this.archivingPickupLocation = false;
      this.$store.dispatch("mapActivityDone");
    },
    async pageChange(newPage) {
      await this.$store.dispatch("updatePaginatedPickupLocationsOptions", {
        page: newPage,
      });
      this.$store.dispatch("updatePaginatedPickupLocations");
    },
    rowOpenedHandler(event) {
      const openedPickupLocation = this.rows.find((ppl) => ppl.id == event);

      if (openedPickupLocation) {
        this.$store.dispatch("setSelectedPickupLocation", openedPickupLocation);
        this.panTo(openedPickupLocation);
      }
      else {
        this.$store.dispatch("setSelectedPickupLocation", null);
      }
    },
    panTo(pl) {
      const geoJson = pl?.geoJson;

      if (geoJson) this.panToGeoJson(geoJson);
    },
    getTotalRain(weather) {
      const rainProps = [
        "precip-0",
        "precip-1",
        "precip-2",
        "precip-3",
        "precip-4",
        "precip-5",
        "precip-6",
        "precip+1",
        "precip+2",
        "precip+3",
        "precip+4",
      ];

      return weather
        ? rainProps.reduce((accum, prop) => accum + weather[prop], 0)
        : 0;
    },
  },
};
</script>

<style lang="css" scoped>
.wrapper {
  position: relative;
  height: 100%;
  display: flex;
}

.wrapper .b-table {
  width: 100%;
}

.filters_columns {
  display: flex;
  flex-direction: column;
}
.filters_rows {
  display: flex;
  flex-direction: row;
}
</style>
