<template>
  <b-loading :is-full-page="true" :modelValue="true">
    <div class="wrapper">
      <div>
        <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
      </div>
      <br />
      <label>{{ label }}</label>
    </div>
  </b-loading>
</template>

<script>
export default {
  name: 'PageLoader',
  props: {
    label: {
      type: String,
      required: false,
      default: "Loading..."
    }
  },
  emits: [],
  computed: {
    loader() {
      return this.$store.getters.loader;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  color: black;
  font-size: xx-large;
  font-weight: bold;
}
</style>
