<script>
import { toRaw } from 'vue';

import { GeoJson, LatLngs } from '@/services/GeoUtil';
import { LatLng } from '@/utils/Dto';

export default {
  inject: ['provider'],
  computed: {
    api: (vm) => vm.provider.api,
    map: (vm) => vm.provider.map,
    zoomLevel: (vm) => vm.provider.zoomLevel,
    boundsBbox: (vm) => vm.provider.boundsBbox,
    boundsBboxGeoJson: (vm) => vm.provider.boundsBboxGeoJson,
    mapObjectFactory: (vm) => vm.provider.mapObjectFactory,
    mapLoaded: (vm) => vm.provider.mapLoaded,
  },
  data: function () {
    return {
      mapListeners: [],
    };
  },
  unmounted() {
    this.clearMapListeners();
  },
  render() {
    return null;
  },
  watch: {
    provider: 'invokeMapReadyIfAvailable',
  },
  mounted() {
    this.invokeMapReadyIfAvailable();
  },
  methods: {
    mapReady() {
    },
    invokeMapReadyIfAvailable() {
      if (this.api && this.map) {
        this.mapReady();
      }
    },
    clearMapListeners: function () {
      this.mapListeners.forEach((listener) => {
        this.api.event.removeListener(listener);
      });

      this.mapListeners = [];
    },
    addMapListener(eventName, callback) {
      let listener = this.api.event.addListener(this.map, eventName, callback);
      this.mapListeners.push(listener);
    },
    addMapDomListener(eventName, callback) {
      let listener = this.api.event.addDomListener(this.map, eventName, callback);
      this.mapListeners.push(listener);
    },
    buildPathFromGooglePoly(poly) {
      let ret = [];

      poly.getPath().forEach(function (p) {
        ret.push(new LatLng(p.lat(), p.lng()));
      });

      return ret;
    },
    fitBoundsForGeoJson(geo, padding) {
      let latLngs = GeoJson.toLatLngs(geo);
      this.fitBoundsForLatLngs(latLngs, padding);
    },
    fitBoundsForLatLngs(latLngs, padding) {
      const bounds = new this.api.LatLngBounds();

      if (latLngs instanceof Array) {
        latLngs.forEach((ll) => bounds.extend(ll));
      } else if (latLngs instanceof Object) {
        bounds.extend(latLngs);
      } else {
        console.warn('Cannot Fit Bounds To: ' + latLngs);
        return;
      }

      this.map.fitBounds(bounds, padding);
    },
    panToGeoJson(geo) {
      const centerPoint = GeoJson.center(geo);
      const latLng = GeoJson.toLatLngs(centerPoint);
      this.panToLatLngs(latLng);
    },
    panToLatLngs(latLngs) {
      if (latLngs instanceof Array) {
        if (latLngs.length < 1) {
          return;
        }

        const pointGeos = latLngs.map(LatLngs.toPointGeoJson);
        const fc = GeoJson.featureCollection(pointGeos);
        const centerPoint = GeoJson.center(fc);
        const centerLatLng = GeoJson.toLatLngs(centerPoint);

        this.map.panTo(centerLatLng);
      } else if (latLngs instanceof Object) {
        this.map.panTo(latLngs);
      }
    },
    panToBoundsGeoJson(geo) {
      const latLngs = GeoJson.toLatLngs(geo);
      const bounds = new this.api.LatLngBounds();
      latLngs.forEach((ll) => bounds.extend(ll));
      this.map.panToBounds(bounds);
    },
    setZoomLevel(level) {
      this.map.setZoom(level);
    },
    objectBounds(o) {
      let ret = new this.api.LatLngBounds();

      if (o.getPaths instanceof Function) {
        o.getPaths().forEach(function (paths) {
          paths.forEach(function (p) {
            ret.extend(p)
          })
        })
      }
      else if (o.getPath instanceof Function) {
        o.getPath().forEach(function (p) {
          ret.extend(p)
        })
      }

      return ret
    },
    buildPathFromGooglePoly(poly) {
      let ret = []

      poly.getPath().forEach(function (p) {
        ret.push(new LatLng(p.lat(), p.lng()))
      })

      return ret
    }
  },
};
</script>
