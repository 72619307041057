<template>
  <div class="google-map-wrapper">
    <template v-if="!mapLoaded"> Map Loading... </template>
    <slot />

    <div v-if="!empty">
      <sidebar ref="profileSidebar" />
      <SearchBar v-if="mapLoaded" />
      <map-controls v-if="mapLoaded" position="BOTTOM_LEFT">
        <profile-button @click="$refs.profileSidebar.onOpen()" />
      </map-controls>
    </div>

    <!-- we need the map div visible to load the map API... -->
  </div>
</template>

<script>
// import { Loader } from "google-maps";
import { Loader } from '@googlemaps/js-api-loader';

import { GeoJson } from "@/services/GeoUtil";
import MapObjectFactory from "@/components/maps/MapObjectFactory";
import MapControls from "@/components/maps/MapControls";
import SearchBar from "@/components/maps/SearchBar";
import Sidebar from "@/components/common/Sidebar.vue";

import ProfileButton from "./ProfileButton.vue";

function debounce(callback, timeoutInMilliseconds) {
  let timeout = null;

  return () => {
    const invokeCallback = function () {
      timeout = null;
      callback();
    };

    if (timeout != null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(invokeCallback, timeoutInMilliseconds);
  };
}

export default {
  components: {
    SearchBar,
    MapControls,
    Sidebar,
    ProfileButton,
  },
  props: ["mapDivId", "empty"],
  provide() {
    return {
      provider: this.provider,
    };
  },
  data() {
    return {
      // from PP4
      provider: {
        api: null,
        map: null,
        mapObjectFactory: null,
        zoomLevel: 1,
        boundsBbox: null,
        boundsBboxGeoJson: null,
        mapLoaded: false,
      },
      mapBecameIdle: false,
      tilesLoaded: false,

      bounds: null,
      gribBoundingBox: null,
      google: null,
      map: null,
      options: {
        zoom: 5,
        mapId: "b402a9ce4c185aac",
        mapTypeId: "roadmap",
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "satellite"],
        },
        mapTypeControl: true,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        streetViewControl: true,
        zoomControl: false,
        rotateControl: true,
        scaleControl: true,
        panControl: true,
        draggable: true,
        isFractionalZoomEnabled: true,
        gestureHandling: 'greedy',
        backgroundColor: "#ccff00",
      },
      data: {
        points: [],
        workOrders: [],
        markerClusterer: null,
      },
      count: 0,
    };
  },
  async mounted() {
    this.initMapsIfPossible();
  },
  methods: {
    mapTypeToggle() {
      if (this.map.mapTypeId == "satellite") {
        this.map.setMapTypeId("roadmap");
      } else {
        this.map.setMapTypeId("satellite");
      }
    },
    async initMapsIfPossible() {
      const mapDiv = document.getElementById(this.mapDivId);
      if (!mapDiv) {
        setTimeout(() => {
          this.initMapsIfPossible();
        }, 200);
        return;
      }

      const loader = new Loader({
        apiKey: this.$env.VUE_APP_GOOGLE_MAPS_API_KEY,
        region: "US",
        version: "beta",
        libraries: ["places", "drawing", "geometry", "visualization"]
      });


      const [libCore, libMaps, libPlaces, libGeometry, libMarker] = await Promise.all([
        loader.importLibrary("core"),
        loader.importLibrary("maps"),
        loader.importLibrary("places"),
        loader.importLibrary("geometry"),
        loader.importLibrary('marker')
      ])

      const mapsApi = { ...libCore, ...libMaps, places: libPlaces, geometry: libGeometry, ...libMarker }

      const map = new google.maps.Map(mapDiv, this.options);

      this.provider.api = mapsApi;
      this.provider.map = map;
      this.provider.mapObjectFactory = new MapObjectFactory(this.provider.api, this.provider.map);

      this.google = google;
      this.map = map;

      this.map.addListener("click", (event) => {
        const { lat, lng } = event.latLng;
        this.$store.commit("curPos", {
          lat: lat(),
          lng: lng(),
        });
      });

      const updateBoundsAndZoomLevel = () => {
        const bounds = map.getBounds();
        if (!bounds || bounds.isEmpty()) {
          return;
        }

        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const boundsEmpty =
          Number.isNaN(sw.lat()) ||
          Number.isNaN(sw.lng()) ||
          Number.isNaN(ne.lat()) ||
          Number.isNaN(ne.lng());
        if (boundsEmpty) {
          return;
        }

        this.provider.zoomLevel = map.getZoom();
        //const bbox = this.provider.googleMapUtils.latLngBoundsToBbox(bounds)
        this.provider.boundsBbox = [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
        this.provider.boundsBboxGeoJson = GeoJson.bboxPolygon(this.provider.boundsBbox);
      };

      this.provider.api.event.addListenerOnce(map, "idle", () => {
        map.setZoom(6);
        map.setCenter({ lat: 35.03012534, lng: -90.8866108496 });

        updateBoundsAndZoomLevel();

        this.mapBecameIdle = true;
      });

      this.provider.api.event.addListenerOnce(map, "tilesloaded", () => {
        this.tilesLoaded = true;
      });

      this.provider.api.event.addListener(map, 'mapcapabilities_changed', () => {
        const mapCapabilities = map.getMapCapabilities();

        if (!mapCapabilities.isAdvancedMarkersAvailable) {
          console.warn("Advanced Markers Not Available -- Check Google Maps API Setup")
        }
      });

      this.map.addListener("dragstart", () => {
        this.map.moving = true;
      });

      this.map.addListener("dragend", () => {
        this.map.moving = false;
      });

      this.map.addListener(
        "bounds_changed",
        debounce(() => {
          updateBoundsAndZoomLevel();
        }, 400)
      );
    },
    updateProviderMapLoaded() {
      this.provider.mapLoaded = this.mapLoaded;
      this.$emit("loaded", this.provider);
    },
  },
  computed: {
    apiLoaded: (vm) => Boolean(vm.provider.api) && Boolean(vm.provider.map),
    mapLoaded: (vm) => vm.apiLoaded && vm.mapBecameIdle && vm.tilesLoaded,
  },
  watch: {
    mapLoaded: "updateProviderMapLoaded",
  },
};
</script>

<style scoped>
.google-map-wrapper {
  width: 100%;
  height: 100%;
}

#mapdiv {
  width: 100%;
  height: 100%;
}
</style>

import SearchBar from '@/components/maps/SearchBar';
