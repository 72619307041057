<template>
  <map-controls position="TOP_CENTER">
    <div class="context_menu has-text-white has-background-light p-1">
      <div v-if="hasTop" class="context-menu-top">
        <slot name="top" />
      </div>

      <div class="context-menu-primary">
        <slot />
      </div>
    </div>
  </map-controls>
</template>

<script>
import MapControls from '@/components/maps/MapControls';

export default {
  components: {
    MapControls,
  },
  props: {},
  computed: {
    hasTop: (vm) => vm.$slots.top != null,
  },
  mounted() {},
};
</script>

<style lang="css">
.context_menu {
  margin-left: 1.5em;
  border-radius: 0.75rem;
  flex-flow: column;
  overflow: auto;
  font-size: medium;
  border: 2px solid var(--primary);
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

@media screen and (orientation: portrait) {
  .context_menu {
    max-height: 60vh;
  }
}

@media screen and (orientation: landscape) {
  .context_menu {
    max-height: 80vh;
  }
}

.context-menu-primary {
  display: flex;
  flex-direction: column;
  align-content: center;
  row-gap: 0.5rem;
}

.context-menu-top {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 1.5em;
  row-gap: 0.5rem;
}

.context_menu .b-checkbox {
  padding: 0em;
}

.context_menu .b-checkbox .icon {
  margin: 0px !important;
}

.context_menu .button .icon {
  width: 1em !important;
  padding-left: 0.75em !important;
  padding-right: 0.75em !important;
  padding-bottom: 1em !important;
  padding-top: 1em !important;
}
</style>
