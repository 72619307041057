<template>
  <span class="weather" v-if="value">
    <span :style="valueStyle(t6)" :title="valueTitle(-6, t6)"></span>
    <span :style="valueStyle(t5)" :title="valueTitle(-5, t5)"></span>
    <span :style="valueStyle(t4)" :title="valueTitle(-4, t4)"></span>
    <span :style="valueStyle(t3)" :title="valueTitle(-3, t3)"></span>
    <span :style="valueStyle(t2)" :title="valueTitle(-2, t2)"></span>
    <span :style="valueStyle(t1)" :title="valueTitle(-1, t1)"></span>
    <span :style="valueStyle(t0, { large: true })" :title="valueTitle(0, t0)"></span>
    <span :style="valueStyle(f1)" :title="valueTitle(1, f1)"></span>
    <span :style="valueStyle(f2)" :title="valueTitle(2, f2)"></span>
    <span :style="valueStyle(f3)" :title="valueTitle(3, f3)"></span>
    <span :style="valueStyle(f4)" :title="valueTitle(4, f4)"></span>
  </span>
  <span v-else> N/A </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  computed: {
    f4: (vm) => (vm.value ? vm.value['precip+4'] : null),
    f3: (vm) => (vm.value ? vm.value['precip+3'] : null),
    f2: (vm) => (vm.value ? vm.value['precip+2'] : null),
    f1: (vm) => (vm.value ? vm.value['precip+1'] : null),
    t0: (vm) => (vm.value ? vm.value['precip-0'] : null),
    t1: (vm) => (vm.value ? vm.value['precip-1'] : null),
    t2: (vm) => (vm.value ? vm.value['precip-2'] : null),
    t3: (vm) => (vm.value ? vm.value['precip-3'] : null),
    t4: (vm) => (vm.value ? vm.value['precip-4'] : null),
    t5: (vm) => (vm.value ? vm.value['precip-5'] : null),
    t6: (vm) => (vm.value ? vm.value['precip-6'] : null),
  },
  methods: {
    valueTitle(index, inches) {
      if (!this.value) {
        return 'N/A';
      }

      const now = new Date();
      now.setTime(now.getTime() + index * 24 * 60 * 60 * 1000);

      const inchesString = Number.isFinite(inches) ? inches.toFixed(2) : 'N/A';

      const fetchTime = new Date(this.value.modifiedOn).toLocaleString();

      return (
        this.dayString(now.getDay()) +
        ' ' +
        now.toLocaleDateString() +
        '\n' +
        inchesString +
        ' in.' +
        '\nFetched: ' +
        fetchTime
      );
    },
    dayString(day) {
      switch (day) {
        case 0:
          return 'Sun';
        case 1:
          return 'Mon';
        case 2:
          return 'Tue';
        case 3:
          return 'Wed';
        case 4:
          return 'Thu';
        case 5:
          return 'Fri';
        default:
          return 'Sat';
      }
    },
    valueStyle(inches, opts = { large: false }) {
      const large = opts.large;

      return `display: inline-block; width: 0.75em; height: ${
        large ? '1.8em' : '1em'
      }; background-color: ${this.valueColor(inches)}`;
    },
    valueColor(inches) {
      // I think this came from here: https://www.weather.gov/ama/precipmapnew
      
      if (inches < 0.01) {
        return '#7d7d7d';
      }
      if (inches < 0.1) {
        return '#14c8fa';
      }
      if (inches < 0.25) {
        return '#3d85c6';
      }
      if (inches < 0.5) {
        return '#001496';
      }
      if (inches < 0.75) {
        return '#00fa14';
      }
      if (inches < 1.0) {
        return '#00a00f';
      }
      if (inches < 1.5) {
        return '#00640a';
      }
      if (inches < 2.0) {
        return '#fafa00';
      }
      if (inches < 2.5) {
        return '#ffd966';
      }
      if (inches < 3.0) {
        return '#fa9600';
      }
      if (inches < 4.0) {
        return '#fa0000';
      }
      if (inches < 5.0) {
        return '#af0000';
      }
      if (inches < 6.0) {
        return '#7d0000';
      }
      if (inches < 8.0) {
        return '#fa00fa';
      }

      return '#7d4be1';
    },
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.weather {
  //border: 3px solid black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0px;
  margin: 0px;
}
</style>
