<!-- 
    A card to display template information for both spotted points and pickup locations
 -->

<template>
  <div class="subform">
    <span class="is-uppercase has-text-weight-bold">{{ value.name }}</span>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-if="!addressByLabel" style="align-self: center">
        {{
          `${value.addStreet}, ${value.addCity}, ${value.addState}, ${value.addCounty}
                                ${value.addPostalCode},${value.addCountry}`
        }}
      </div>
      <div v-else style="display: flex; flex-direction: column">
        <span>Street: {{ value.addStreet }}</span>
        <span>City: {{ value.addCity }}</span>
        <span>State: {{ value.addState }}</span>
        <span>Postal Code: {{ value.addPostalCode }}</span>
      </div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <pan-to-button v-if="value.pointLatLng" :location="value.pointLatLng" style="margin: 5px" />
        <span v-if="value && value.owner" style="white-space: nowrap"
          >Owned by
          <user :value="value.owner" />
        </span>
      </div>
    </div>
    <div v-if="value.notes && value.notes.length > 0" class="subform sub-subform">
      <span class="has-text-weight-bold">Location Notes</span>
      {{ value.notes }}
    </div>
  </div>
</template>

<script>
import PanToButton from '@/components/common/PanToButton';
import User from '@/components/common/User.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    addressByLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PanToButton,
    User,
  },
};
</script>

<style lang="css" scoped>
.sub-subform {
  border: 1px solid rgb(186, 186, 186);
}
</style>
