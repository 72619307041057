<template>
  <div class="wrapper">
    <b-table
      :data="routesWithMetadata"
      :backend-pagination="true"
      :total="paginatedRoutes.total"
      :current-page="paginatedRoutes.page"
      :per-page="paginatedRoutes.perPage"
      hoverable
      :pagination-rounded="true"
      custom-detail-row
      :columns="columnsWithDates"
      :loading="loading"
      detailed
      detail-key="id"
      :opened-detailed="openedDetailedRoutes"
      :show-detail-icon="true"
      :sticky="true"
      :sticky-header="true"
      :sticky-headers="true"
      :paginated="true"
      :pagination-simple="true"
      pagination-position="top"
      bordered
      pagination-size="is-small"
      class="is-small is-size-7 table"
      @page-change="pageChanged"
    >
      <div style="display: flex; flex-direction: column; margin-bottom: 10px">
        <div style="margin-bottom: 10px">
          <span class="is-uppercase has-text-weight-bold">Scheduled On</span>
          <b-datepicker
            v-model="dateRange"
            size="is-small"
            range
            placeholder="Date"
          >
          </b-datepicker>
          <b-button
            style="margin-top: 10px; width: 100%; color: white; background-color: var(--primary);"
            @click="clearDateRange"
            :disabled="!dateRangeValid"
            size="is-small"
          >
            Clear
          </b-button>
        </div>
        <div>
          <span class="is-uppercase has-text-weight-bold">Driver</span>
          <driver-select v-model="leadDriver" />
        </div>
      </div>
      <template #detail="props">
        <tr>
          <td colspan="9">
            <RouteEdit :rowPointer="props.row.rowPointer" :readonly="true" />
          </td>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import RouteEdit from '@/components/common/RouteEdit';
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import DriverSelect from '@/views/RouteWizard/components/DriverSelect';
import { getFullNameAny } from '@/services/UserUtil';

export default {
  extends: Pp4MapObject,
  props: [],
  inject: ['sidebarStatus'],
  components: {
    DriverSelect,
    RouteEdit
  },
  data: function () {
    return {
      dateRange: [],
      leadDriver: null,
      openedDetailedRoutes: [],
      lastOpenedDetailedRouteId: null,
      columnsWithDates: [
        { field: 'driverName', label: 'Driver' },
        { field: 'formattedScheduledOn', label: 'Scheduled', sortable: true },
        { field: 'formattedCompletedOn', label: 'Completed', sortable: true },
      ],
    };
  },
  computed: {
    paginatedRoutes: (vm) => vm.$store.state.paginatedRoutes,
    loading: vm => vm.paginatedRoutes.loading,
    routesWithMetadata: vm => {
      return vm.paginatedRoutes.data.map((route) => {
        const ret = Object.assign({}, route);

        ret.dateStartedOn = ret.startedOn ? new Date(ret.startedOn).toLocaleDateString() : null;
        ret.dateCreatedOn = ret.createdOn ? new Date(ret.createdOn).toLocaleDateString() : null;
        ret.dateScheduledOn = ret.scheduledOn ? new Date(ret.scheduledOn) : null;
        ret.dateModifiedOn = ret.modifiedOn ? new Date(ret.modifiedOn) : null;
        ret.dateCompletedOn = ret.completedOn ? new Date(ret.completedOn) : null;

        ret.formattedCreatedOn = ret.createdOn ? new Date(ret.createdOn).toLocaleDateString() : null;
        ret.formattedScheduledOn = ret.scheduledOn ? new Date(ret.scheduledOn).toLocaleDateString() : null;
        ret.formattedCompletedOn = ret.completedOn ? new Date(ret.completedOn).toLocaleDateString() : null;

        ret.driverName = getFullNameAny(ret.assignedToUser);

        return ret;
      });
    },
    dateRangeValid: (vm) => {
      return vm.dateRange && vm.dateRange.length >= 2;
    },
  },
  watch: {
    openedDetailedRoutes: 'openedDetailedRoutesChanged',
    dateRange: 'dateRangeChanged',
    leadDriver: 'leadDriverChanged',
  },
  mounted() {
    this.$store.dispatch('updatePaginatedRoutes');
  },
  methods: {
    leadDriverChanged() {
      this.$store.dispatch('updatePaginatedRoutesOptions', {
        assignedTo: this.leadDriver ? this.leadDriver.userId : null,
      });

      this.$store.dispatch('updatePaginatedRoutes');
    },
    dateRangeChanged() {
      if (this.dateRange.length < 2) {
        this.$store.dispatch('updatePaginatedRoutesOptions', {
          scheduledOnBegin: null,
          scheduledOnEnd: null,
        });
      } else {
        this.$store.dispatch('updatePaginatedRoutesOptions', {
          scheduledOnBegin: this.dateRange[0],
          scheduledOnEnd: this.dateRange[1],
        });
      }

      this.$store.dispatch('updatePaginatedRoutes');
    },
    async pageChanged(newPage) {
      await this.$store.dispatch('updatePaginatedRoutesOptions', {
        page: newPage,
      });
      await this.$store.dispatch('updatePaginatedRoutes');
    },
    openedDetailedRoutesChanged() {
      if (this.openedDetailedRoutes.length === 0) {
        this.lastOpenedDetailedRouteId = null;
        return;
      }

      if (this.openedDetailedRoutes.length === 1) {
        this.lastOpenedDetailedRouteId = this.openedDetailedRoutes[0];
      } else {
        const oldIndex = this.openedDetailedRoutes.indexOf(this.lastOpenedDetailedRouteId);
        if (oldIndex >= 0) {
          this.openedDetailedRoutes.splice(oldIndex, 1);
        }
      }

      const id = this.openedDetailedRoutes[0];
      const route = this.routesWithMetadata.find((o) => o.id === id);

      // Pan to the route.
      {
        const latLngs = route.stops.map((o) => o.location);
        this.fitBoundsForLatLngs(latLngs);
        //this.panToGeoJson(pickupLocation.geoJson);
      }
    },
    buildSmallFontClassFunction() {
      return () => {
        return 'is-size-7';
      };
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>

<style lang="css">
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 100%;
  min-width: 550px;
  padding: 5px;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  padding: 5px;
  flex-grow: 2;
  margin: 0px;
  max-height: 98vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

:deep(.table > .table-wrapper) {
  overflow-y: scroll !important;
  height: 100%;
}

.table::-webkit-scrollbar {
  display: none;
}
</style>
