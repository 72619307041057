<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <div style="display: flex; flex-direction: row; vertical-align: middle; justify-content: stretch">
          <div style="flex: 1 20%; display: flex; flex-flow: row wrap; justify-content: center">
            <div style="display: flex; flex-flow: column wrap; justify-content: center">
              <span style="text-align: center; vertical-align: middle">
                <user 
                  v-if="driverStopInfo?.driverStop?.route?.assignedToDriver?.user"
                  style="padding-left: 5px; padding-right: 5px" 
                  :value="driverStopInfo.driverStop.route.assignedToDriver.user" />
                picked up <b> {{ weight }} </b> rolls on
                <b>{{ new Date(driverStopInfo.createdOn).toLocaleDateString() }}</b> at
                {{ new Date(driverStopInfo.createdOn).toLocaleTimeString([], { timeStyle: 'short' }) }}.
              </span>
            </div>
            <slot name="additionalInformation"></slot>
          </div>
          <div
            style="
              flex: 1;
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
              vertical-align: middle;
              padding: 5px;
            "
          >
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
              <span class="is-size-6 has-text-centered has-text-weight-bold">Before</span>
              <clickable-image :image="driverStopInfo.beforeImage" />
            </div>
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
              <span class="is-size-6 has-text-centered has-text-weight-bold">After</span>
              <clickable-image :image="driverStopInfo.afterImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/components/common/User.vue';
import ClickableImage from '@/components/common/ClickableImage.vue';

export default {
  props: {
    driverStopInfo: {
      type: Object,
      required: true,
    },
    weight: {
      type: Number,
      required: true,
    },
  },
  components: { User, ClickableImage },
};
</script>

<style scoped>
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
