<template>
  <div class="main-wrapper">
    test
    <!-- <div style="display: flex; flex-direction: column; flex: 1; overflow-y: none">
      <ul v-for="d in drivers" :key="`selectDriver:${d.id}`">
        <li
          @click="addToSelectedDrivers(d)"
          :style="`${
            selectedDrivers.includes(d)
              ? 'background-color: lightgreen; margin-bottom: 5px'
              : 'margin-bottom: 5px'
          }`"
        >
          <label :for="d.checkboxId" class="name_label" :title="d.title">
            {{ d.user.userEmail }}
          </label>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["routes"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.routes);
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}
</style>
