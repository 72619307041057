<template>
  <pp4-label :latLng="pickupRequest.pointLatLng" 
    :visible="finalVisible"
    :labelText="label" :elementClass="elementClasses" @click="onClick"
    :zIndex="pickupRequest.unitValue"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    />
</template>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject";
import Pp4Label from '@/components/maps/Label';
import * as FormatUtil from '@/services/FormatUtil';
import * as UserUtil from '@/services/UserUtil';
import * as GeoUtil from '@/services/GeoUtil';

export default {
  extends: Pp4MapObject,
  components: { Pp4Label },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    pickupRequest: {
      required: true,
      type: Object,
    },
  },
  data: function () {
    return {
      mapObject: null,
      mouseover: false,
    };
  },
  watch: {
    latLng: "mapReady",
    label: "mapReady",
  },
  mounted() {
  },
  computed: {
    pointGeoJson: vm => GeoUtil.LatLngs.toPointGeoJson(vm.pickupRequest.pointLatLng),
    finalVisible: vm => {
      return vm.visible;
      // return  vm.visible && GeoUtil.GeoJson.booleanContains(
      //  vm.boundsBboxGeoJson, vm.pointGeoJson)
    },
    label: vm => {
      if(! vm.mouseover) {
        const unit = vm.pickupRequest?.unitValue.toString() || "Material N/A";
        return unit;
      }

      const unit = FormatUtil.formatUnitValues([{
        unit: vm.pickupRequest.unit, value: vm.pickupRequest.unitValue
      }]);

      const owner = vm.pickupRequest.pickupLocation?.owner ?
        UserUtil.getFullNameAny(vm.pickupRequest.pickupLocation?.owner)
        : 'Unowned';

      let ret = `
        ${unit} --- ${owner}<br>
        ${vm.pickupRequest.pickupLocation?.name}
      `;

      return ret;
    },
    elementClasses: vm => {
      const ret = ['pickup-request-label'];
      if(vm.mouseover) {
        ret.push('pickup-request-mouseover');
      }

      if(vm.pickupRequest.completedOn) {
        ret.push("has-background-crazy");
      }
      else {
        switch (vm.pickupRequest.bradColor) {
          case 'red':
            ret.push('red-brad-color');
            break;
          case 'green':
            ret.push('green-brad-color');
            break;
          case 'yellow':
            ret.push('yellow-brad-color');
            break;
          case 'orange':
            ret.push('orange-brad-color');
            break;
          default:
            console.warn(`Unexpected Color: ${vm.pickupRequest.bradColor}`);
        }
      }

      return ret.join(' ');
    },
  },
  emits: ['click'],
  methods: {
    onMouseenter() {
      this.mouseover = true;
    },
    onMouseleave() {
      this.mouseover = false;
    },
    onClick() {
      this.$emit("click", this.pickupRequest);
    }
  },
};

</script>

<style lang="css">
.red-brad-color {
  background-color: red;
  color: white;
}

.yellow-brad-color {
  background-color: yellow;
  color: darkgray;
}

.orange-brad-color {
  background-color: orange;
  color: darkgray;
}

.green-brad-color {
  background-color: green;
  color: white;
}

.pickup-request-mouseover {
  font-style: italic;
  width: 20em;
  z-index: 1000 !important;
  opacity: 1.0 !important;
}

.pickup-request-label {
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  border: solid white 1px;
  padding: 0.5em;
  opacity: 0.7;
  text-align: center;
  z-index: 10;
}
</style>
