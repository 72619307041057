import { createActions } from './actions';
import mutations from './mutations';
import getters from './getters';

export function createRouteWizardStore($api) {
  const actions = createActions($api);

  return {
    state: {
      states: [
        'AK',
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VT',
        'WA',
        'WI',
        'WV',
        'WY',
      ],
      loading: false,
      loadingLabel: '',
      points: [],
      workOrders: [],
      workingRoute: { stops: [], directions: null },
      // Paginated data intended for use in buefy table element.
      paginatedRoutes: {
        data: [],
        total: 0,
        loading: true,
        page: 1,
        perPage: 50,
        //sortField: 'vote_count',
        //sortOrder: 'desc',
        //defaultSortOrder: 'desc',
        open: true,
        active: true,
        closed: false,
        scheduledOnBegin: null,
        scheduledOnEnd: null,
        assignedTo: null,
      },
      paginatedSpottedPoints: {
        data: [],
        counties: [], // county breakdown of rollCount
        state: null,
        statuses: ['open', 'routed'],
        createdOnBegin: null,
        createdOnEnd: null,
        total: 0,
        loading: true,
        page: 1,
        perPage: 100,
      },
      paginatedPickupLocations: {
        data: [],
        filters: {
          plasticTypeGroupId: null,
          plasticTypeId: null,
          routed: [],
          owned: [],
          hasImages: [],
          archived: ['exc'],
          bbox: null,
          searchText: null,
          ownerSearchText: null,
          containerSearchText: null,
        },
        total: 0,
        page: 1,
        perPage: 50,
        loading: true,
      },
      paginatedPickupRequests: {
        data: [],
        filters: {
          plasticTypeGroupId: null,
          plasticTypeId: null,
          completed: ["exc"],
          newUsers: ["exc"],
          owned: ["inc"],
          associated: ["inc"],
          routed: [],
          bbox: null,
          searchText: null,
          ownerSearchText: null
        },
        total: 0,
        page: 1,
        perPage: 200,
        loading: true,
        resourceSummary: null,
      },
      warehouses: [],
      drivers: {
        data: [],
        loading: true,
      },
      paginatedDriverStopReviews: {
        data: [],
        filters: {
          reviewed: ['exc'],
          owned: ['inc'],
          ownerSearchText: '',
          driverSearchText: '',
          beginDate: null, endDate: null
        },
        total: 0,
        page: 1,
        perPage: 50,
        loading: false,
      },
      users: {
        data: [],
        loading: false
      },
      plasticTypeGroups: [
      ],
      plasticTypes: [
      ],
      units: [
      ],
      geotabDrivers: [],
      selectedPoint: null,
      selectedPoints: [],
      selectedDriver: null,
      selectedDriverInPlannedMode: false,
      selectedPickupLocation: null,
      loader: false,
      routeMade: false,
      crosshairCursor: false,
      curPos: null,
      layers: {
        pickupRequests: true,
        pickupLocations: true,
        driverStops: true,
        drivers: false,
        regionDriverConfigs: false,
        regionPfpuConfigs: false,
      },
      mapActivityActive: false
    },
    mutations,
    getters,
    actions,
  }
}