import axios from 'axios';
import AxiosRetry from 'axios-retry';
import { mutate as lameMutate } from 'swrv';

import toastService from '@/services/toastService';

let ApiInstance = null

const Base = axios.create({
  baseUrl: '/',
});

export async function getVueAppEnv() {
  return await Base.get("vue-app-env")
}

function InitApiInstance($auth) {
  const API = axios.create({
    baseURL: '/api',
    headers: { 'Content-Type': 'application/json' },
  });

  AxiosRetry(API, { retries: 3 });

  async function updateConfigWithAuth(config) {
    const updatedConfig = config;
    try {
      const jwt = await $auth.getAccessTokenSilently();
      if (jwt) {
        updatedConfig.headers.Authorization = `Bearer ${jwt}`;
      } else {
        console.warn('No Token');
      }

      return updatedConfig;
    } catch (e) {
      console.error(e)
      console.warn('Failed to Add Authentication for: ' + JSON.stringify(config));
    }
  };

  async function handleResponseError(err) {
    if (!err || !err.response) {
      toastService.error();
      return Promise.reject(err);
    }

    if (err.response.status >= 500) {
      toastService.error('Swing and a miss...');
      return Promise.reject(err);
    }

    toastService.error();
    return Promise.reject(err);
  };

  API.interceptors.request.use(updateConfigWithAuth);

  API.interceptors.response.use(
    (response) => response,
    (err) => handleResponseError(err),
  );

  let TouchPromise = null;

  async function wrappedGet(...params) {
    if (!TouchPromise) {
      TouchPromise = new Promise((res, rej) => {
        API.get("/dispatcher/touch").then(res).catch(rej);
      });
    }

    await TouchPromise;

    return await API.get(...params);
  }

  ApiInstance = {
    fetch: async (...params) => {
      const ret = await wrappedGet(...params)
      return ret.data
    },
    getRegionDriverConfigs: () => wrappedGet("/dispatcher/region-driver-configs"),
    getRegionPfpuConfigs: () => wrappedGet("/dispatcher/region-pfpu-configs"),
    postNotification: (params) => API.post("/dispatcher/notifications", params),
    points: (params) => wrappedGet("/dispatcher/spotted-points", { params }),
    routes: (params) => wrappedGet("/dispatcher/routes", { params }),
    updateRoute: (id, value) => API.put(`/dispatcher/routes/${id}`, value),
    updateRouteNotes: (id, value) => API.put(`/dispatcher/routes/${id}/notes`, value),
    updateRouteDirections: (id) => API.put(`/dispatcher/routes/${id}/directions`),
    deleteRouteStop: (routeRowPointer, stopRowPointer) =>
      API.delete(`/dispatcher/routes/${routeRowPointer}/stops/${stopRowPointer}`),
    deleteRouteStops: (routeRowPointer) => API.delete(`/dispatcher/routes/${routeRowPointer}/stops`),
    deleteDropTask: (rowPointer) => API.delete(`/dispatcher/drop-tasks/${rowPointer}`),
    postRouteStop: (routeId, value) => API.post(`/dispatcher/routes/${routeId}/stops`, value),
    routeStopOrderChanged: (rowPointer, value) => API.put(`/dispatcher/routes/${rowPointer}/stops`, value),
    getPickupLocation: (rowPointer) => wrappedGet(`/dispatcher/pickup-locations/${rowPointer}`),
    getPickupLocations: (params) => wrappedGet("/dispatcher/pickup-locations", { params }),
    getPickupLocationPickupRequests: (rowPointer) =>
      wrappedGet(`/dispatcher/pickup-locations/${rowPointer}/pickup-requests`),
    getPickupLocationStops: (rowPointer) =>
      wrappedGet(`/dispatcher/pickup-locations/${rowPointer}/stops`),
    getPickupRequests: (params) => wrappedGet("/dispatcher/pickup-requests", { params }),
    putPickupRequest: (rowPointer, value) =>
      API.put(`/dispatcher/pickup-requests/${rowPointer}`, value),
    putPickupLocation: (value) => API.put(`/dispatcher/pickup-locations/${value.rowPointer}`, value),
    updatePrimaryImage: (model) => API.put(`/dispatcher/pickup-locations/image`, model),
    putDriverStopInfo: (id, value) => API.put(`/dispatcher/driver-stop-info/${id}`, value),
    postPickupLocation: (value) => API.post("/dispatcher/pickup-locations", value),
    postPickupRequest: (pickupLocationRowPointer, value) =>
      API.post(`/dispatcher/pickup-locations/${pickupLocationRowPointer}/pickup-requests`, value),
    mergePickupLocations: (value) => API.post("/dispatcher/pickup-locations-merge", value),
    archivePickupLocation: (value) => API.put("/dispatcher/pickup-locations/archive", value),
    deletePickupLocation: (value) => API.delete(`/dispatcher/pickup-locations/${value}`),
    getWarehouses: () => wrappedGet("/dispatcher/warehouses"),
    images: (params) => wrappedGet(`/dispatcher/images`, { params }),
    getImage: (rowPointer) =>
      wrappedGet(`/dispatcher/images/${rowPointer}`, {
        responseType: "arraybuffer",
      }),
    getConstants: () => wrappedGet("/dispatcher/constants"),
    getDrivers: () => wrappedGet("/dispatcher/drivers"),
    getUsers: () => wrappedGet("/dispatcher/users"),
    getUserBalanceLineItems: (userId) => wrappedGet(`/dispatcher/users/${userId}/balance-line-items`),
    getUserMaxRebateAmount: (userId) => wrappedGet(`/dispatcher/users/${userId}/max-rebate-amount`),
    putUserMaxRebateAmount: (userId, { maxAmount }) => API.put(`/dispatcher/users/${userId}/max-rebate-amount`, { maxAmount }),
    deleteUserMaxRebateAmount: (userId) => API.delete(`/dispatcher/users/${userId}/max-rebate-amount`),
    getDriver: (id) => wrappedGet(`/dispatcher/drivers/${id}`),
    getPlannedRoute: (driverId) => wrappedGet(`/dispatcher/drivers/${driverId}/planned-route`), //will create if necessary
    postWarehouseStop: (userId, warehouseId) =>
      API.post(`/dispatcher/drivers/${userId}/warehouse-stop`, {
        params: { warehouseId },
      }),
    getDriverStopReviews: (params) => wrappedGet(`/dispatcher/driver-stop-reviews`, { params }),
    putDriverStopReview: (driverStopRowPointer, review) =>
      API.put(`/dispatcher/driver-stop-reviews/${driverStopRowPointer}`, review),
    putDriver: (driver) => API.put(`/dispatcher/drivers/${driver.id}`, driver),
    getGeotabDrivers: () => wrappedGet("/dispatcher/geotab/drivers"),
    updatePoint: (model) => API.put(`/dispatcher/spotted-points/${model.id}`, model),
    postVroom: (model) => API.post("/dispatcher/vroom", model),
    postCredit: (model) => API.post("/dispatcher/credits", model),
    deleteCredit: (id) => API.delete(`/dispatcher/credits/${id}`),
    postVroomForWarehouse: (warehouseId) =>
      API.post("/dispatcher/vroom", null, { params: { warehouseId } }),
    getOrders: () => API.get("/dispatcher/ship/orders"),
    getOrderPickupLocations: (model) => API.get("dispatcher/ship/orders/pickup-locations", { params: { ...model } }),
    putOrderPickupLocations: (model) => API.put("dispatcher/ship/orders/pickup-locations", model),
    putDriverStop: (routeId, driverStopRowPointer, model) => API.put(`dispatcher/routes/${routeId}/stops/${driverStopRowPointer}`, model)
  }
}

export function createApi($auth) {
  InitApiInstance($auth)

  return {
    install(app) {
      app.config.globalProperties.$api = ApiInstance
      app.provide('$api', ApiInstance)

      app.provide("$mutate", (key) => {
        lameMutate(key, ApiInstance.fetch(key))
      })
    }
  }
}

export function useApi() {
  return ApiInstance
}

