<template>
  <div class="route-edit-wrapper">
    <loading :active="routeSwr.isValidating.value"/>

    <div ref="stops">
      <div class="mb-2" style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        ">
        <span class="is-size-6" v-if="remainingPickupMaterialSummary">Remaining: {{ remainingPickupMaterialSummary
          }}.</span>
        <directions v-if="route?.directions" v-model="route.directions" class="level-left is-size-6" />
        <div v-if="hasActiveRouteWithStops">
          <!-- add info icon here -->
          <b-button size="is-small" type="is-light is-warning" outlined label="Dump Active Route"
            @click="setRouteAsDumped"></b-button>
        </div>
      </div>

      <VueDraggable v-model="data.updatedStops" v-if="!readonly" @end="dragEnd" class="route-edit-stops-div">
        <div v-for="(stop, index) in data.updatedStops" :key="stop.rowPointer">
          <route-stop v-if="!stop.dropRequestId" :readonly="readonly" :stop="stop" @remove="deleteStop(stop)" />
          <route-stop-drop-request v-else :stop="stop" @remove="deleteStop" />
        </div>
      </VueDraggable>
      <template v-else>
        <div v-for="(stop, index) in data.updatedStops" :key="'routestop:' + index">
          <route-stop :stop="stop" @remove="deleteStop(stop)" />
        </div>
      </template>
    </div>
    <div v-if="!readonly" style="
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
      ">
      <b-button class="is-small mr-3" :disabled="data.updatedStops.length === 0" @click="updateDirections">Calc.
        Directions
      </b-button>
      <b-button class="ml-2 is-small is-danger is-clickable" @click="deleteStops">Clear Route</b-button>
    </div>
    <RouteMapComponent v-if="route" :route="route" :warehouse="warehouse" :selected="true" :colorScheme="colorScheme" />
  </div>
</template>

<script setup>
import { reactive, computed, inject, watch } from "vue";
import { useStore } from "vuex";
import { VueDraggable } from "vue-draggable-plus";
import useSwr from 'swrv'

import RouteStop from "./RouteStop.vue";
import RouteStopDropRequest from "./RouteStopDropRequest.vue";
import RouteMapComponent from "@/components/maps/Route";
import Directions from "@/components/common/Directions.vue";
import * as FormatUtil from "@/services/FormatUtil";


const props = defineProps({
  rowPointer: {
    type: String,
    required: true,
  },
  warehouse: {
    type: Object,
    required: false,
    default: null,
  },
  colorScheme: {
    type: Object,
    required: false,
    default: null,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['nope','routeDumped'])

const data = reactive({
  updatedRouteDate: null,
  updatedRouteName: "",
  updatedStops: [],
})

const $store = useStore()
const $api = inject("$api")

const routeSwr = useSwr(`/dispatcher/routes/${props.rowPointer}`, $api.fetch, {
  revalidateOnFocus: false
})

const route = computed(() => {
  return routeSwr.data.value ?? null
})

watch(route, () => {
  data.updatedRouteName = route.value?.routeName ?? null;
  data.updatedRouteDate = route.value?.dateScheduledOn ?? null
  data.updatedStops = route.value?.stops?.slice() ?? []
})

const hasActiveRouteWithStops = computed(() => {
  const routeVal = route.value

  if (!routeVal) {
    return false;
  }

  return (
    !routeVal.completedOn &&
    routeVal.stops.some((s) => {
      if (s.info) return s.info != null;
      if (s.dropStopInfo) return s.dropStopInfo != null;
    })
  );
})

const remainingPickupMaterialSummary = computed(() => {
  const stops = route.value?.stops || [];

  const materialUnitValues = [];

  stops.forEach((stop) => {
    const pickupRequests = stop?.pickupLocation?.pickupRequests;
    if (!pickupRequests) {
      return;
    }

    pickupRequests.forEach((r) => {
      if (r.completedOn) {
        return;
      }

      materialUnitValues.push({
        unit: r.unit,
        value: r.unitValue,
        plasticTypeGroup: r.plasticTypeGroup,
        plasticType: r.plasticType,
      });
    });
  });

  if (!materialUnitValues.length) {
    return null;
  }

  return FormatUtil.formatMaterialUnitValues(materialUnitValues);
})

async function dragEnd() {
  const updatedStopsSimplified = data.updatedStops.map((stop, i) => {
    return { driverStopRowPointer: stop.rowPointer, newStopOrder: i + 1 };
  });

  await $store.dispatch("updateRouteStopOrder", {
    rowPointer: route.value.rowPointer,
    updatedStopsSimplified,
  });
}

async function updateDirections() {
  $store.dispatch("updateRouteDirections", { id: route.value.id });
}

function setRouteAsDumped(){
  emit("routeDumped");
}

async function deleteStop(stop) {
  if(! route.value) {
    return
  }

  const index = route.value.stops.indexOf(stop);

  if (index < 0) {
    return;
  }

  await $store.dispatch("deleteRouteStop", {
    routeRowPointer: route.value.rowPointer,
    stopRowPointer: stop.rowPointer,
  });
}

async function deleteStops() {
  await $store.dispatch("deleteRouteStops", { rowPointer: route.value.rowPointer });
}
</script>

<style scoped>
.route-edit-wrapper {
  overflow-y: auto;
  padding: 0.5em;
  margin: 0px;
}

.route-edit-stops-div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  row-gap: 1em;
}

.route-edit-stops-div div {
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
}
</style>
