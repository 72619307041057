<template>
  <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">

    <span v-if="viewportFollow">Following Viewport...</span>
    <b-button v-else size="is-small" @click="viewportFilterClicked" title="Filter to current map viewport">
      <span>Viewport</span>
    </b-button>
    <b-button size="is-small" v-if="value && !viewportFollow" @click="turnOffFilter">
      <b-icon pack="mdi" size="is-small" icon="close" />
    </b-button>

    <!-- This lock system needs refactoring -->
    <b-checkbox-button v-model="viewportFollow" size="is-small" native-value="true"
      title="Filter to map viewport and follow">
      <b-icon pack="mdi" size="is-small" :icon="viewportFollow ? 'lock' : 'lock-open-variant'" />
    </b-checkbox-button>
  </div>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import FilterBounds from '@/components/maps/FilterBounds.vue';

export default {
  extends: Pp4MapObject,
  components: {FilterBounds},
  props: ['value'],
  data: () => {
    return {
      viewportFollow: false,
      lastBoundsBboxChangeEmitted: null
    };
  },
  computed: {
  },
  emits: ["change"],
  watch: {
    // bboxFilterActive: 'onBboxFilterModeChange',
    viewportFollow: 'onViewportFollowChange',
    boundsBbox: 'onBboxChange'
  },
  methods: {
    viewportFilterClicked() {
      if(this.boundsBbox === this.lastBoundsBboxChangeEmitted) {
        this.emitChange(null);
      }
      else {
        this.emitChange(this.boundsBbox);
      }
    },
    onViewportFollowChange() {
      if (this.viewportFollow) {
        this.emitChange(this.boundsBbox);
      }
      else {
        this.emitChange(null);
      }
    },
    onBboxChange() {
      if (!this.viewportFollow) {
        return
      }

      this.emitChange(this.boundsBbox);
    },
    emitChange(val) {
      if(val === this.lastBoundsBboxChangeEmitted) {
        this.lastBoundsBboxChangeEmitted = null
        // console.log("! EMITTING", null)
        this.$emit('change', null);
        return
      }

      this.$emit('change', val);
      this.lastBoundsBboxChangeEmitted = val
    },
    turnOffFilter() {
      this.$emit("change", null);
      this.viewportFollow = false;
    },
    // onBboxChange() {
    //   console.log("! BBOX CHANGED", this.boundsBbox)
    //   if (this.viewportFollow) {
    //     this.viewportBboxFilter = this.boundsBbox;
    //     this.$emit('change', this.viewportBboxFilter);
    //     return
    //   }

    //   // if(this.bboxFilterActive) {
    //   //   return
    //   // }

    //   // this.viewportBboxFilter = this.boundsBbox;
    //   // this.$emit('change', this.viewportBboxFilter);
    // },
    // onBboxFilterModeChange() {
    //   console.log("! FILTER MODE CHANGED", this.bboxFilterActive, this.viewportFollow)
    //   if (this.viewportFollow) {
    //     this.viewportBboxFilter = this.boundsBbox;
    //     this.$emit('change', this.viewportBboxFilter);
    //     return
    //   }

    //   // if (this.bboxFilterActive) {
    //   //   this.viewportBboxFilter = this.boundsBbox;
    //   //   this.$emit('change', this.viewportBboxFilter);
    //   // }
    //   // else if(viewportFollow) {
    //   //   this.viewportBboxFilter = null;
    //   //   this.$emit('change', this.viewportBboxFilter);
    //   // }
    // }
  },
};
</script>

<style></style>
