<!--
    A filter that assumes inclusive behavior by default but excludes values that do not satisfy checked properties when at least one property is selected.
-->

<template>
  <b-field :label="name" label-position="on-border">
    <b-checkbox-button :disabled="loading" class="filter-button" v-model="checkboxGroup" native-value="inc"
      size="is-small" type="is-success is-light">
      <b-icon custom-size="mdi-18px" icon="checkbox-blank-circle"></b-icon>
    </b-checkbox-button>

    <b-checkbox-button :disabled="loading" class="filter-button" v-model="checkboxGroup" native-value="exc"
      size="is-small" type="is-danger is-light">
      <b-icon custom-size="mdi-18px" icon="checkbox-blank-circle-outline"></b-icon>
    </b-checkbox-button>
  </b-field>
</template>

<script setup>
import { defineEmits, onMounted, watch } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  value: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  }
})

const checkboxGroup = defineModel()

onMounted(() => {
  checkboxGroup.value = props.value
})

const $emit = defineEmits(['change'])

watch(checkboxGroup, (newValue, oldValue) => {
  if (newValue.length == 2) {
    const fixedValue = newValue.filter((x) => !oldValue.includes(x));
    if(fixedValue.length < newValue.length) {
      checkboxGroup.value = fixedValue
    }
  }
  else {
    $emit('change', newValue);
  }
})
</script>

<style lang="css" scoped>
.filter-button .b-checkbox {
  padding-left: 14px;
  padding-right: 14px;
}

/* only way I could override Buefy */
.button .icon:first-child:not(:last-child) {
  margin-left: 0.05em;
  margin-right: 0.05em;
}

.field:not(:last-child) {
  margin: 0px;
  margin-bottom: 0px !important;
}
</style>
