<template>
  <div>
    <map-controls position="TOP_CENTER">
      <div
        style="position: absolute; top: 60px; right: 80px; display: flex; flex-direction: row; background-color: gray; padding: 5px">
        <b-button size="is-small" icon-left="check" class="is-success mr-2" :disabled="saveDisabled" @click="save" />
        <b-button size="is-small" icon-left="cancel" class="has-text-white is-danger" type="is-danger"
          title="Cancel add pickup location" @click="cancel" />
      </div>
    </map-controls>

    <pp4-poly v-if="previewPath" :path="previewPath" :clickable="false" strokeColor="hsla(118, 67%, 50%, 0.25)"
      fillColor="hsla(118, 67%, 50%, 0.15)" />

    <pp4-poly v-if="finalPath" :path="finalPath" :clickable="false" strokeColor="hsla(118, 67%, 50%, 0.5)"
      fillColor="hsla(118, 67%, 50%, 0.5)" />
  </div>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import Pp4Poly from '@/components/maps/Poly';
import ContextMenu from '@/components/maps/ContextMenu';
import MapControls from '@/components/maps/MapControls';

import { GeoJson, LatLngs } from '@/services/GeoUtil';
import { LatLng } from '@/utils/Dto';

export default {
  extends: Pp4MapObject,
  components: {
    ContextMenu,
    Pp4Poly,
    MapControls
  },
  props: {
    create: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      location: null,
      previewLocation: null,
    };
  },
  computed: {
    saveDisabled: (vm) => !vm.location,
    previewPath: (vm) => vm.buildPreview(vm.previewLocation),
    finalPath: (vm) => vm.buildPreview(vm.location),
  },
  mounted() {
    this.addMapListener('click', (e) => this.handleClick(e));
    this.addMapListener('mousemove', (e) => this.handleMousemove(e));
  },
  emits:["done"],
  methods: {
    buildPreview(latLng) {
      if (!latLng) {
        return null;
      }

      const pointGeo = LatLngs.toPointGeoJson(latLng);

      const circleGeo = GeoJson.circle(pointGeo, 100, {
        units: 'meters',
        steps: 16,
      });

      return GeoJson.toLatLngs(circleGeo);
    },
    handleClick(e) {
      let googleLatLng = e.latLng;
      let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng());

      this.location = latLng;
      this.previewLocation = latLng;
    },
    handleMousemove(e) {
      let googleLatLng = e.latLng;
      let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng());

      this.previewLocation = latLng;
    },
    async save() {
      if (this.create) {
        await this.$store.dispatch('postPickupLocation', this.location);
        this.$emit('done');
      }

      if (!this.create) {
        this.$emit('done', this.location)
      }

    },
    cancel() {
      this.$emit('done');
    },
  },
};
</script>
