<template>
  <div>
    <Pp4Label v-if="warehouseLocation" :latLng="warehouseLocation" :labelText="warehouseHtml"
      elementClass="warehouse-map-object" />
  </div>
</template>

<script>
import Pp4Label from '@/components/maps/Label';

export default {
  components: {
    Pp4Label,
  },
  data() {
    return {};
  },
  props: {
    warehouse: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    warehouseLocation: (vm) => vm.warehouse?.location,
    warehouseHtml: (vm) => {
      const ret = document.createElement('div')
      ret.style = "color: white; font-size: small; text-align: center;"
      
      ret.innerHTML = `${vm.warehouse.name ?? "Unnamed"}<br>🏭`

      return ret.outerHTML
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="css">
.warehouse-map-object {
  position: absolute;
  cursor: pointer;
  font-weight: bold;
  font-size: large;
  transform: translateX(-50%) translateY(-50%);
  background: white;
  padding: 0.5rem;
  border-radius: 1.5rem;
  background-color: rgba(15, 15, 55, 0.35);
}
</style>
