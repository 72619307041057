<template>
    <datalist id="global-users-datalist">
        <option disabled value="">Please select one</option>
        <option value="">None</option>
        <option v-for="(user, index) in users" :key="index" :value="user.id">
            ({{user.id}}) {{ getFullNameAny(user) }} ({{user.userId}})
        </option>
    </datalist>
</template>

<script setup>
import { computed, inject } from 'vue';
import useSwr from 'swrv'
import LocalStorageCache from 'swrv/dist/cache/adapters/localStorage'

import { getFullNameAny } from "@/services/UserUtil";

const $api = inject('$api')

const swrUrl = computed(() => {
    return `/dispatcher/users?all=true`
})

const usersSwr = useSwr(swrUrl, $api.fetch, {
    cache: new LocalStorageCache('swrv'),
    revalidateOnFocus: false
})

const users = computed(() => {
    if(usersSwr.isValidating.value) {
        return []
    }

    return usersSwr.data?.value?.results || []
})
</script>

<style scoped lang="css"></style>