<template>
  <Pp4Marker :location="spottedPoint.pointLatLng" v-bind="markerOptions" />
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import Pp4Marker from '@/components/maps/Marker';

import { getFullNameAny } from "../../services/UserUtil";

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Marker,
  },
  props: {
    spottedPoint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    markerOptions: (vm) => {
      const createdOn = vm.spottedPoint.createdOn;
      let title = `Spotted ${new Date(createdOn).toLocaleString()}`;
      if (vm.spottedPoint.createdBy) {
        title += `\n${getFullNameAny(vm.spottedPoint?.createdBy)}`;
      }

      return {
        title,
        label: '🔭',
        labelClass: 'spotted-point-marker',
        clickable: true
      };
    },
  },
  watch: {},
  mounted() {
  },
  methods: {},
};
</script>

<style lang="css">
.spotted-point-marker {
  position: relative;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  white-space: pre-wrap;
  cursor: pointer;
}
</style>
