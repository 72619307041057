/* eslint-disable no-underscore-dangle */
import { useApi } from "@/services/apiService";

export default {
  regionDriverConfigs: () => async () => (await useApi().getRegionDriverConfigs())?.data || [],
  regionPfpuConfigs: () => async () => (await useApi().getRegionPfpuConfigs())?.data || [],
  images: () => async (filters) => await useApi().images(filters),
  image: () => async (rowPointer) => await useApi().getImage(rowPointer),
  points: (state) => state.points,
  fullPickupLocation: () => async (rowPointer) => {
    const ret = (await useApi().getPickupLocation(rowPointer))?.data;
    if (!ret) {
      return null;
    }

    ret.driverStops = (await useApi().getPickupLocationStops(rowPointer))?.data?.results;
    ret.pickupRequests = (await useApi().getPickupLocationPickupRequests(rowPointer))?.data?.results;

    return ret;
  },
  userBalanceLineItems: () => async (userId) => {
    const ret = (await useApi().getUserBalanceLineItems(userId))?.data;

    if (!ret) {
      return [];
    }

    return ret;
  },
  userMaxRebateAmount: () => async (userId) => {
    return (await useApi().getUserMaxRebateAmount(userId))?.data;
  },
  pickupLocationStops: () => async (rowPointer) =>
    await useApi().getPickupLocationStops(rowPointer),
  paginatedPickupLocations: (state) => state.paginatedPickupLocations,
  selectedPoint: (state) => state.selectedPoint,
  selectedPoints: (state) => state.selectedPoints,
  query: (state) => state.query,
  drivers: (state) => state.drivers,
  selectedDriver: (state) => state.selectedDriver,
  driversById: (state) => {
    const ret = {};
    state.drivers.data.forEach((driver) => {
      ret[driver.id] = driver;
    });
    return ret;
  },
  driverById: (state, getters) => (id) => {
    return getters.driversById[id];
  },
  driversByAuth0UserId: (state) => {
    const ret = {};
    state.drivers.data.forEach((driver) => {
      ret[driver.user.userId] = driver;
    });
    return ret;
  },
  driverByAuth0UserId: (getters) => (auth0UserId) => {
    return getters.driversByAuth0UserId[auth0UserId];
  },
  loader: (state) => state.loader,
  filter: (state) => state.filter,
  routeMade: (state) => state.routeMade,
  crosshairCursor: (state) => state.crosshairCursor,
  curPos: (state) => state.curPos,
  layers: (state) => state.layers,
  dropRequests: (state) => state.dropRequests,
  paginatedRoutes: (state) => state.paginatedRoutes
};
