<template>
   <div>
    <Pp4Poly v-for="r in polys"
      :key="r.id" :path="r.path"
      strokeColor="#37ffdb"
      fillColor="rgba(22, 100, 86, 0.4)"
      :infoWindowContent="r.infoWindowContent"/>
   </div>
 </template>
 
 <script>
 import Pp4MapObject from "@/components/maps/Pp4MapObject";
 import Pp4Poly from "@/components/maps/Poly";
 import { GeoJson } from '@/services/GeoUtil';
 
 export default {
   extends: Pp4MapObject,
   components: {
      Pp4Poly,
   },
   data: function () {
     return {
      data: []
     };
   },
   computed: {
    polys: vm => {
      return vm.data.map(r => {
        const configDescriptions = r.configs.map(c => {
          return `${c.plasticTypeGroup?.name}${c.plasticType ? '/' + c.plasticType.name : ''}
            &nbsp;${c.collectedUnit?.plural},${c.remainingUnit?.plural}<br>
            `;
        }).join('\n');

        const infoWindowContent = `<b><center>${r.name}</center></b><br>
          ${configDescriptions}
        `

        return {...r,
          path: GeoJson.toLatLngs(r.geo),
          infoWindowContent
        }
      });
    }
   },
   methods: {
    async fetchThemData() {
      const result = await this.$store.getters['regionDriverConfigs']();
      this.data = result?.regions || [];
    }
   },
   mounted() {
    this.fetchThemData();
   }
 };
 </script>
 
 <style scoped>
 </style>
 