<template>
  <div class="wrapper">
    <Table :loading="ordersSwr.isValidating.value" :search="true" @search="searchTextChanged($event)" :controls="true"
      :data="tableData" detailKey="orderNumber" :columns="data.columns" @rowOpened="rowOpenedHandler">
      <template #controls>
        <div class="mb-1"
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
          <span style="font-weight: bold;">Last refreshed at <span>{{ new Date().toLocaleString() }}</span></span>
          <b-button @click="refreshOrders" size="is-small" icon-right="refresh" title="Refresh Table" />
        </div>
      </template>
      <template #orderDate="{ value }">
        <span style="white-space: nowrap">{{ new Date(value.orderDate).toLocaleDateString() }}</span>
      </template>


      <template #detail="{ value }">
        <PickupLocationFinder :value="value" @pickupLocationFound="setPickupLocationId" />

        <SytelineOrderCard :value="value" :pickupLocationId="data.pickupLocationId" />
      </template>

    </Table>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { reactive, computed, inject } from "vue";
import useSwr from 'swrv'

import Table from "@/components/common/Table";
import SytelineOrderCard from '@/views/RouteWizard/components/cards/SytelineOrderCard'
import PickupLocationFinder from "@/views/RouteWizard/components/PickupLocationFinder";

const data = reactive({
  isLoading: false,
  rows: [],
  selectedOrderNumber: null,
  pickupLocationId: null,
  query: null,
  columns: [
    { field: "orderNumber", label: "Order", sortable: true },
    { field: "shipTo.name", label: "Ship To", sortable: true },
    { field: "dueDate", label: "Due Date", sortable: true },
    { field: "orderStats.itemsLeftToShip", label: "LTS", sortable: true },
    { field: 'warehouse', label: 'WHSE' }
  ],
})

const $store = useStore()
const $api = inject("$api")

const selectedDriver = $store.state.selectedDriver

const ordersSwr = useSwr("/dispatcher/ship/orders", $api.fetch, {
  revalidateOnFocus: false
})

const tableData = computed(() => {
  if (ordersSwr.data.value instanceof Array) {
    const withMetaData = ordersSwr.data.value.map(o => {
      const orderStats = o.sytelineOrderItems.reduce((acc, curr) => {
        acc.itemsLeftToShip = acc.itemsLeftToShip + curr.qtyOrdered - curr.qtyShipped;
        acc.totalItemsOrdered = acc.totalItemsOrdered + curr.qtyOrdered;
        acc.totalItemsShipped = acc.totalItemsShipped + curr.qtyShipped;
        return acc
      }, {
        itemsLeftToShip: 0,
        totalItemsOrdered: 0,
        totalItemsShipped: 0
      });

      let dueDate = 'none';
      if (o.sytelineOrderItems.length > 0) {
        // TODO: maybe better to grab the earliest date in the list?
        dueDate = new Date(o.sytelineOrderItems[0].dueDate).toLocaleDateString()
      }

      return { ...o, orderStats, dueDate }
    })

    if (data.query) {
      return withMetaData.filter((o) => {
        const { shipTo, orderNumber } = o;
        return `${shipTo.name} ${orderNumber}`.toLowerCase().includes(data.query)
      })
    }

    return withMetaData
  }

  return []
})

function searchTextChanged(query) {
  if (query.length === 0) {
    data.query = null
  }
  data.query = query.toLowerCase();
}

function rowOpenedHandler(newVal) {
  data.selectedOrderNumber = newVal
}

function setPickupLocationId(event) {
  data.pickupLocationId = event.id;
}

function refreshOrders() {
  ordersSwr.mutate()
}
</script>

<style lang="css" scoped>
.wrapper {
  max-width: 45vw;
  overflow: auto;
  padding: 0px;
  position: relative;
  height: 100%;
  display: block;
}

.wrapper .b-table {
  width: 100%;
  height: 100%;
}

.filters_columns {
  display: flex;
  flex-direction: column;
}

.filters_rows {
  display: flex;
  flex-direction: row;
}
</style>