<template>
    <b-loading :model-value="props.active" :is-full-page="false" />
</template>

<script setup>
const props = defineProps({
    active: {
        type: Boolean,
        required: true
    }
})
</script>
