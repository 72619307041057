<!--
  A form for creating driver stop review entries from a driver stop
-->

<template>
  <div class="driver-stop-review-edit-wrapper">
    <driver-stop-summary class="mt-1" :driverStopInfo="value" :weight="value.weight">
      <template #additionalInformation>
        <!-- Pickup Location Summary -->
        <div v-if="pickupLocation">
          <div style="display: flex; flex-direction: column">
            <pickup-location-card :value="pickupLocation" />
            <div v-if="value.notes && value.notes.length > 0" class="subform">
              <ToggleNotes :data="value.notes" title="Driver Notes" />
            </div>
            <div class="subform">
              <ToggleNotes
                :data="value.reviewerNotes"
                title="Reviewer Notes"
                editable
                @saveEvent="saveHandler"
              />
            </div>
          </div>
        </div>
        <b-skeleton v-else height="100px" />
      </template>
    </driver-stop-summary>

    <!-- Roll Count Compare Form -->
    <roll-count-compare-card
      class="mt-2"
      :driverItems="driverItems"
      :cachedItems="value.cachedItems"
      :reviewItems="value.reviewItems"
      :driverStopRowPointer="value.driverStopRowPointer"
      @onPut="$emit('onPut')"
    />

    <pickup-requests-list
      v-if="pickupLocation?.pickupRequests"
      class="mt-2"
      :requests="pickupLocation.pickupRequests"
      :activeFrame="value.activeFrame"
    />

    <pickup-location
      v-if="pickupLocation"
      :pickupLocation="pickupLocation"
      :visible="true"
      :selected="true"
    />
  </div>
</template>

<script>
import PickupLocationCard from './cards/PickupLocationCard.vue';
import PickupRequestsList from '@/components/maps/PickupRequestsList';
import DriverStopSummary from './DriverStopSummary.vue';
import PickupLocation from '@/components/maps/PickupLocation.vue';
import RollCountCompareCard from './cards/RollCountCompareCard.vue';
import ToggleNotes from '@/components/common/ToggleNotes.vue';

class PickupItem {
  constructor(plasticTypeId, rollCount) {
    this.plasticTypeId = plasticTypeId;
    this.rollCount = rollCount;
  }
}

function ReviewItemToPickupItem(reviewItem) {
  return new PickupItem(reviewItem.plasticTypeId, reviewItem.rollCount);
}

function PickupItemSort(a, b) {
  return a.plasticTypeId - b.plasticTypeId;
}

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    PickupLocationCard,
    PickupRequestsList,
    DriverStopSummary,
    PickupLocation,
    RollCountCompareCard,
    ToggleNotes,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      driverItems: [],
      pickupLocation: null,
    };
  },
  computed: {
  },
  watch: {
    value: 'valueChanged',
  },
  mounted() {
    this.valueChanged();
  },
  emits:['onPut'],
  methods: {
    async saveHandler(event) {
      await this.$store.dispatch('updateDriverStopInfoNotes', {
        id: this.value.driverStopRowPointer,
        notes: event,
      });
      await this.$store.dispatch('updatePaginatedDriverStopReviews');
    },
    async valueChanged() {
      const info = this.value;
      const rollCountsByPlasticTypeId = {};

      // set array of roll count by plastic id
      for (let i = 0; i < 7; i++) {
        const plasticTypeId = info[`plasticType${i}`];
        const rollCount = info[`weight${i}`];
        if (rollCount === 0) {
          continue;
        }

        if (plasticTypeId in rollCountsByPlasticTypeId) {
          rollCountsByPlasticTypeId[plasticTypeId] += rollCount;
        } else {
          rollCountsByPlasticTypeId[plasticTypeId] = rollCount;
        }
      }

      this.driverItems = [];

      // set pickup items for driver items
      for (let plasticTypeId in rollCountsByPlasticTypeId) {
        this.driverItems.push(new PickupItem(Number.parseInt(plasticTypeId), rollCountsByPlasticTypeId[plasticTypeId]));
      }

      // if there are not existing cached items
      if (!this.value.cachedItems || this.value.cachedItems?.length < 1) {
        this.value.cachedItems = [];

        let hasReviewItems = this.value.reviewItems.length != 0;

        // if there are review items
        if (hasReviewItems) {
          // set pickup items from existing review items in DB
          // replace with array.map probably
          this.value.reviewItems.forEach((element) => {
            this.value.cachedItems.push(ReviewItemToPickupItem(element));
          });
        }
        // if there are no driver items, create one cached item
        else if (this.driverItems.length == 0) {
          this.value.cachedItems.push(new PickupItem(1, 0));
        }
        // if there are driver items
        else {
          // use the driver items
          // one-level cloning
          // careful about this in the future.. maybe should make where we set
          // driver items in the future a property / method and then reference that operation
          // to assign this value
          this.value.cachedItems = this.driverItems.map((driverItem) => Object.assign({}, driverItem));
        }
      }

      this.driverItems.sort(PickupItemSort);
      this.value.cachedItems.sort(PickupItemSort);

      this.pickupLocation = await this.$store.getters.fullPickupLocation(this.value.driverStop.pickupLocation.rowPointer);
    },
  },
};

export { PickupItem };
</script>

<style lang="css" scoped>
.driver-stop-review-edit-wrapper {
  max-width: 45vw;
  display: flex;
  flex-direction: column;
  padding: 5px !important;
}
</style>
