<template>
  <template v-if="detailedMode">
    <driver-stop v-for="o in stopsWithMetadata" :key="o.key" :value="o" :fg="o.fg" :bg="o.bg" @click="emitClick" />
  </template>

  <Warehouse v-if="warehouse" :warehouse="warehouse" />
</template>

<script setup>
import { reactive, toRaw, watch, onUnmounted, computed } from 'vue'

import { useMaps } from '@/composables/useMaps'
import Warehouse from '@/components/maps/Warehouse';
import DriverStop from '@/components/maps/DriverStop';

import { LatLngs, GeoJson } from '@/services/GeoUtil';

const $emit = defineEmits(['click'])

const data = reactive({
  polylineFgMapObject: null,
  polylineBgMapObject: null
})

const props = defineProps({
  route: {
    type: Object,
    required: true,
  },
  warehouse: {
    type: Object,
    default: null,
    required: false,
  },
  detailedMode: {
    type: Boolean,
    default: true,
    required: false,
  },
  colorScheme: {
    type: Object,
    required: false,
    default: null
  },
  selected: {
    type: Boolean,
    required: false,
    default: false
  },

  //events
  onClick: {
    required: false
  }
})

const maps = useMaps()

const stopsWithMetadata = computed(() => {
  const vals = props.route.stops;

  return vals.map((val, i) => {
    const key = val.rowPointer

    const done = val.info;

    const labelText = `${done ? "🗸" : ""} ${val.stopOrder.toString()}`;
    const fg = props.colorScheme?.fg.toHexString() || 'white';
    const bg = props.colorScheme?.bg.toHexString() || 'black';
    const offsetInMeters = i % 2 === 0 ? i * 5 : i * -5;

    return { ...val, key, labelText, fg, bg, offsetInMeters };
  });
})

function emitClick() {
  $emit('click', props.route)
}

function setPolylineFgMapObject(val) {
  toRaw(data.polylineFgMapObject)?.setMap(null)
  data.polylineFgMapObject = val

  if (props.onClick) {
    val.addListener('click', emitClick)
  }
}

function setPolylineBgMapObject(val) {
  toRaw(data.polylineBgMapObject)?.setMap(null)
  data.polylineBgMapObject = val

  if (props.onClick) {
    val.addListener('click', emitClick)
  }
}

function updateMapObjectsWithoutDirections() {
  const latLngs = props.route.stops.map((s) => s.location);

  const fg = props.colorScheme?.fg.toHexString() || 'white';
  const bg = props.colorScheme?.bg.toHexString() || 'black';

  setPolylineFgMapObject(new maps.api.Polyline({
    map: maps.map,
    path: latLngs,
    geodesic: true,
    strokeColor: props.selected
      ? fg : bg,
    strokeOpacity: props.selected
      ? 0.5 : 0.25,
    strokeWeight: props.selected
      ? 2 : 4,
    zIndex: 1
  }))

  if (props.selected
  ) {
    setPolylineBgMapObject(new maps.api.Polyline({
      map: maps.map,
      path: latLngs,
      geodesic: true,
      strokeColor: bg,
      strokeOpacity: 1.0,
      strokeWeight: 5,
      zIndex: 0
    }))
  }
}

function updateMapObjectsFromDirections(routes) {
  const route = routes[0];
  const legs = route?.legs || [];
  const fg = props.colorScheme?.fg.toHexString() || 'white';
  const bg = props.colorScheme?.bg.toHexString() || 'black';

  const finalLatLngs = []

  legs.forEach((leg, i) => {
    let stopProps =
      i < stopsWithMetadata.value.length
        ? stopsWithMetadata.value[i]
        : {
          color: 'yellow',
          offsetInMeters: 0,
        };

    const steps = leg?.steps || [];

    steps.forEach((step) => {
      const polyline = step.polyline;
      let googleLatLngs = maps.api.geometry.encoding.decodePath(polyline.points);

      if (googleLatLngs.length > 255) {
        const tmpLatLngs = googleLatLngs.map((ll) => ({ lat: ll.lat(), lng: ll.lng() }));
        const ls = LatLngs.toLineStringGeoJson(tmpLatLngs);
        GeoJson.simplify(ls, { tolerance: 0.0001, mutate: true });
        let newLatLngs = GeoJson.toLatLngs(ls);
        googleLatLngs = newLatLngs.map((ll) => new maps.api.LatLng(ll.lat, ll.lng));
      }

      if (googleLatLngs.length < 2) {
        return;
      }

      googleLatLngs.forEach((ll) => {
        ll.lat = ll.lat();
        ll.lng = ll.lng();
      });

      const ls = LatLngs.toLineStringGeoJson(googleLatLngs);

      const offsetLs = GeoJson.lineOffset(ls, stopProps.offsetInMeters, { units: 'meters' });

      const offsetLatLngs = GeoJson.toLatLngs(offsetLs);

      finalLatLngs.push(...offsetLatLngs);
    });
  });

  setPolylineFgMapObject(new maps.api.Polyline({
    map: maps.map,
    path: finalLatLngs,
    geodesic: true,
    strokeColor: props.selected
      ? fg : bg,
    strokeOpacity: props.selected
      ? 0.5 : 0.25,
    strokeWeight: props.selected
      ? 2 : 4,
    zIndex: 1
  }))

  if (props.selected
  ) {
    setPolylineBgMapObject(new maps.api.Polyline({
      map: maps.map,
      path: finalLatLngs,
      geodesic: true,
      strokeColor: bg,
      strokeOpacity: 1.0,
      strokeWeight: 5
    }))
  }
}

function updateMapObjects() {
  const directions = props.route.directions;

  const routes = directions?.resultJson?.routes;

  if (!routes?.length) {
    updateMapObjectsWithoutDirections()
  }
  else {
    updateMapObjectsFromDirections(routes);
  }
}

function clearMapObjects() {
  toRaw(data.polylineBgMapObject)?.setMap(null);
  toRaw(data.polylineFgMapObject)?.setMap(null);
  data.polylineBgMapObject = null
  data.polylineFgMapObject = null
}

watch(props, updateMapObjects, { immediate: true });

onUnmounted(clearMapObjects)
</script>

<style lang="css"></style>
