<template>
  <div style="cursor: grab; padding: 10px;" :class="orderComplete ? 'card success' : 'card'">
    <div class="card-header" style="flex-direction: row; justify-content: flex-end;" v-if="stop.dropStopInfo">
      <span class="mr-1">Delivered {{ stop.dropStopInfo.amount }} on</span>
      <span class="mr-1">{{ new Date(stop.dropStopInfo.createdOn).toLocaleString() }}</span>
      <i class="mdi mdi-check-circle" aria-hidden="true"></i>
    </div>

    <div style="display: flex; flex-direction: row; justify-content: space-between; flex: 1;">
      <!-- <div>
        <span class="is-size-7 has-text-weight-bold"> Order #: {{ orderNumber }}</span>
      </div> -->
      <div>
        <span class="is-size-6 has-text-weight-bold mr-1">{{ stop.stopOrder }}</span>
        <span class="is-size-7 has-text-weight-medium">{{ pickupLocationAddress }}</span>
      </div>

      <PanToButton :location="stop.location" />
    </div>

    <div style="display: flex; flex-direction: column; flex: 1;">
      <div v-for="(item, index) in items" class="mt-1" style="display: flex; flex-direction: column; flex: 1;">
        <div style="display: flex; flex-direction: row; flex: 1; justify-content: space-between; align-items: center">
          <div style="display: flex; align-items: center;">

            <icon-button v-if="!item.dropStopInfo" type="is-warning" icon="delete" @click="emitRemove(item)" />
          </div>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div class="mr-2">
              <!-- <span>Stop: </span> -->
            </div>
            <div class="mr-2">
              <span>Qty: </span>
              <span style="font-weight: 400;">{{ item.dropRequest.amount }}</span>
            </div>
            <div v-if="item.dropStopInfo" class="mr-2">
              <span>Item: </span>
              <span style="font-weight: 400;"> {{ item.dropRequest.item.sytelineItem }} </span>
            </div>
            <div v-if="!item.dropStopInfo">
              <span>Item: </span>
              <span style="font-weight: 400;"> {{ item.dropRequest.item.sytelineItem }} </span>
            </div>
          </div>
        </div>
        <div v-if="index === 0" style="display: flex; flex-direction: row; flex: 1;" class="pt-1">
          <!-- dumb alignment solution -->
          <ToggleNotes title="Driver Notes" :data="item.notes" :editable="true" @saveEvent="(e) => saveNotesHandler(e, item)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconButton from './IconButton.vue';
import PanToButton from './PanToButton.vue';
import ToggleNotes from './ToggleNotes.vue';

export default {
  name: "RouteStopDropRequest",
  components: {
    IconButton,
    PanToButton,
    ToggleNotes
  },
  props: {
    stop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return this.stop.items.length > 0 ? this.stop.items : []
    },
    stopOrder() {
      return this.stop.stopOrder ? this.stop.stopOrder : 'n/a'
    },
    orderNumber() {
      return this.stop.dropRequest.sytelineOrderId ? this.stop.dropRequest.sytelineOrderId : 'n/a'
    },
    pickupLocationAddress() {
      const pl = this.stop.pickupLocation;
      return `${pl.addStreet} ${pl.addCity}, ${pl.addState} ${pl.addPostalCode}`;
    },
    orderComplete() {
      const numberSkus = this.stop.items.length;
      const dropStopInfos = this.stop.items.reduce((acc, curr) => {
        if (curr.dropStopInfo) {
          acc.push(curr);
          return acc;
        }
        return acc;
      }, [])

      return dropStopInfos.length === numberSkus
    }
  },
  mounted() {
  },
  methods: {
    emitRemove(stop) {
      this.$emit("remove", stop);
    },
    async saveNotesHandler(notes, stop) {
      const { routeId, rowPointer } = stop;

      await this.$api.putDriverStop(routeId, rowPointer, { notes })
      this.$store.dispatch("getDrivers")
    }
  },
};
</script>

<style lang="css" scoped>
/* .success {
  color: $success-dark;
  background: $success-light;
} */
</style>