<template>
  <div ref="theslot">
    <slot />
  </div>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';

export default {
  extends: Pp4MapObject,
  props: {
    position: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentInstance: null,
    };
  },
  beforeUnmount() {
    if (!this.componentInstance) {
      return;
    }

    let index = this.controls.indexOf(this.componentInstance);
    this.controls.removeAt(index);
  },
  computed: {
    controls: (vm) => vm.map.controls[vm.api.ControlPosition[vm.position]],
  },
  methods: {
    mapReady() {
      this.componentInstance = this.$refs.theslot;

      if (this.componentInstance.parentNode) {
        this.componentInstance.parentNode.removeChild(this.componentInstance);
      }

      this.controls.push(this.componentInstance);
    },
  },
};
</script>

<style lang="css" scoped></style>
