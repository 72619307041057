<template>
  <span @mouseenter="mousein" @mouseleave="mouseout" class="topspan">
    <icon-button icon="map-marker" @click="panTo" @dblclick="panToAndZoom" :title="title" />
    <input type="text" ref="forcopying" :class="textClass" :value="title">
  </span>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject';
import IconButton from '@/components/common/IconButton.vue';

export default {
  extends: Pp4MapObject,
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mouseover: false
    }
  },
  computed: {
    title: vm => vm.location.lng + ', ' + vm.location.lat,
    textClass: vm => vm.mouseover ? 'shown_text' : 'hidden_text',
  },
  methods: {
    panTo() {
      this.panToLatLngs(this.location);

      this.copy();
    },
    copy() {
      this.$refs.forcopying.select();
      document.execCommand("copy");
      this.mouseover = false;
    },
    mousein(e) {
      this.mouseover = true;
    },
    mouseout(e) {
      this.mouseover = false;
    },
    panToAndZoom() {
      this.panToLatLngs(this.location);
      if (this.zoomLevel < 16) {
        this.setZoomLevel(16);
      }
    },
  },
  components: { IconButton },
};
</script>

<style lang="css" scoped>
:deep(.icon.is-small > i.mdi) {
  font-size: 18px;
}

.topspan {
  position: relative;
}

.hidden_text {
  display: none;
}

.shown_text {
  position: absolute;
  left: 22px;
  top: 22px;
  font-size: x-small;
  z-index: 1000;
}
</style>
