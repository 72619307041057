<!-- 
    An inline location component to display template information for both spotted points and pickup locations
 -->

<template>
  <span>
    <span v-if="showAddress">
      {{
        `${value.addStreet}, ${value.addCity}, ${value.addState}, ${value.addCounty} ${value.addPostalCode},
                        ${value.addCountry}`
      }}
    </span>
    <span v-if="showLatLng"> Lat/Lng: {{ formattedLatLng }} </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    showAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLatLng: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    formattedLatLng: (vm) => `${vm.value.pointLatLng.lat.toFixed(6)}, ${vm.value.pointLatLng.lng.toFixed(6)}`,
  },
};
</script>

<style></style>
