<template>
  <span> {{ distanceDescription }}, {{ durationDescription }} </span>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
})

const model = defineModel('value')

const legs = computed(() => {
  const routes = model.value?.resultJson?.routes;
  if (!routes || routes.length === 0) {
    return [];
  }

  const route = routes[0];
  return route.legs;
})


const distanceInMeters = computed(() => {
  if (legs.value?.length < 1) {
    return 0;
  }

  let ret = 0;
  legs.value.forEach((leg) => {
    ret += leg?.distance?.value || 0;
  });

  return ret;
})

const durationInSeconds = computed(() => {
  if (legs.value?.length < 1) {
    return 0;
  }

  let ret = 0;
  legs.value.forEach((leg) => {
    ret += leg?.duration?.value || 0;
  });

  return ret;
})


const distanceDescription = computed(() => {
  const miles = (distanceInMeters.value / 1609.344).toFixed(1);

  if (miles <= 0) {
    return "Distance N/A";
  }

  return `${miles} Miles`;
})

const durationDescription = computed(() =>{
  let ret = (durationInSeconds.value / 60 / 60).toFixed(1);

  if (ret <= 0) {
    return "Duration N/A";
  }

  if (ret < 1) {
    ret = (ret * 60).toFixed(0) + " Minutes";
  } else {
    ret = ret + " Hours";
  }
  return ret;
})
</script>

<style></style>
