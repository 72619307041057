<template>
  <div v-if="driver">
    <div class="ml-2 mt-2" style="display: flex; flex-direction: column">
      <div class="mb-2" style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
        <span class="has-text-weight-semibold is-size-6"
          :style="`background-color: ${driver.colorScheme.bg}; color: ${driver.colorScheme.fg}`">
          {{ getFullNameAny(driver.user) }}
        </span>

        <PanToButton style="padding: 5px" v-if="driverLatLng" :location="driverLatLng" />

        <icon-button icon="cellphone-sound" class="ml-1" :disabled="!driver?.user?.driverNotifications"
          @click="notifyDriver" title="Notify" />
      </div>


      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <span class="has-text-weight-semibold is-size-6"> Lead Driver </span>
          <icon-button v-if="driver.following" icon="map" class="ml-1"
            @click="() => { this.$emit('rowOpened', driver.following); }" title="Lead Driver" />
        </div>

        <individual-field class="ml-1" v-slot="inputProps" @onSave="saveLeadDriver()">
          <input v-if="inputProps.disabled" :disabled="inputProps.disabled" :value="computedLeadDriverName" />
          <div v-if="!inputProps.disabled">
            <input list="drivers" name="driver" v-model="driver.following" required />
            <datalist id="drivers">
              <option disabled value="">Please select one</option>
              <option v-for="(driver, index) in drivers" :key="index" :value="driver.id">
                {{ getFullNameAny(driver.user) }}
              </option>
            </datalist>
          </div>
        </individual-field>
      </div>

      <div style="display: flex; flex-direction: row; justify-content: space-between;" class="pt-1">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <span class="has-text-weight-semibold is-size-6">Role</span>
        </div>

        <individual-field class="ml-1" v-slot="inputProps" @onSave="saveUserRole">
          <input v-if="inputProps.disabled" :disabled="inputProps.disabled" :value="computedRole" />
          <div v-if="!inputProps.disabled">
            <input list="roles" name="role" v-model="driver.role" required />
            <datalist id="roles">
              <option disabled value="">Please select one</option>
              <option v-for="(role, index) in roles" :key="index" :value="role">
                {{ role }}
              </option>
            </datalist>
          </div>
        </individual-field>
      </div>

      <div style="display: flex; flex-direction: column; justify-content: space-between;" class="pt-1">
        <div style="display: flex; flex-direction: row; align-items: center;">
          <span class="has-text-weight-semibold is-size-6"> Route Notes </span>
        </div>
        <ToggleNotes getUserFirstAndLast :data="activeRoute.notes" :editable="true" @saveEvent="saveHandler" />
      </div>
    </div>

    <b-tabs class="p-0" v-model="activeTab" v-if="followingDriverActiveRoute">
      <b-tab-item label="Following" >
        <template v-if="followingDriverActiveRoute">
        <RouteEdit
          class="has-text-weight-semibold"
          :rowPointer="followingDriverActiveRoute.rowPointer"
          @routeDumped="setRouteAsDumped(followingDriverActiveRoute.rowPointer,followingDriverPlannedRoute?.rowPointer)"
          :warehouse="driver.warehouse"
          :readonly="true"
        />
      </template>
      </b-tab-item>
    </b-tabs>

    <b-tabs class="p-0" v-model="activeTab" v-if="driver && !followingDriverActiveRoute">
      <b-tab-item label="Assigned">
        <template v-if="activeRoute">
        <RouteEdit
          class="has-text-weight-semibold"
          :rowPointer="activeRoute.rowPointer"
          :warehouse="driver.warehouse"
          @routeDumped="setRouteAsDumped(activeRoute.rowPointer, plannedRoute?.rowPointer)"
          :colorScheme="driver.colorScheme"
        />
      </template>
      </b-tab-item>

      <b-tab-item label="Planned">
        <template v-if="plannedRoute">
          <RouteEdit
            class="has-text-weight-semibold"
            :rowPointer="plannedRoute.rowPointer"
            @routeDumped="setRouteAsDumped(activeRoute.rowPointer, plannedRoute?.rowPointer)"
            :warehouse="driver.warehouse"
            :colorScheme="driver.colorScheme"
          />
        </template>
        <template v-else>
          <button @click="createPlannedRoute">Create Planned Route</button>
        </template>
      </b-tab-item>
    </b-tabs>
    <driver v-if="driverLatLng" :geotabDriver="geotabDriver" :driver="driver" />
  </div>
  <div v-else>
    <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"> </b-icon>
  </div>
</template>

<script>
import RouteEdit from "@/components/common/RouteEdit";
import ToggleNotes from "@/components/common/ToggleNotes";
import PanToButton from "@/components/common/PanToButton";
import IconButton from "@/components/common/IconButton.vue";
import Driver from "@/components/maps/Driver";
import { getFullNameAny } from "@/services/UserUtil";
import IndividualField from "@/components/IndividualField.vue";
import toastService from "@/services/toastService";

export default {
  data() {
    return {
      getFullNameAny,
      values: {
        _driver: undefined,
      },
      activeTab: 0,
    };
  },
  components: {
    RouteEdit,
    IconButton,
    PanToButton,
    ToggleNotes,
    Driver,
    IndividualField,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  emits: ["rowOpened", "change"],
  watch: {
    activeTab: "activeTabChanged"
  },
  computed: {
    drivers: (vm) => vm.$store.state.drivers.data,
    roles: (vm) => {
      return [
        "Boom Operator",
        "Follow Driver",
        "Material Handler",
        "Supervisor",
        "Spotter",
        "Salesman",
        "CS rep"
      ]
    },
    geotabDriver: (vm) => {
      if (!vm.driver.geotabId) {
        return null;
      }

      const geotabDrivers = vm.$store.state.geotabDrivers;

      return geotabDrivers.find((gtd) => gtd.id === vm.driver.geotabId);
    },
    driverLatLng: (vm) => {
      if (!vm.geotabDriver) {
        return null;
      }

      const device = vm.geotabDriver.device;
      if (!device) {
        return null;
      }

      return {
        lat: device.latitude,
        lng: device.longitude,
      };
    },
    activeRoute: (vm) => vm.driver.activeRoute ?? null,
    plannedRoute: (vm) => vm.driver.plannedRoute ?? null,
    followingDriverActiveRoute: (vm) =>
      vm.driver.followingDriver?.activeRoute ?? null,
    followingDriverPlannedRoute: (vm) =>
      vm.driver.followingDriver?.plannedRoute ?? null,
    computedLeadDriverName: (vm) => {
      const followingDriver = vm.driver.followingDriver

      if (!followingDriver?.user) {
        return null;
      }

      return getFullNameAny(followingDriver.user);
    },
    computedRole: (vm) => {

      return vm.driver.role
    }
  },
  mounted() {
    this.activeTabChanged(0) //needed when user switches rows and was on 'planned' previously..
  },
  methods: {
    activeTabChanged(val) {
      if (val === 0) {
        this.$store.dispatch('selectedDriverInPlannedMode', false);
      }

      if (val === 1) {
        this.$store.dispatch('selectedDriverInPlannedMode', true);
      }
    },
    createPlannedRoute() {
      this.$store.dispatch("createPlannedRoute", this.driver.id);
    },
    saveUserRole() {
      if (this.driver.role !== null) {
        this.$store
          .dispatch("updateDriver", {
            id: this.driver.id,
            role: this.driver.role
          })
          .then(async (_) => {
            this.values._driver = this.driver;
          });
      }
    },
    notifyDriver() {
      const notes = this?.activeRoute?.notes;
      if (!notes || !notes.length) {
        return;
      }

      const notification = {
        userId: this.driver.user.userId,
        app: "driver",
        body: notes,
      };

      if (!confirm("Notify Driver?" + "\n\n" + notes)) {
        return;
      }

      this.$store.dispatch("postNotification", notification);
    },
    // perhaps convert to patch once JSON patch is implemented
    saveLeadDriver() {
      let following = parseInt(this.driver.following);
      following = isNaN(following) ? null : following;

      if (following === this.driver.user.id)
        return this.$toast.error("Cannot set lead driver to self");

      if (following == null || this.drivers.find((d) => d.id === following)) {
        // changes propagate from parent via computed property holding commit, see DriversTable.vue
        this.$store
          .dispatch("updateDriver", {
            id: this.driver.id,
            geotabId: this.driver.geotabId,
            following,
          })
          .then(async (_) => {
            this.values._driver = this.driver;
          });
      } else this.$toast.error(`User ID ${this.driver.following} is not a driver`);
    },
    toggleNotesVisible() {
      this.notesVisible = !this.notesVisible;
    },
    async saveHandler(event) {
      console.log("! EMIT CHANGE")
      try {
        await this.$store.dispatch("updateRouteNotes", {
          id: this.activeRoute.id,
          notes: event,
        });

        this.$emit("change");
      } catch (e) {
        this.$telemetry.error(e);
      }
    },


    async setRouteAsDumped(rowPointer, plannedRowPointer) {
        try {
          await this.$store.dispatch("postWarehouseStop", {
            userId: this.activeRoute.assignedTo,
            routeRowPointer: rowPointer,
            plannedRowPointer: plannedRowPointer
          });
          toastService.success("Route dumped");
        } catch (error) {
          toastService.error(
            "Route dumped could not be dumped. Check your browser's log for details."
          );
        }
    },
  },
};
</script>

<style scoped></style>
