<!--
  Shows a table of current driver stops with corresponding review forms for driver stops that are up for review.
-->
<template>
  <div class="driver-stop-reviews-table-wrapper">
    <Table
      :disabledSearch="true"
      :data="rows"
      detailKey="driverStopRowPointer"
      :pagination="rowPagination"
      @pageChange="pageChange"
      @rowOpened="rowOpened"
      :openRowAccess="openRowAccess"
      :columns="columnsWithDates"
      :loading="rowPagination.loading"
      :snapTo="currentlyOpen"
      :controls="true"
    >
      <template #controls>
        <filters>
          <template #filters>
            <div class="filters_columns">
              <div class="filters_rows">
                <inclusive-filter name="Owned" :value="filters.owned" @change="updateFilter('owned', $event)" />
                <span class="ml-3 filters_rows">
                  <b-checkbox size="is-small" v-model="needsReview">Needs Review</b-checkbox>
                </span>
                <b-datepicker range size="is-small"
                  placeholder="Date range..."
                  :editable="true"
                  v-model="dates"
                  :append-to-body="false"
                  @range-start="updateFilter('beginDate', $event)"
                  @range-end="updateFilter('endDate', $event)"
                  />
                  <b-button size="is-small" v-if="dates && dates.length" @click="clearDates">X</b-button>
              </div>

              <div class="filters_rows mt-1"> <!-- row two filters -->
                <label class="mr-1" for="pickup-location-driver-search">Driver Search: </label>
                <input id="pickup-location-driver-search" :disabled="rowPagination.loading" type="text" @change="updateFilter('driverSearchText', $event.target.value)"/>
              </div>

              <div class="filters_rows mt-1"> <!-- row three filters -->
                <label class="mr-1" for="pickup-location-user-search">Owner Search: </label>
                <input id="pickup-location-user-search" :disabled="rowPagination.loading" type="text" @change="updateFilter('ownerSearchText', $event.target.value)"/>
              </div>
            </div>
          </template>
        </filters>

        <div style="display: flex; flex-direction: column; margin-bottom: 5px">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <b-button size="is-small" @click="refresh" icon-right="refresh" title="Refresh Driver Stop Reviews" />
          </div>
        </div>
      </template>

      <template #top-left>
        <b>Total: {{ rowPagination.total }}</b>
      </template>

      <template #reviewed="{ value }">
        {{value.reviewed ? 'Yes' : 'No'}}
      </template>

      <template #pickupDatelocaleString="{ value }">
        {{ new Date(value.createdOn).toLocaleDateString() }}
      </template>

      <template #driverName="{ value }">
        <User v-if="value?.driverStop?.route?.assignedToDriver?.user" 
          :value="value.driverStop.route.assignedToDriver.user" />
      </template>

      <template #ownerName="{ value }">
        <User v-if="value?.driverStop?.pickupLocation?.owner" :value="value.driverStop.pickupLocation.owner" />
      </template>

      <template #detail="props">
        {{props.id}}
        <driver-stop-review-form :value="props.value" @onPut="openNextRow" />
      </template>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/common/Table';
import User from '@/components/common/User.vue';
import Filters from '@/components/common/Filters.vue';
import InclusiveFilter from '@/components/common/InclusiveFilter.vue';

import Pp4MapObject from '@/components/maps/Pp4MapObject';

import DriverStopReviewForm from '@/views/RouteWizard/components/DriverStopReviewForm';
import { getFullNameAny } from '@/services/UserUtil';
import { debounce } from "lodash";

export default {
  extends: Pp4MapObject,
  props: [],
  inject: ['sidebarStatus'],
  components: {
    DriverStopReviewForm,
    User,
    Filters,
    InclusiveFilter,
    Table,
  },
  data: (vm) => {
    return {
      needsReview: true,
      dates: [],
      paginatedDriverStopReviews: [],
      openRowAccess: null,
      currentlyOpen: null,
      columnsWithDates: [
        { field: 'reviewed', label: 'Reviewed', visible: true, sortable: true, centered: true },
        { field: 'pickupDatelocaleString', label: 'Stop Date', visible: true, sortable: true },
        { field: 'driverName', label: 'Driver', visible: true, sortable: true },
        { field: 'ownerName', label: 'Owner', visible: true, sortable: true },
        { field: 'weight', label: 'Count', visible: true, sortable: true },
      ],
    };
  },
  computed: {
    rows: (vm) => {
      const ret = vm.$store.state.paginatedDriverStopReviews.data.map((row) => {
        const ret = Object.assign({}, row);

        const driver = row?.driverStop?.route?.assignedToDriver;
        const driverName = driver ? getFullNameAny(driver.user) : 'N/A';
        ret.driver = driver?.user || null;
        ret.driverName = driverName;

        const owner = row?.driverStop?.pickupLocation?.owner;
        const ownerName = owner ? getFullNameAny(owner) : 'No Owner';
        ret.owner = owner;
        ret.ownerName = ownerName;

        //    weight
        var weight = 0;
        for (let index = 0; index < 7; index++) {
          weight = weight + row['weight' + index];
        }
        ret.weight = weight;

        // set filters
        ret.reviewed = ret?.reviewItems?.length > 0 || false;

        ret.reviewDate = undefined;
        if(ret.reviewItems) {
          ret.reviewItems.forEach((element) => {
            if (element.createdOn) ret.reviewDate = element.createdOn;
          });
        }

        // if there aren't cached items (this should only run once but just in case)
        // define the cached items array
        if (row.cachedItems == null || row.cachedItems.length < 1) ret.cachedItems = [];
        else ret.cachedItems = row.cachedItems;

        return ret;
      });

      // sort by driver stop info oldest first for getting right active frame
      ret.sort((a, b) => a.createdOn - b.createdOn);

      ret.forEach((row, rowIndex, rowArray) => {
        // set activeFrame
        row.activeFrame = {
          currentDsInfoCreatedOn: row.createdOn,
          previousDsInfoCreatedOn: rowArray[rowIndex - 1]?.createdOn || row.createdOn,
        };
      });

      // sort by driver stop info newest first for table UI
      ret.reverse();

      return ret;
    },
    rowPagination: (vm) => vm.$store.state.paginatedDriverStopReviews,
    filters: (vm) => vm.$store.state.paginatedDriverStopReviews.filters,
  },
  watch: {
    needsReview: "updateFiltersFromNeedsReview"
  },
  mounted() {
    this.refresh();
  },
  created() {
    this.debounceRefresh = debounce(function () {
      this.$store.dispatch('updatePaginatedDriverStopReviews');
    }, 1000);
  },
  methods: {
    async refresh() {
      await this.$store.dispatch('updatePaginatedDriverStopReviews');
    },
    clearDates() {
      this.dates = [];
      this.updateFilter('beginDate', null);
      this.updateFilter('endDate', null);
    },
    async updateFilter(property, value) {
      if(value instanceof Date) {
        value = value.toISOString()
      }

      const newFilters = { ...this.filters };
      newFilters[property] = value;
      await this.$store.dispatch('updatePaginatedDriverStopReviewsOptions', { filters: newFilters });

      this.debounceRefresh();
    },
    updateFiltersFromNeedsReview() {
      this.updateFilter('reviewed', this.needsReview ? ['exc'] : ['inc']);
    },
    async pageChange(newPage) {
      await this.$store.dispatch('updatePaginatedDriverStopReviewsOptions', {
        page: newPage,
      });
      await this.$store.dispatch('updatePaginatedDriverStopReviews');
    },
    async openNextRow() {
      // if there is more than one row and it is already open
      if (this.rows.length > 1) {
        const currentIndex = this.rows.findIndex((e) => e.driverStopRowPointer == this.currentlyOpen);
        const nextIndex = currentIndex + 1;
        if (this.rows.length == nextIndex || this.rows.length < nextIndex) {
          return;
        }
        this.openRowAccess = [this.rows[nextIndex].driverStopRowPointer];
      }

      await this.refresh();
    },
    rowOpened(rowId) {
      this.currentlyOpen = rowId;
      const driverStopReview = this.rows.find((e) => e.driverStopRowPointer == this.currentlyOpen);
      if(! driverStopReview) {
        return;
      }
      
      const pickupLocation = driverStopReview?.driverStop?.pickupLocation;
      if(! pickupLocation?.geoJson) {
        return;
      }

      this.panToGeoJson(pickupLocation.geoJson);
      this.$store.dispatch('setSelectedPickupLocation', pickupLocation);
    },
  },
};
</script>

<style lang="css" scoped>
.driver-stop-reviews-table-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  min-width: 600px;
}

.driver-stop-reviews-table-wrapper .b-table {
  width: 100%;
}

.filters_columns {
  display: flex;
  flex-direction: column;
}

.filters_rows {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
