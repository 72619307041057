<template>
  <div v-if="image">
    <div @click="showFullImage">
      <authorized-image id="image-button" :imgStyles="{ maxHeight: '169px', maxWidth: '78.4px' }" :image="image" />
    </div>
    <full-screen-image-modal :active="fullscreen" :image="image" @close="closeImage" />
  </div>
  <span v-else>none</span>
</template>

<script>
import AuthorizedImage from '@/components/common/AuthorizedImage.vue';
import FullScreenImageModal from './FullScreenImageModal.vue';

export default {
  props: {
    label: {
      type: String,
      default: 'label',
    },
    image: {
      required: true,
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  computed: {
    getWinHeight() {
      return window.innerHeight;
    },
    getWinWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    showFullImage() {
      this.fullscreen = true;
    },
    closeImage(value) {
      this.fullscreen = value;
    },
  },
  components: { AuthorizedImage, FullScreenImageModal },
};
</script>

<style>
#image-button {
  cursor: pointer;
}
</style>
