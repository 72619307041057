<template>
  <div style="position: relative">
    <!-- I don't know the details, but having this position relative matters for the element overlay... -->
    <template v-if="!filtered">
      <PickupLocation v-for="(pl, i) in pickupLocationsNotSelected" :key="`UnselectedPickupLocation:${pl.id}`"
        :pickupLocation="pl" :visible="pickupLocationsVisible" 
        :selected="false"
        @click="pickupLocationClicked(pl)" />
    </template>


    <!-- <Pp4Label
      v-for="c in countyRollups"
      :key="c.key"
      elementClass="county-cluster-label"
      :labelText="c.labelHtml"
      :latLng="c.location"
      :visible="countiesVisible"
    /> -->
  </div>
</template>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject";
import Pp4Label from "@/components/maps/Label";
import PickupLocation from "@/components/maps/PickupLocation";

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Label,
    PickupLocation,
  },
  props: ["pickupLocations"],
  data: () => {
    return {
    };
  },
  watch: {},
  computed: {
    selectedPickupLocation: (vm) => vm.$store.state.selectedPickupLocation,
    pickupLocationsVisible: (vm) => !vm.filtered,
    filtered: (vm) => !vm.$store.state.layers.pickupLocations,
    pickupLocationsNotSelected: (vm) => {
      if (!vm.selectedPickupLocation) {
        return vm.pickupLocations;
      }

      return vm.pickupLocations.filter(
        (o) => {
          if (vm.selectedPickupLocation && o.id === vm.selectedPickupLocation.id) {
            return false;
          }

          return true;
        }
      );
    }
  },
  mounted() { },
  methods: {
    pickupLocationClicked(pickupLocation) {
      this.$store.dispatch("setSelectedPickupLocation", pickupLocation);
    },
    mapReady() {
    },
  },
};
</script>

<style lang="css"></style>
