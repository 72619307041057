import AccessBlock from "../views/AccessBlock";
import RouteWizard from "../views/RouteWizard/RouteWizard";
import VroomWizard from "../views/VroomWizard/VroomWizard";

const routes = [
  {
    path: "/",
    name: "dispatcher",
    component: RouteWizard,
  },
  {
    path: "/vroom",
    name: "vroom",
    component: VroomWizard,
  },
  {
    path: "/warning",
    name: "access-block",
    component: AccessBlock,
  },
];

export default routes;
