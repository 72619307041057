<template>
  <div class="controls-container">
    <span class="is-uppercase has-text-weight-bold"
      v-if="showTooltip">Filters
      <!-- This tooltip should only be shown if there are inclusive-filter components -->
      <b-tooltip
        label="Green includes those that fit filter, red includes those that do not fit filter, white includes both."
        type="is-info"
        position="is-right"
        multilined
      >
        <b-icon icon="information-outline" size="is-small" />
      </b-tooltip>
    </span>
    <div
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.135);
        padding: 5px;
      "
    >
      <slot/>
      <slot name="filters"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTooltip: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style lang="css" scoped>
.controls-container {
  margin-bottom: 5px;
}
</style>
