<template>
  <div>
    <b-image v-show="!loading" :style="imgStyles || defaultImgStyles" ref="img" :alt="alt" :src="src" @load="load"
      :lazy="false" />
    <b-skeleton v-if="loading" :height="skeletonHeight" :width="skeletonWidth" :style="imgStyles || defaultImgStyles" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    image: {
      type: Object,
      required: true,
    },
    imgStyles: {
      type: Object,
      required: false,
    },
  },
  watch: {
    image: 'imageUpdated',
  },
  data() {
    return {
      defaultImgStyles: {},
      src: null,
      loading: true,
    };
  },
  computed: {
    alt: (vm) => vm.image.id + ' ' + vm.image.routeId + ' ' + vm.image.stopId + ' ' + vm.image.rowPointer,
    skeletonWidth: (vm) => /^\d*/.exec(vm.imgStyles?.maxWidth || vm.defaultImgStyles?.maxWidth || '100px')[0],
    skeletonHeight: (vm) => /^\d*/.exec(vm.imgStyles?.maxHeight || vm.defaultImgStyles?.maxHeight || '100px')[0],
  },
  mounted() {
    this.getImage();
  },
  methods: {
    load() {
      this.loading = false;
    },
    imageUpdated(oldValue, newValue) {
      // only get image if it's a new image
      // otherwise load event will never be called by b-image
      if (oldValue.id != newValue.id) {
        this.loading = true;

        this.getImage();
      }
    },
    async getImage() {
      const bearerToken = await this.$auth0.getAccessTokenSilently();
      if (!bearerToken) {
        return;
      }

      const imageUrl = `/api/dispatcher/images/${this.image.rowPointer}`

      const response = await fetch(imageUrl, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${bearerToken}`
        }
      })

      const blob = await response.blob()

      this.src = URL.createObjectURL(blob);
    },
  },
};
</script>

<style>
img {
  object-fit: contain;
  max-height: inherit;
  max-width: inherit;
}
</style>
